import { DatatableHeader } from '@/components/datatable';
import { ColumnComponents } from '@/components/datatable/header/datatableHeader.types';

export const clientProfilesPageHeaders: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'isActive',
    label: 'Status',
    width: '15%',
    renderComponent: ColumnComponents.Switch,
  },
  {
    isOrderResultEnabled: false,
    key: 'name',
    label: 'Nome',
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'responsible',
    label: 'Responsável',
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'username',
    label: 'Usuário',
    width: '10%',
  },
  {
    isOrderResultEnabled: false,
    key: 'createdAt',
    label: 'Criado em',
    width: '13%',
  },
  {
    isOrderResultEnabled: false,
    key: 'password',
    label: 'Senha',
    width: '10%',
    renderComponent: ColumnComponents.Password,
  },
  {
    isOrderResultEnabled: false,
    key: 'picture',
    label: 'Foto',
    width: '16%',
    renderComponent: ColumnComponents.Image,
  },
  {
    isOrderResultEnabled: false,
    key: 'manage',
    label: 'Gerenciar',
    width: '6%',
    renderComponent: ColumnComponents.Edit,
  },
];
