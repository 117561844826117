import React from 'react';
import { View } from 'react-native';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import locale from '@/internationalization';

import { Routes } from '../../navigation/routes';
import { useSectorList } from './SectorListPage.functions';
import { SectorListPageTypes } from './SectorListPage.types';
import { Container } from '@/components/container';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import SectorTabs from '@/components/sectortabs/SectorTabs';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Datatable } from '@/components/datatable';
import { sectorPageHeaders } from './SectorListPage.consts';

const SectorListPage = ({
  route: { params: { entity } },
}: SectorListPageTypes) => {
  const {
    entityData,
    adminData,
    updateStatus,
    goToEdit,
    sectorList,
    loading,
  } = useSectorList(entity);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.sectorList}
        role={adminData?.adminType?.type}
      />

      <Container>
        <EntitiesPageTitle
          title={locale.t('sectorCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <SectorTabs entityId={entity} list create={false} />
          <View style={[PaperColumns.tableContainer]}>
            <Datatable
              headers={sectorPageHeaders}
              data={sectorList}
              loading={loading}
              onSwitchChange={updateStatus}
              onEditPress={goToEdit}
              searchFields={['name']}
            />
          </View>
        </Paper>
      </Container>
    </>
  );
};
export default SectorListPage;
