import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity } from '@/api/entities';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { useCreateProductCategory, useGetProductCategory, useUpdateProductCategory } from '@/api/categories';
import locale from '@/internationalization';
import { Routes } from '@/navigation/routes';
import { Flows } from '@/navigation/routes/enums/Flows';

export const useCategoryCreate = (entity: string, id: string, flow: Flows) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const {
    data: categoryToEdit,
    isLoading: categoryToEditLoading,
  } = useGetProductCategory(id, !!id);

  const { goBack, goToEntityPage } = useNavigate();

  const [name, setName] = useState('');
  const [picture, setPicture] = useState('');
  const [imageCache, setImageCache] = useState('');
  const [APIErrorMessage, setAPIErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [hasAPIError, setHasAPIError] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const queryClient = useQueryClient();
  const mutation = useCreateProductCategory();
  const updateMutation = useUpdateProductCategory();

  const goToStep1 = () => {
    setStep1(true);
    setStep2(false);
  };

  const goToStep2 = () => {
    setStep1(true);
    setStep2(true);
  };

  const onImagePick = (file: File, uri: string) => {
    setImageCache(uri);
    setPicture('');
  };

  const create = () => mutation.mutateAsync({
    name,
    entity,
    picture: imageCache,
    isActive: true,
  });

  const update = (idToUpdate: string) => updateMutation.mutateAsync({
    id: idToUpdate,
    name,
    entity,
    isActive: categoryToEdit?.isActive,
    picture: imageCache,
  });

  const submit = async () => {
    setLoading(true);

    try {
      const result = id ? await update(id) : await create();
      const data = await result.json();

      if (result.status !== 201 && result.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        const err = data.code ? locale.t(`errors.${data.code}`) : locale.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }

      setLoading(false);

      await queryClient.invalidateQueries('productCategories');

      if (flow === Flows.product) {
        goToEntityPage(Routes.Product, { params: { entity }, screen: Routes.ProductCreate });
        return;
      }

      goToEntityPage(Routes.Category, { params: { entity }, screen: Routes.CategoryList });
    } catch (e) {
      console.error('useCategoryCreate.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setLoading(false);
    }
  };

  useEffect(() => {
    goToStep1();
  }, []);

  useEffect(() => {
    if (categoryToEdit && !categoryToEditLoading) {
      setName(categoryToEdit?.name);
      setPicture(categoryToEdit?.picture);
      setIsEditing(true);
    }
  }, [categoryToEdit]);

  return {
    entityData,
    adminData,
    loading,
    step1,
    step2,
    name,
    setName,
    imageCache,
    picture,
    APIErrorMessage,
    hasAPIError,
    isEditing,
    goBack,
    onImagePick,
    goToStep1,
    goToStep2,
    submit,
  };
};
