import fetch from '../fetch';
import { CreateEntityDto, UpdateEntityDto } from './dto';
import { InvitationDto, InvitationDtoUpdate } from '@/api/entities/dto';

export const getAdminEntities = (adminId: string) => fetch(`/entity/admin/entities/${adminId}`).then((result) => result.json());
export const getEntityAdmins = (entityId: string) => fetch(`/entity/admins/${entityId}`).then((result) => result.json());
export const getEntity = (id: string) => fetch(`/entity/${id}`).then((result) => result.json());
export const getEntitiesAll = (id: string) => fetch(`/entity/entities/${id}`).then((result) => result.json());
export const getEntityInfo = (id: string) => fetch(`/entity/info/${id}`).then((result) => result.json());
export const getEntityBank = (id: string) => fetch(`/entity/bank/${id}`).then((result) => result.json());
export const createNewEntity = (data: CreateEntityDto) => fetch('/entity', {
  method: 'POST',
  body: JSON.stringify(data),
});
export const updateEntity = (data: UpdateEntityDto) => fetch(`/entity/${data.id}`, {
  method: 'PUT',
  body: JSON.stringify(data),
});
export const updateSystemEntity = (data: UpdateEntityDto) => fetch(`/entity/system/${data.id}`, {
  method: 'PUT',
  body: JSON.stringify(data),
});
export const cancelSubscription = (id: string) => fetch(`/entity/cancel-plan/${id}`, {
  method: 'DELETE',
});

export const getEntityInvitations = (entityId: string) => fetch(`/invitations/entity/${entityId}`).then((result) => result.json());

export const createInvitation = (data: InvitationDto) => fetch('/invitations', {
  method: 'POST',
  body: JSON.stringify(data),
});

export const updateInvitation = (data: InvitationDtoUpdate) => fetch(`/invitations/${data.id}`, {
  method: 'PUT',
  body: JSON.stringify(data),
});

export const getInvitation = (id: string) => fetch(`/invitations/${id}`).then((result) => result.json());
