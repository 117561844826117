import React from 'react';

import {
  ActivityIndicator, ScrollView, StyleSheet, View,
} from 'react-native';
import { colors, dimensions } from '@/consts';

export type Props = {
  children: any;
  bgColor?: string;
  hasSubmenu?: boolean
  disableInternalPadding?: boolean;
  withScroll?: boolean;
  loading?: boolean;
}

export const Paper = ({
  children, disableInternalPadding, hasSubmenu, withScroll, bgColor, loading,
}: Props) => {
  const propsStyles = {
    backgroundColor: bgColor || colors.paper.bg,
    minHeight: hasSubmenu ? dimensions.paperHeightWithSubHeader : dimensions.paperHeight,
    padding: disableInternalPadding ? 0 : 20,
  };

  return (
    <View style={[styles.container, propsStyles]} testID="paper">
      <>
        {loading && (
        <View style={styles.loader}>
          <ActivityIndicator size="large" color={colors.loading} />
        </View>
        )}
        {withScroll ? (
          <ScrollView style={styles.scroll} testID="paper-scroll-view">
            {children}
          </ScrollView>
        ) : (children)}
      </>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: 50,
  },
  scroll: {
    flex: 1,
  },
  loader: {
    marginTop: 50,
    height: '30%',
    justifyContent: 'flex-end',
  },
});
