import { StyleSheet } from 'react-native';
import { typography } from '@/styles';
import { colors } from '@/consts';

export const styles = StyleSheet.create({
  confirmationText: {
    ...typography.p2,
    fontWeight: '400',
    color: colors.register.confirmationText,
    marginBottom: 22,
  },
  verificationCode: {
    ...typography.p3,
    fontWeight: '700',
    marginLeft: 20,
  },
  sendCodeButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default styles;
