import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { formatNumber } from 'react-native-currency-input';
import { useGetAdmin, useGetAdminId } from '../../api/admin';
import { useGetEntity } from '../../api/entities';
import locale from '../../internationalization';
import { Product } from '../../types/product';
import { useGetProducts, useUpdateProduct, useGetCategories } from '../../api/products';
import { currencyFormatFloat } from '../../utils/strUtils';
import { Routes } from '../../navigation/routes';
import { useNavigate } from '../../navigation/routes/hooks/useNavigate';
import { formatOptions } from '../../utils/currencyMask';
import { productRole } from '@/types/productRoles';

export const useProductsList = (entity: string) => {
  const [isActiveInactive, setIsActiveInactive] = useState<productRole>(
    productRole.Activo,
  );

  const [orderVal, setOrderVal] = useState<string>('default');
  const [segmentVal, setSegmentVal] = useState<string>('all');
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: categories } = useGetCategories(entity, !!entity);
  const { data: products, isLoading: loading } = useGetProducts(
    entity,
    !!entity,
  );
  const segmentValues = [
    { label: 'Todos', value: 'all' },
    ...(categories?.map((c: { name: any; id: any; }) => ({
      label: c.name,
      value: c.id,
    })) || []),
  ];
    
  const [productDatatable, setProductDatatable] = useState<any[]>([]);

  const { goToEntityPage } = useNavigate();

  const queryClient = useQueryClient();
  const mutation = useUpdateProduct();

  const orderProductNames = (a: Product, b: Product) => {
    if (orderVal === 'asc') {
      return a.name.localeCompare(b.name);
    } if (orderVal === 'desc') {
      return b.name.localeCompare(a.name);
    } 
      return 0;
    
  }

  const filterBySegment = (list: Product[]) => {
    if (segmentVal === 'all') {
      return list;
    }
    return list.filter((c: { productCategory: { id: any; }; }) => c?.productCategory?.id === segmentVal);
  }

  const formatList = (list: Product[]) => {
    const segmentFilteredList = filterBySegment(list);
    
    const filteredList =
      isActiveInactive === 'ativo'
        ? segmentFilteredList.filter(p => p.isActive)
        : segmentFilteredList.filter(p => !p.isActive);

    filteredList.sort(orderProductNames);

    const listFormatted = filteredList.map((c: Product) => {
      const payload = {
        id: c.id,
        isActive: {
          label: c.isActive ? locale.t('active') : locale.t('inactive'),
          value: c.isActive,
        },
        name: c.name,
        category: c?.productCategory?.name,
        quantity: c.quantity,
        price: formatNumber(
          currencyFormatFloat(c.price as number),
          formatOptions,
        ),
        picture: c.picture,
        sector: c.segment?.name,
        manage: '',
      };
      return payload;
    });
    setProductDatatable(listFormatted);
  };

  const updateStatus = async (val: boolean, idProduct: string) => {
    const index = productDatatable.findIndex(c => c.id === idProduct);

    if (productDatatable[index]) {
      const productIndex = products.findIndex((c: any) => c.id === idProduct);
      const { id, name, price, cost, description, quantity, productCategory } =
        products[productIndex] as Product;
      await mutation.mutateAsync({
        id,
        name,
        entity,
        price,
        cost,
        description,
        quantity,
        productCategory: productCategory.id,
        isActive: val,
      });

      await queryClient.invalidateQueries('products');
    }
  };

  const goToEdit = (idProduct: string) => {
    const index = productDatatable.findIndex(c => c.id === idProduct);
    if (productDatatable[index]) {
      const { id } = productDatatable[index];
      goToEntityPage(Routes.Product, {
        params: {
          entity,
          id,
        },
        screen: Routes.ProductCreate,
      });
    }
  };

  useEffect(() => {
    if (products?.length && !loading) {
      formatList(products);
    }
  }, [products, isActiveInactive, orderVal, segmentVal]);

  return {
    isActiveInactive,
    adminData,
    entityData,
    productDatatable,
    loading,
    setIsActiveInactive,
    goToEdit,
    updateStatus,
    orderVal,
    setOrderVal,
    segmentVal,
    setSegmentVal,
    segmentValues
  };
};
