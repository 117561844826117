import { Platform } from 'react-native';
import { API_URL } from '@/config/envs';
import { LocalStorage } from '@/services/localStorage';
import { ADMIN } from '@/consts/storageKeys';
import { Admin } from '@/types/admin';
import { decode } from '@/common/jwt/decode';
import { isExpired } from '@/common/jwt/checkToken';

export const refreshToken = (token: string) => {
  const options: any = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refreshToken: token,
    }),
  };

  return fetch(`${API_URL}/login/refresh-token`, options);
};

export const optionsInterceptor = async (options: any) => {
  const opt = { ...options };

  const userString = await LocalStorage.get(ADMIN);

  if (userString) {
    const user: Admin = JSON.parse(userString || '');

    const decodedToken = decode(user?.accessToken || '');
    const shouldRefresh = isExpired(decodedToken.exp);

    let accessToken = user?.accessToken;
    if (shouldRefresh) {
      const newToken = await refreshToken(user?.refreshToken || '');

      if (newToken.ok) {
        const data = await newToken.json();
        accessToken = data.accessToken;

        const newConfig = {
          ...user,
          accessToken,
        };

        await LocalStorage.save(ADMIN, newConfig);
      }
    }

    opt.headers = {
      ...opt.headers,
      'Content-Type': 'application/json',
      Platform: Platform.OS,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  opt.headers = {
    ...opt.headers,
    'Content-Type': 'application/json',
    Platform: Platform.OS,
  };

  return opt;
};

export const fetcher = async (path: string, options?: any) => {
  const requestOptions = await optionsInterceptor(options);
  return fetch(`${API_URL}${path}`, requestOptions);
};

export default fetcher;
