import { StyleSheet } from 'react-native';
import { typography } from '@/styles';
import { colors } from '@/consts';

export const Styles = StyleSheet.create({
  confirmationText: {
    ...typography.p2,
    fontWeight: '400',
    color: colors.register.confirmationText,
    marginBottom: 22,
  },
});

export default Styles;
