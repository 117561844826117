import { useGetAdmin, useGetAdminId } from '../../api/admin';

export const usePaymentHistory = () => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);

  return {
    adminData,
  };
};
