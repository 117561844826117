import React from 'react';

import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { Paper } from '@/components/paper';
import { colors, dimensions, Images } from '@/consts';
import { ProgressBar } from '@/components/progressbar';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Subheader } from '@/components/subheader';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { PageStyles } from '@/styles/common/PageStyles';
import { Styles } from './ProductCreatePage.styles';
import ProductTabs from '@/components/producttabs/ProductTabs';
import { ProductCreatePageTypes } from './ProductCreatePage.types';
import { useProductsCreate } from './ProductsCreatePage.functions';
import { Routes } from '@/navigation/routes';
import { SearchableInput } from '@/components/searchableinput/SearchableInput';
import { UploadComponent } from '@/components/uploadcomponent';
import { ErrorHandler } from '@/components/errorhandler';
import { Select } from '@/components/select/Select';
import PercentageInput from '@/components/PercentageInput/PercentageInput';

export const ProductCreatePage = ({
  route: {
    params: { entity, id },
  },
}: ProductCreatePageTypes) => {
  const {
    adminData,
    entityData,
    category,
    setCategory,
    categoriesSearchList,
    setCategoryId,
    onChangePrice,
    step1,
    step3,
    step2,
    setName,
    loading,
    categoryId,
    name,
    price,
    description,
    cost,
    imageCache,
    picture,
    quantity,
    isEditing,
    hasAPIError,
    APIErrorMessage,
    discountPrice,
    onChangeDiscount,
    setDescription,
    setQuantity,
    setCost,
    goToStep1,
    goToStep2,
    goToStep3,
    goBack,
    submit,
    onImagePick,
    goToCreateCategory,
    discount,
    highlightedOptionsList,
    onChangeHighlighte,
    onChangeSearch,
    isHighlightedString,
    sectorsSearchList,
    setSector,
    sector,
    variablePriceList,
    variablePriceString,
    onChangeSelect,
    setSectorId,
    isvariablePrice,
  } = useProductsCreate(entity, id || '');
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.ProductList}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('productCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <ProductTabs entityId={entity} create list={false} />
          <View style={[PaperColumns.tableContainerForm]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.columnForm}>
                  <View style={PageStyles.imageWrapper}>
                    <img
                      src={Images.ProductCreateIcon}
                      alt={locale.t('imagesAlt.product')}
                    />
                  </View>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('productCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('productCreate.content')}
                  </Text>
                </View>
                <View style={PaperColumns.columnFormRight}>
                  <View style={Styles.progressbarContainer}>
                    {!isvariablePrice ? (
                      <ProgressBar
                        width={dimensions.paperWidth / 2 - 70}
                        step1={step1}
                        step2={step2}
                        step3={step3}
                      />
                    ) : (
                      <ProgressBar
                        steps={2}
                        width={dimensions.paperWidth / 2 - 70}
                        step1={step1}
                        step2={step2}
                      />
                    )}
                  </View>
                  {step1 && !step2 && !step3 && (
                    <View style={PageStyles.subtitleHeader}>
                      <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                        {locale.t('productCreate.basicInfo')}
                      </Text>
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('productCreate.cancel')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goBack}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('productCreate.next')}
                            onPress={goToStep2}
                            disabled={
                              name.trim() === '' ||
                              category.trim() === '' ||
                              categoryId.trim() === '' ||
                              loading
                            }
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && !step3 && (
                    <View style={PageStyles.subtitleHeader}>
                      <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                        {locale.t('productCreate.quantity')}
                      </Text>
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('productCreate.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep1}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          {!isvariablePrice && (
                            <MFullWidthButton
                              label={locale.t('productCreate.next')}
                              onPress={goToStep3}
                              disabled={
                                price === 0 ||
                                price === null ||
                                cost === 0 ||
                                cost === null ||
                                quantity === '' ||
                                loading ||
                                description === ''
                              }
                              height={42}
                              fontSize={14}
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && step3 && (
                    <View style={PageStyles.subtitleHeader}>
                      <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                        {locale.t('productCreate.basicInfo')}
                      </Text>
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('productCreate.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={isvariablePrice ? goToStep1 : goToStep2}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('productCreate.register')}
                            onPress={submit}
                            disabled={
                              (imageCache.trim() === '' || loading) &&
                              !isEditing
                            }
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  {step1 && !step2 && !step3 && (
                    <View style={Styles.inputContainer}>
                      <Text style={Styles.content}>
                        {locale.t('productCreate.content1')}
                      </Text>
                      <View style={PageStyles.formRow}>
                        <View style={[PageStyles.inputWrapper]}>
                          <MTextInput
                            label={locale.t('productCreate.form.name')}
                            value={name}
                            onChangeText={setName}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={[PageStyles.formRowInput]}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <SearchableInput
                            value={category}
                            onChange={InputValue => setCategory(InputValue)}
                            label={locale.t('productCreate.form.category')}
                            data={categoriesSearchList}
                            onSelect={item => {
                              setCategoryId(item.value);
                              setCategory(item.label);
                            }}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <Select
                            selectedValue={isHighlightedString}
                            onValueChange={onChangeHighlighte}
                            label={locale.t('productCreate.form.isHighlighted')}
                            values={highlightedOptionsList}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={[PageStyles.formRowInput]}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <Select
                            label={locale.t('productCreate.form.variablePrice')}
                            onValueChange={onChangeSelect}
                            selectedValue={variablePriceString}
                            values={variablePriceList}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <SearchableInput
                            value={sector}
                            onChange={onChangeSearch}
                            label={locale.t('productCreate.form.sector')}
                            data={sectorsSearchList}
                            onSelect={item => {
                              setSectorId(item.value);
                              setSector(item.label);
                            }}
                          />
                        </View>

                        {categoriesSearchList.length === 0 && (
                          <TouchableOpacity
                            style={Styles.addIconWrapper}
                            activeOpacity={1}
                            onPress={goToCreateCategory}>
                            <View style={Styles.addIcon}>
                              <img
                                src={Images.PlusISquareIcon}
                                alt={locale.t('imagesAlt.edit')}
                              />
                            </View>
                            <Text style={Styles.add}>
                              {locale.t('add_category')}
                            </Text>
                          </TouchableOpacity>
                        )}
                      </View>
                    </View>
                  )}
                  {step1 && step2 && !step3 && (
                    <View style={Styles.inputContainer}>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <MTextInput
                            label={locale.t('productCreate.form.cost')}
                            value={cost}
                            prefix="R$ "
                            onChangeValue={setCost}
                            isCurrency
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <MTextInput
                            label={locale.t('productCreate.form.price')}
                            value={price}
                            prefix="R$ "
                            onChangeValue={onChangePrice}
                            isCurrency
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View
                        style={[
                          PageStyles.formRow,
                          { justifyContent: 'space-between' },
                        ]}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <PercentageInput
                            label={locale.t(
                              'productCreate.form.employeeDiscount',
                            )}
                            discount={discount}
                            onChange={val => onChangeDiscount(val || 0)}
                          />
                        </View>

                        <View
                          style={[
                            PageStyles.inputWrapper,
                            Styles.discountAmountContainer,
                          ]}>
                          {discountPrice > 0 && (
                            <MTextInput
                              label={locale.t(
                                'productCreate.form.priceForEmployees',
                              )}
                              value={discountPrice}
                              prefix="R$ "
                              onChangeValue={() => null}
                              isCurrency
                              height={42}
                              borderRadius={14}
                            />
                          )}
                        </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <MTextInput
                            label={locale.t('productCreate.form.description')}
                            value={description}
                            onChangeText={setDescription}
                            multiline
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <MTextInput
                            label={locale.t('productCreate.form.quantity')}
                            value={quantity}
                            onChangeText={setQuantity}
                            mask="0000"
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && step3 && (
                    <View style={Styles.inputsContainerStep3}>
                      <Text style={Styles.content}>
                        {locale.t('imageContent')}
                      </Text>
                      <View>
                        <UploadComponent
                          onFileSelect={onImagePick}
                          uri={imageCache || picture}
                        />
                      </View>
                    </View>
                  )}
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default ProductCreatePage;
