import { StyleSheet } from 'react-native';
import { colors, dimensions } from '@/consts';
import { typography } from '@/styles';
import { colorSchema } from '@/consts/colors';

export const FinanceExtractsPageStyle = StyleSheet.create({
  container: {
    borderRadius: 20,
    backgroundColor: colors.paymentData.bg,
    minHeight: dimensions.paperHeight - 55,
    padding: 30,
  },
  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    zIndex: 999,
    marginTop: -18,
  },
  InputWarpper: {
    marginRight: 30,
  },
  databaseWarpper: {
    zIndex: 9,
  },
  modal: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  containerBalance: {
    marginHorizontal: 30,
  },
  containerVC: {
    width: '18%',
  },
  labelBalance: {
    marginBottom: 5,
    ...typography.p3,
    color: colorSchema.PureBlack,
    fontWeight: '700',
  },
  labelVC: {
    marginBottom: 5,
    ...typography.p3,
    color: colorSchema.PureBlack,
    fontWeight: '700',
  },

  containerText: {
    borderColor: colorSchema.Gray2,
    paddingVertical: 10,
    borderRadius: 10,
  },

  containerTextVC: {
    zIndex: 99999,
    paddingVertical: 10,
  },
  textBalance: {
    width: 94,
    textAlign: 'center',
  },
  textVC: {
    ...typography.p3,
    fontWeight: '200',
    color: colors.datatableHeader.label,
  },
  selectContainer: {
    paddingHorizontal: 30,
    width: 200,
  },
  spacer30: {
    width: 30,
    height: 30,
  },
  label: {
    ...typography.p3,
    fontWeight: '700',
    marginBottom: 15,
  },
  containerSwitch: {
    alignItems: 'center',
    marginRight: 30,
  },
});
