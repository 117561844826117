import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '../routes';
import { CategoryListPage } from '../../pages/categoryslist';
import { CategoryCreatePage } from '../../pages/categorycreate';
import { CategoryRouteParams } from '../routes/RoutesParams';

const Stack = createStackNavigator<CategoryRouteParams>();

export const Category = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.CategoryList}
      component={CategoryListPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.CategoryCreate}
      component={CategoryCreatePage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Category;
