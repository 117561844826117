import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '../routes';
import { SectorRouteParams } from '../routes/RoutesParams';
import { SectorCreatePage } from '@/pages/sectorcreate';
import { SectorListPage } from '@/pages/sectorlist';

const Stack = createStackNavigator<SectorRouteParams>();

export const Sector = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.sectorList}
      component={SectorListPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.sectorCreate}
      component={SectorCreatePage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Sector;
