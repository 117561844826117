import { useNavigation } from '@react-navigation/native';
import { Routes } from '@/navigation/routes';
import { useNavigateRegisterStep2 } from '@/navigation/routes/hooks/useNavigateRegisterStep2';
import { useNavigateEditPlan } from '@/navigation/routes/hooks/useNavigateEditPlan';
import { useNavigateEntityInternalPages } from '@/navigation/routes/hooks/useNavigateEntityInternalPages';
import { useNavigateEditEntity } from '@/navigation/routes/hooks/useNavigateEditEntity';
import { useNavigatePayment } from '@/navigation/routes/hooks/useNavigatePayment';
import { useNavigateRecoveryPassword2 } from '@/navigation/routes/hooks/useNavigateRecoveryPassword2';
import { useNavigateToCreateCategory } from '@/navigation/routes/hooks/useNavigateToCreateCategory';

export const useNavigate = () => {
  const navigation = useNavigation();
  const { goToStep2 } = useNavigateRegisterStep2();
  const { gotoEditPlan } = useNavigateEditPlan();
  const { goToEntityPage } = useNavigateEntityInternalPages();
  const { goToEditEntity } = useNavigateEditEntity();
  const { goToPayment } = useNavigatePayment();
  const { goToRecoveryPassword } = useNavigateRecoveryPassword2();
  const { goToCreateCategory } = useNavigateToCreateCategory();

  const navigate = (route: Routes, params?: any) => {
    navigation.navigate(route, params);
  };

  return {
    navigate,
    goToEntityPage,
    goToStep2,
    gotoEditPlan,
    goToEditEntity,
    goToPayment,
    goToRecoveryPassword,
    goToCreateCategory,
    addListener: (type: 'focus' | 'blur' | 'state', cb: () => void) => navigation.addListener(type, cb),
    goBack: () => navigation.goBack(),
  };
};

export default useNavigate;
