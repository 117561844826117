import { StyleSheet } from 'react-native';
import { colors } from '@/consts';
import { typography } from '@/styles';

export const styles = StyleSheet.create({
  picker: {
    height: 56,
    borderRadius: 10,
    borderWidth: 1,
    color: colors.select.color,
    borderColor: colors.select.border,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  label: {
    ...typography.p3,
    fontWeight: '700',
    marginBottom: 5,
  },
});
