import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '../routes';
import { AdministrationProfilesPage } from '../../pages/administrationprofiles';
import { AdministrationCreatePage } from '../../pages/administrationcreate';
import { AdministrationRouteParams } from '../routes/RoutesParams';

const Stack = createStackNavigator<AdministrationRouteParams>();

export const Administration = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.AdministrationProfiles}
      component={AdministrationProfilesPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.AdministrationCreate}
      component={AdministrationCreatePage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Administration;
