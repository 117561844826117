import React from 'react';
import { Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { LoginRegisterStyle } from '@/styles/common';
import { dimensions, Images } from '@/consts';
import locale from '@/internationalization';
import { RouteParams } from '@/pages/recoverypassword2/RecoveryPassword2.types';
import useRecoveryPassword2Functions from '@/pages/recoverypassword2/RecoveryPassword2.functions';
import { ErrorHandler } from '@/components/errorhandler';

export const RecoveryPassword2 = ({ route: { params } }: RouteParams) => {
  const {
    setPassword,
    setConfirmPassword,
    submitUpdatePassword,
    isLoading,
    apiError,
    password,
    confirmPassword,
  } = useRecoveryPassword2Functions(params?.hash || '');
  return (
    <View style={LoginRegisterStyle.container}>
      <View style={LoginRegisterStyle.imageContainer}>
        <img
          src={Images.Access}
          height={dimensions.height}
          width={dimensions.width * 0.5}
          alt={locale.t('imagesAlt.access')}
        />
      </View>
      <View style={LoginRegisterStyle.formWrapper}>
        <View style={LoginRegisterStyle.formContainer}>
          <Text style={LoginRegisterStyle.steps}>{locale.t('step2')}</Text>
          <Text style={LoginRegisterStyle.title}>{locale.t('recoveryPassword')}</Text>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              value={password}
              onChangeText={setPassword}
              label={locale.t('password')}
              height={42}
              borderRadius={14}
              secureTextEntry
              isPassword
            />
          </View>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              value={confirmPassword}
              onChangeText={setConfirmPassword}
              label={locale.t('confirmPassword')}
              height={42}
              borderRadius={14}
              secureTextEntry
              isPassword
            />
          </View>
          {apiError && (
            <ErrorHandler message={apiError} />
          )}
          <View style={[LoginRegisterStyle.buttonsContainer, { marginBottom: 30 }]}>
            <MFullWidthButton
              onPress={() => submitUpdatePassword()}
              label={locale.t('save')}
              height={42}
              fontSize={14}
              disabled={
                password.trim() === ''
                || confirmPassword.trim() === ''
                || password.trim() !== confirmPassword.trim()
                || isLoading
              }
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default RecoveryPassword2;
