import React from 'react';

import { View } from 'react-native';
import { Header } from '@/components/header';
import { Subheader } from '@/components/subheader';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Datatable } from '@/components/datatable';
import { categoryListHeader } from './CategoryListPage.consts';
import { CategoryTabs } from '@/components/categorytabs';
import { CategoryListPageTypes } from './CategoryListPage.types';
import { Routes } from '@/navigation/routes';
import { useCategoryList } from './CategoryListPage.functions';

export const CategoryListPage = ({
  route: { params: { entity } },
}: CategoryListPageTypes) => {
  const {
    adminData,
    entityData,
    loading,
    categoriesDatatable,
    goToEdit,
    updateStatus,
  } = useCategoryList(entity);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.CategoryList}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('category.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <CategoryTabs entityId={entity} list create={false} />
          <View style={[PaperColumns.tableContainer]}>
            <Datatable
              headers={categoryListHeader}
              loading={loading}
              data={categoriesDatatable}
              onSwitchChange={updateStatus}
              onEditPress={goToEdit}
              searchFields={['name']}
            />
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default CategoryListPage;
