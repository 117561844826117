// import { View } from 'react-native';
// import { MFullWidthButton } from 'menupass-ui';
import React from 'react';
import { PageTitle } from '../pagetitle';
// import locale from '../../internationalization';
import { EntitiesPageTitleTypes } from './EntitiesPageTitle.types';
// import { entitiesPageTitleStyles } from './EntitiesPageTitle.styles';
// import { colors } from '../../consts';

export const EntitiesPageTitle = ({ title /* exportCsv, exportPdf */ }: EntitiesPageTitleTypes) => (
  <PageTitle
    title={title}
  //   component={(
  //     <View style={entitiesPageTitleStyles.buttonContainer}>
  //       <View style={entitiesPageTitleStyles.btnWrapper}>
  //         <MFullWidthButton
  //           label={locale.t('exportPdf')}
  //           onPress={exportPdf}
  //           labelColor={colors.entitiesPageTitle.labelColor}
  //           bgColor={colors.entitiesPageTitle.bgColor}
  //           height={42}
  //           fontSize={14}
  //         />
  //       </View>
  //       <View style={entitiesPageTitleStyles.btnWrapper}>
  //         <MFullWidthButton
  //           label={locale.t('exportCsv')}
  //           onPress={exportCsv}
  //           labelColor={colors.entitiesPageTitle.labelColor}
  //           bgColor={colors.entitiesPageTitle.bgColor}
  //           height={42}
  //           fontSize={14}
  //         />
  //       </View>
  //     </View>
  // )}
  />
);

export default EntitiesPageTitle;
