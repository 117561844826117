export const cardBrands = [
  {
    label: 'Master',
    value: 'master',
  },
  {
    label: 'Visa',
    value: 'visa',
  },
  {
    label: 'Amex',
    value: 'amex',
  },
];
