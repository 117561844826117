import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import locale from '@/internationalization';

import { Routes } from '../../navigation/routes';
import { useSystemPeriodCreate } from './SystemPeriodCreatePage.functions';
import { SystemPeriodCreatePageTypes, selectPeriod } from './SystemPeriodCreatePage.types';
import { Container } from '@/components/container';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { PageStyles } from '@/styles/common/PageStyles';
import { colors } from '@/consts';
import { Styles } from './SystemPeriodCreatePage.styles';
import Select from '@/components/select/Select';
import { ErrorHandler } from '@/components/errorhandler';
import { SystemTabs } from '@/components/systemtabs';

const SystemPeriodCreatePage = ({
  route: { params: { entity, id } },
}: SystemPeriodCreatePageTypes) => {
  const {
    entityData,
    adminData,
    hasAPIError,
    APIErrorMessage,
    time,
    periodValue,
    loading,
    description,
    goBack,
    onSubmit,
    setPeriodValue,
    onChangeTime,
    setDescription
  } = useSystemPeriodCreate(entity, id || '');

  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.periodCreate}
        role={adminData?.adminType?.type}
      />

      <Container>
        <EntitiesPageTitle
          title={locale.t('system.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <SystemTabs entityId={entity} system={false} periodCreate periodList={false} />
          <View style={PaperColumns.tableContainer}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('systemPeriodCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('systemPeriodCreate.content')}
                  </Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                      {locale.t('systemPeriodCreate.basicInfo')}
                    </Text>
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          onPress={goBack}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('save')}
                          onPress={onSubmit}
                          disabled={
                            time.trim() === ''
                            || loading
                          }
                          height={42}
                          fontSize={14}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={Styles.inputContainer}>
                    {hasAPIError && (
                      <ErrorHandler message={APIErrorMessage} />
                    )}
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <View style={Styles.SelectContainer}>
                          <Select
                            label={locale.t('systemPeriodCreate.period')}
                            onValueChange={(value: any) => setPeriodValue(value)}
                            selectedValue={periodValue}
                            values={selectPeriod}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('systemPeriodCreate.time')}
                          placeholder={locale.t('systemPeriodCreate.hourPlaceholder')}
                          value={time}
                          onChangeText={onChangeTime}
                          height={42}
                          borderRadius={14}
                          mask="00:00"
                        />
                      </View>
                    </View>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <View style={Styles.SelectContainer}>
                          <MTextInput
                            label={locale.t('systemPeriodCreate.describe')}
                            value={description}
                            onChangeText={setDescription}
                            height={42}
                            borderRadius={14}
                            maxLength={80}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};
export default SystemPeriodCreatePage;
