import React from 'react';

import { Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { LoginRegisterStyle } from '@/styles/common';
import { colors, dimensions, Images } from '@/consts';
import locale from '@/internationalization';
import { Styles } from './RegisterStep2.styles';
import { RegisterStep2RouteParams } from '@/navigation/routes/RoutesParams';
import { useRegisterStep2, VERIFICATION_CODE_TIME } from './RegisterStep2.functions';
import { ErrorHandler } from '@/components/errorhandler';

type RouteParams = {
  route: {
    params: RegisterStep2RouteParams
  }
}

export const RegisterStep2 = ({
  route: {
    params: {
      name, email, password, passwordConfirm,
    },
  },
}: RouteParams) => {
  const {
    submit,
    sendVerificationCode,
    onChangeCode,
    goToRegister,
    code,
    isPhoneNumberValid,
    countDown,
    isDisabledByCuntDown,
    phone,
    setPhone,
    hasAPIError,
    APIErrorMessage,
    loading,
  } = useRegisterStep2({
    name,
    email,
    password,
    passwordConfirm,
  });
  return (
    <View style={LoginRegisterStyle.container}>
      <View style={LoginRegisterStyle.imageContainer}>
        <img
          src={Images.Access}
          height={dimensions.height}
          width={dimensions.width * 0.5}
          alt={locale.t('imagesAlt.access')}
        />
      </View>
      <View style={LoginRegisterStyle.formWrapper}>
        <View style={LoginRegisterStyle.formContainer}>
          <Text style={LoginRegisterStyle.steps}>{locale.t('register.step2')}</Text>
          <Text style={LoginRegisterStyle.title}>{locale.t('register.title')}</Text>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              label={locale.t('register.form.phone')}
              placeholder={locale.t('register.form.phonePlaceholder')}
              mask="(00) 00000-0000"
              value={phone}
              onChangeText={setPhone}
              height={42}
              borderRadius={14}
            />
          </View>
          <Text style={Styles.confirmationText}>{locale.t('register.confirmationCodeInstruction')}</Text>
          <View style={LoginRegisterStyle.verificationCodeContainer}>
            <View style={LoginRegisterStyle.buttonsContainer}>
              <MFullWidthButton
                label={locale.t('register.form.sendCode')}
                bgColor={colors.register.sendCodeBg}
                labelColor={colors.register.sendCodeLabel}
                onPress={sendVerificationCode}
                disabled={loading || isDisabledByCuntDown || phone.trim() === '' || phone.trim().length < 15}
                height={42}
                fontSize={14}
              />
            </View>
            <Text style={LoginRegisterStyle.verificationCode}>
              {countDown.currentSecond === 0 ? `00:${VERIFICATION_CODE_TIME}` : `00:${countDown.currentSecond}`}
            </Text>
          </View>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              label={locale.t('register.form.code')}
              placeholder={locale.t('register.form.codePlaceholder')}
              onChangeText={onChangeCode}
              value={code}
              onSubmitEditing={() => submit()}
              height={42}
              borderRadius={14}
            />
          </View>
          {hasAPIError && (
            <ErrorHandler message={APIErrorMessage} />
          )}
          <View style={LoginRegisterStyle.buttonsWarpper}>
            <View style={LoginRegisterStyle.buttonsContainer}>
              <MFullWidthButton
                label={locale.t('totemCreate.back')}
                bgColor={colors.newEntity.entitiesBtn}
                labelColor={colors.newEntity.entitiesBtnTitle}
                onPress={goToRegister}
                height={42}
                fontSize={14}
              />
            </View>
            <View style={LoginRegisterStyle.buttonsContainer}>
              <MFullWidthButton
                label={locale.t('register.form.forward')}
                onPress={() => submit()}
                disabled={
                phone.trim() === ''
                || phone.trim().length < 15
                || loading
                || code.trim() === ''
                || !isPhoneNumberValid
              }
                height={42}
                fontSize={14}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default RegisterStep2;
