import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { formatNumber } from 'react-native-currency-input';
import { useNavigate } from '../../navigation/routes/hooks/useNavigate';
import { Routes } from '../../navigation/routes';
import { useSystemPlans } from '../../api/plans';
import { Plan } from '../../types/plans';
import { useGetAdmin, useGetAdminId } from '../../api/admin';
import { useGetAdminPaymentMethods } from '../../api/payment';
import locale from '../../internationalization';
import {
  applyPhoneNumberMask,
  applyZipCodeMask,
  cnpjRemoveMask,
  cpfRemoveMask,
  currencyFormatFloat,
  emailIsValid,
  phoneNumberRemoveDDI,
  phoNumberRemoveMask,
} from '../../utils/strUtils';
import {
  useCancelSubscription,
  useCreateNewEntity,
  useGetEntity,
  useGetEntityBank,
  useGetEntityInfo,
  useUpdateEntity,
} from '../../api/entities';
import { formatOptions } from '../../utils/currencyMask';
import { useSearchZipCode } from '../../api/global';
import { Flows } from '@/navigation/routes/enums/Flows';
import { useGetTermsOfAcceptance, useTermsOfAcceptance } from '@/api/termsOfAcceptance';

export const useNewEntity = (id: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: paymentMethods } = useGetAdminPaymentMethods(adminId, !!adminId);
  const { data: entityData } = useGetEntity(id, !!id);
  const { data: entityInfo } = useGetEntityInfo(id, !!id);
  const { data: entityBank } = useGetEntityBank(id, !!id);
  const { data: plans } = useSystemPlans();
  const { data: termsOfAcceptance } = useGetTermsOfAcceptance(adminId, !!adminId);

  // step 1 fields
  const [plansSelect, setPlansSelect] = useState([]);
  const [cardsSelect, setCardsSelect] = useState<{ label: string, value: string }[]>([]);
  const [cardSelected, setCardSelected] = useState('');
  const [planSelected, setPlanSelected] = useState('');
  const [planRecurrence, setPlanRecurrence] = useState<'monthly' | 'annual' | string>('monthly');
  const [planRecurrenceRadio, setPlanRecurrenceRadio] = useState([]);
  const [planSelectedObject, setPlanSelectedObject] = useState<Plan>();
  const [logo, setLogo] = useState('');
  const [acceptanceTerm, setAcceptanceTerm] = useState<{ label: string, value: string }[]>([{ label: `${locale.t('newEntity.radioAcceptanceTerm.term')}`, value: '' }]);
  const [acceptanceTermLabel] = useState('term');

  const [loading, setLoading] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [emailError, setEmailError] = useState(false);

  // step 2 fields
  const [name, setName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [phone, setPhone] = useState('');

  // step 3 fields
  const [nameEmpresa, setNameEmpresa] = useState('');
  const [commercialName, setCommercialName] = useState('');
  const [identity, setIdentity] = useState('');
  const [responsibleName, setResponsibleName] = useState('');
  const [responsibleIdentity, setResponsibleIdentity] = useState('');
  const [responsiblePhone, setResponsiblePhone] = useState('');
  const [email, setEmail] = useState('');
  const [techName, setTechName] = useState('');
  const [techIdentity, setTechIdentity] = useState('');
  const [techEmail, setTechEmail] = useState('');
  const [techPhone, setTechPhone] = useState('');

  // step 4 fields
  const [bankCode, setBankCode] = useState('');
  const [accountTypeCode, setAccountTypeCode] = useState('');
  const [bankAgency, setBankAgency] = useState('');
  const [bankAgencyDigit, setBankAgencyDigit] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [bankAccountDigit, setBankAccountDigit] = useState('');

  // step 5 fields
  const [imageCache, setImageCache] = useState('');

  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);

  // modal
  const [isCancelSubscriptionModalOpened, setIsCancelSubscriptionModalOpened] = useState(false);

  const { navigate, goToPayment } = useNavigate();

  const queryClient = useQueryClient();
  const mutator = useCreateNewEntity();
  const mutatorUpdate = useUpdateEntity();
  const cancelSubscriptionMutation = useCancelSubscription();
  const termsOfAcceptanceMutator = useTermsOfAcceptance();

  const activePlans = useRef<Plan[]>([]);

  const goToEntities = () => {
    navigate(Routes.Home);
  };

  const formatPlansSelect = () => {
    activePlans.current = plans.filter((p: Plan) => p.isActive);

    const plansList: any = activePlans.current.map((plan: Plan) => ({
      label: plan.name,
      value: plan.id,
    }));

    if (plansList?.length) {
      setPlanSelected(plansList[0].value || '');
      setPlansSelect(plansList);
      setPlanSelectedObject(activePlans.current[0]);
    }
  };

  const formatRecurrenceRadio = (index: number) => {
    const radioList: any = activePlans.current.map((plan: Plan) => ({
      id: plan.id,
      radio: [
        {
          label: `${formatNumber(currencyFormatFloat(plan.monthlyPrice as number), formatOptions)}/${locale.t('month')}`,
          value: 'monthly',
        },
        {
          label: `${formatNumber(currencyFormatFloat(plan.annualPrice as number), formatOptions)}/${locale.t('year')}`,
          value: 'annual',
        },
      ],
    }));

    if (radioList[index] && radioList[index].radio) {
      setPlanRecurrenceRadio(radioList[index].radio);
    }
  };

  const onChangePlan = (item: string) => {
    if (plans && plans?.length) {
      const index = activePlans.current.findIndex((p: Plan) => p.id === item);
      setPlanSelectedObject(plans[index]);
      setPlanSelected(item);
      formatRecurrenceRadio(index);
    }
  };

  const goToAddPayment = () => {
    goToPayment({
      params: {
        flow: Flows.onBoarding,
      },
      screen: Routes.PaymentData,
    });
  };

  const onRadioChange = (value: string) => {
    setPlanRecurrence(value);
  };

  const goToStep1 = () => {
    setStep1(true);
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
  };

  const goToStep2 = () => {
    setStep1(true);
    setStep2(true);
    setStep3(false);
    setStep4(false);
    setStep5(false);
  };

  const goToStep3 = () => {
    setStep1(true);
    setStep2(true);
    setStep3(true);
    setStep4(false);
    setStep5(false);
  };

  const goToStep4 = () => {
    setStep1(true);
    setStep2(true);
    setStep3(true);
    setStep4(true);
    setStep5(false);
  };

  const goToStep5 = () => {
    setStep1(true);
    setStep2(true);
    setStep3(true);
    setStep4(true);
    setStep5(true);
  };

  const emailChange = (e: string) => {
    setEmail(e);
    setEmailError(emailIsValid(e));
  };

  const emailTechChange = (e: string) => {
    setTechEmail(e);
    setEmailError(emailIsValid(e));
  };

  const onImagePick = (file: File, uri: string) => {
    setImageCache(uri);
  };

  const searchZipCodeMutation = useSearchZipCode();
  const onSearchZipCode = async () => {
    if (zipCode.length === 9) {
      const result = await searchZipCodeMutation.mutateAsync({
        zipCode,
      });

      const data = await result.json();

      setState(data.state || '');
      setCity(data.city || '');
      setStreet(data.address || '');
      setComplement(data.complement || '');
      setNumber(data.addressNumber || '');
      setZipCode(data.zipCode || '');
    }
  };

  const create = () => mutator.mutateAsync({
    name,
    state,
    zipCode,
    city,
    adminId,
    phone: `+55${phoNumberRemoveMask(phone)}`,
    address: street,
    addressLine2: complement,
    logo: imageCache,
    addressNumber: number,
    country: 'Brazil',
    paymentMethodId: cardSelected,
    recurrentType: planRecurrence,
    systemPlanId: planSelected,
    entityLegalInfo: {
      name: nameEmpresa,
      commercialName,
      identity: cnpjRemoveMask(identity),
      responsibleName,
      responsibleIdentity: cpfRemoveMask(responsibleIdentity),
      responsiblePhone: phoNumberRemoveMask(responsiblePhone),
      email,
      techName,
      techIdentity,
      techEmail,
      techPhone: phoNumberRemoveMask(techPhone),
    },
    entityBankData: {
      bank: {
        code: bankCode,
      },
      accountType: {
        code: accountTypeCode,
      },
      bankAgency,
      bankAgencyDigit,
      bankAccount,
      bankAccountDigit,
    },
  });

  const update = () => mutatorUpdate.mutateAsync({
    id,
    name,
    state,
    zipCode,
    city,
    adminId,
    phone: `+55${phoNumberRemoveMask(phone)}`,
    address: street,
    addressLine2: complement,
    logo: imageCache,
    addressNumber: number,
    country: 'Brazil',
    paymentMethodId: cardSelected,
    recurrentType: planRecurrence,
    systemPlanId: planSelected,
    systemMinRecurrentPrice: entityData?.systemMinRecurrentPrice,
    systemMinProductQuantity: entityData?.systemMinProductQuantity,
    entityLegalInfo: {
      id,
      name: nameEmpresa,
      commercialName,
      identity,
      responsibleName,
      responsibleIdentity,
      responsiblePhone: phoNumberRemoveMask(responsiblePhone),
      email,
      techName,
      techIdentity,
      techEmail,
      techPhone: phoNumberRemoveMask(techPhone),
    },
    entityBankData: {
      id,
      bank: {
        code: bankCode,
      },
      accountType: {
        code: accountTypeCode,
      },
      bankAgency,
      bankAgencyDigit,
      bankAccount,
      bankAccountDigit,
    },
  });

  const submit = async () => {
    setLoading(true);

    try {
      const result = id && entityInfo?.id ? await update() : await create();

      const data = await result.json();

      if (result.status !== 201 && result.status !== 200) {
        const err = data.code ? locale.t(`errors.${data.code}`) : locale.t('errors.400');
        setAPIErrorMessage(err);
        setHasAPIError(true);
        setLoading(false);
        return;
      }

      if (!id && !termsOfAcceptance?.accepted) {
        await termsOfAcceptanceMutator.mutateAsync({
          accepted: true,
          adminEntity: adminId,
        });
      }

      setLoading(false);
      await queryClient.invalidateQueries('adminEntities');

      navigate(Routes.Home);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setAPIErrorMessage(locale.t('errors.500'));
      setHasAPIError(true);
    }
  };

  const onCancelPlan = async () => {
    setIsCancelSubscriptionModalOpened(false);
    await cancelSubscriptionMutation.mutateAsync(id);
    await queryClient.invalidateQueries('adminEntities');
  };

  const onCloseModal = () => {
    setIsCancelSubscriptionModalOpened(false);
  };

  const onOpenModal = () => {
    setIsCancelSubscriptionModalOpened(true);
  };

  const openAbaTerm = () => {
    const url = 'https://agreement.menupass.com.br/';
    window.open(url, '_blank');
  };

  const onTermChange = () => {
    setAcceptanceTerm([{ label: `${locale.t('newEntity.radioAcceptanceTerm.term')}`, value: 'term' }]);
  };

  useEffect(() => {
    if (termsOfAcceptance?.accepted) {
      setAcceptanceTerm([{ label: `${locale.t('newEntity.radioAcceptanceTerm.term')}`, value: 'term' }]);
    }
  }, [termsOfAcceptance, plans, entityData]);

  useEffect(() => {
    if (plans?.length > 0) {
      formatPlansSelect();
      formatRecurrenceRadio(0);
    }
  }, [plans]);

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      setCardsSelect([{
        label: `${locale.t('cardTruncation')} ${paymentMethods[0]?.cardLast4Numbers}`,
        value: paymentMethods[0]?.id,
      }]);
      setCardSelected(paymentMethods[0]?.id);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (entityData && plans && plans?.length && entityInfo && entityBank) {
      // step 1
      onRadioChange(entityData?.payments[0]?.paymentPlan.recurrentType);

      if (entityData?.payments.length > 0) {
        onChangePlan(entityData?.payments[0]?.systemPlan.id);
      } else {
        onChangePlan(plans[0].id);
      }

      // step 2
      setName(entityData?.name);
      setPhone(applyPhoneNumberMask(phoneNumberRemoveDDI(entityData?.phone)));
      setZipCode(applyZipCodeMask(entityData?.zipCode));
      setState(entityData?.state);
      setCity(entityData?.city);
      setStreet(entityData?.address);
      setNumber(entityData?.addressNumber);
      setComplement(entityData?.addressLine2);

      // step 3
      setNameEmpresa(entityInfo?.name);
      setCommercialName(entityInfo?.commercialName);
      setIdentity(entityInfo?.identity);
      setResponsibleName(entityInfo?.responsibleName);
      setResponsibleIdentity(entityInfo?.responsibleIdentity);
      setResponsiblePhone(applyPhoneNumberMask(entityInfo?.responsiblePhone));
      setEmail(entityInfo?.email);
      setTechName(entityInfo?.techName);
      setTechIdentity(entityInfo?.techIdentity);
      setTechEmail(entityInfo?.techEmail);
      setTechPhone(applyPhoneNumberMask(entityInfo?.techPhone));

      // step 4
      setBankCode(entityBank?.bank);
      setAccountTypeCode(entityBank?.accountType);
      setBankAgency(entityBank?.bankAgency);
      setBankAgencyDigit(entityBank?.bankAgencyDigit);
      setBankAccount(entityBank?.bankAccount);
      setBankAccountDigit(entityBank?.bankAccountDigit);

      // step 3
      setLogo(entityData?.logo);
      setIsEditing(true);
    }
  }, [entityData, plans, entityInfo, entityBank]);

  useEffect(() => {
    setStep1(true);
  }, []);

  return {
    plansSelect,
    planSelected,
    cardsSelect,
    cardSelected,
    adminData,
    planSelectedObject,
    planRecurrenceRadio,
    planRecurrence,
    step1,
    step2,
    step3,
    step4,
    step5,
    name,
    zipCode,
    state,
    street,
    city,
    number,
    complement,
    nameEmpresa,
    commercialName,
    identity,
    responsibleName,
    responsibleIdentity,
    responsiblePhone,
    email,
    techName,
    techIdentity,
    techEmail,
    techPhone,
    bankCode,
    accountTypeCode,
    bankAgency,
    bankAgencyDigit,
    bankAccount,
    bankAccountDigit,
    setBankCode,
    setAccountTypeCode,
    setBankAgency,
    setBankAgencyDigit,
    setBankAccount,
    setBankAccountDigit,
    setNameEmpresa,
    setCommercialName,
    setIdentity,
    setResponsibleName,
    setResponsibleIdentity,
    setResponsiblePhone,
    setTechName,
    setTechIdentity,
    setTechEmail,
    setTechPhone,
    setName,
    setCity,
    setStreet,
    setState,
    setNumber,
    setComplement,
    loading,
    imageCache,
    logo,
    phone,
    setZipCode,
    setPhone,
    hasAPIError,
    APIErrorMessage,
    isEditing,
    entityData,
    onImagePick,
    onChangePlan,
    goToEntities,
    goToAddPayment,
    onRadioChange,
    goToStep1,
    goToStep2,
    goToStep3,
    goToStep4,
    goToStep5,
    submit,
    emailChange,
    emailTechChange,
    emailError,
    onSearchZipCode,
    onCancelPlan,
    onCloseModal,
    isCancelSubscriptionModalOpened,
    onOpenModal,
    acceptanceTerm,
    acceptanceTermLabel,
    onTermChange,
    openAbaTerm,
  };
};
