import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity } from '@/api/entities';
import {
  applyPhoneNumberMask, applyZipCodeMask, emailIsValid, phoNumber,
} from '@/utils/strUtils';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import {
  useCheckAdminClientEmail, useCheckAdminClientPhone,
  useCreateAdminClient,
  useCreateStaff,
  useGetAdminClientById,
  useUpdateAdminClient,
} from '@/api/client';
import locale from '@/internationalization';
import { Routes } from '@/navigation/routes';
import { useSearchZipCode } from '@/api/global';
import { ResponsibleRoles } from '@/types/responsibleRoles';

export const useResponsibleCreate = (entity: string, id: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: adminClient } = useGetAdminClientById(id, !!id);

  const { goBack, goToEntityPage } = useNavigate();

  // step 1
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [responsibleType, setResponsibleType] = useState<ResponsibleRoles>(ResponsibleRoles.AdminClient);

  // step 2
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);

  const queryClient = useQueryClient();
  const mutator = useCreateAdminClient();
  const mutatorStaff = useCreateStaff();
  const mutatorUpdate = useUpdateAdminClient();

  const emailChange = (e: string) => {
    setEmail(e);
    setEmailError(emailIsValid(e));
  };

  const goToStep2 = () => {
    setStep1(true);
    setStep2(true);
  };

  const goToStep1 = () => {
    setStep1(true);
    setStep2(false);
  };

  const searchZipCodeMutation = useSearchZipCode();
  const onSearchZipCode = async () => {
    if (zipCode.length === 9) {
      const result = await searchZipCodeMutation.mutateAsync({
        zipCode,
      });

      const data = await result.json();

      setState(data.state || '');
      setCity(data.city || '');
      setStreet(data.address || '');
      setComplement(data.complement || '');
      setNumber(data.addressNumber || '');
      setZipCode(data.zipCode || '');
    }
  };

  const updateAdmin = () => mutatorUpdate.mutateAsync({
    id,
    name,
    email,
    entity,
    state,
    city,
    zipCode,
    password,
    nationalId: cpf,
    phone: phoNumber(phone),
    username: email,
    address: street,
    addressLine2: complement,
    addressNumber: number,
    country: 'Brazil',
    role: responsibleType,
    user: adminClient?.user.id,
  });

  const createAdmin = () => {
    const payload = {
      name,
      email,
      entity,
      state,
      city,
      zipCode,
      password,
      nationalId: cpf,
      phone: phoNumber(phone),
      username: email,
      address: street,
      addressLine2: complement,
      addressNumber: number,
      country: 'Brazil',
    }
    if (responsibleType === ResponsibleRoles.AdminClient) {
     return mutator.mutateAsync(payload) 
    } 
     return mutatorStaff.mutateAsync(payload);
    
  }
  
 

  const submit = async () => {
    setLoading(true);

    try {
      const result = id ? await updateAdmin() : await createAdmin();
      const data = await result.json();

      if (result.status !== 201 && result.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        const err = data.code ? locale.t(`errors.${data.code}`) : locale.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }

      setLoading(false);

      await queryClient.invalidateQueries('adminClients');
      goToEntityPage(Routes.Responsible, {
        params: {
          entity,
        },
        screen: Routes.ResponsibleProfiles,
      });
    } catch (e) {
      console.error('useRegisterStep2.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setLoading(false);
    }
  };

  const [checkEmail, setCheckEmail] = useState('');
  const [checkPhone, setCheckPhone] = useState('');
  const { data: emailAlreadyExists } = useCheckAdminClientEmail(checkEmail, !!checkEmail);
  const { data: phoneAlreadyExists } = useCheckAdminClientPhone(checkPhone, !!checkPhone);

  const checkIfEmailAlreadyExists = () => {
    if (!emailError) {
      setCheckEmail(email);
    }
  };

  const checkIfPhoneAlreadyExists = () => {
    if (phone.trim() !== '' && phone.length === 15) {
      setCheckPhone(phone);
    }
  };

  useEffect(() => {
    if (emailAlreadyExists?.id) {
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.ER_DUP_ENTRY_EMAIL'));
      return;
    }

    setHasAPIError(false);
    setAPIErrorMessage('');
  }, [emailAlreadyExists]);

  useEffect(() => {
    if (phoneAlreadyExists?.id) {
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.ER_DUP_ENTRY_PHONE'));
      return;
    }

    setHasAPIError(false);
    setAPIErrorMessage('');
  }, [phoneAlreadyExists]);

  useEffect(() => {
    goToStep1();
  }, []);

  useEffect(() => {
    if (adminClient) {
      setName(adminClient?.name);
      setEmail(adminClient?.email);
      setCpf(adminClient?.nationalId);
      setPhone(applyPhoneNumberMask(adminClient?.phone));
      setStreet(adminClient?.address);
      setNumber(adminClient?.addressNumber);
      setZipCode(applyZipCodeMask(adminClient?.zipCode));
      setComplement(adminClient?.addressLine2);
      setCity(adminClient?.city);
      setState(adminClient?.state);
      setIsEditing(true);
      setResponsibleType(adminClient?.user?.role)
    }
  }, [adminClient]);

  return {
    adminData,
    entityData,
    name,
    setName,
    email,
    cpf,
    setCpf,
    phone,
    setPhone,
    emailError,
    loading,
    step1,
    step2,
    zipCode,
    setZipCode,
    state,
    setState,
    street,
    setStreet,
    city,
    setCity,
    number,
    setNumber,
    complement,
    setComplement,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    hasAPIError,
    APIErrorMessage,
    isEditing,
    responsibleType,
    setResponsibleType,
    emailChange,
    onSearchZipCode,
    goToStep1,
    goToStep2,
    goBack,
    submit,
    checkIfEmailAlreadyExists,
    checkIfPhoneAlreadyExists,
  };
};
