import { useEffect, useState } from 'react';
import { formatNumber } from 'react-native-currency-input';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity } from '@/api/entities';
import { useGetEntityClients, useTransferRecharge } from '@/api/client';
import { Client } from '@/types/client';
import locale from '@/internationalization';
import { Routes } from '@/navigation/routes';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import { currencyFormat } from '@/utils/strUtils';
import { formatOptions } from '@/utils/currencyMask';

export const useManualRechargeCreate = (entity: string) => {
  const { goToEntityPage } = useNavigate();

  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);

  const { data: clients, isLoading: loading } = useGetEntityClients(entity, !!entity);

  const [clientOf, setClientOf] = useState('');
  const [clientTo, setClientTo] = useState('');
  const [clientOfVc, setClientOfVc] = useState('');
  const [clientToVc, setClientToVc] = useState('');
  const [value, setValue] = useState<number | null>(0);
  const [hasAPIError, setHasAPIError] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');

  const [clientIdOf, setClientIdOf] = useState('');
  const [clientIdTo, setClientIdTo] = useState('');
  const [clientsSearchList, setClientsSearchList] = useState<any[]>([]);
  const [disabled, setDisabled] = useState(false);

  const mutation = useTransferRecharge();

  const parseCurrency = (val: string) => {
    const parsedValue = val.replace('R$', '').replace(/\s/g, '').replace(',', '.');
    return parseFloat(parsedValue);
  };

  const recharge = async () => {
    setDisabled(true);
    setHasAPIError(false)
    setAPIErrorMessage('')
    try{
      if (clientIdOf && clientIdTo) {
        const clientOfbalance = parseCurrency(clientOfVc);
      if( value && value > clientOfbalance ) {
        setDisabled(false)
        setHasAPIError(true);
        const err =  `${clientOf}${locale.t(`rechargeTransferCreate.error`)}`
        setAPIErrorMessage(err);
        return;
      }
  
     const result = await mutation.mutateAsync({
        amount: currencyFormat(formatNumber(value || 0, formatOptions)),
        clientIds: {
          of: clientIdOf, 
          to: clientIdTo,
        }
      });
      
      if (result.status !== 201) {
        setDisabled(false);
        setHasAPIError(true);
        const err = locale.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }

      goToEntityPage(Routes.Finance, {
        params: {
          entity,
        },
        screen: Routes.FinanceExtracts,
      });
      window.location.reload();
    }
    } catch (e) {
      console.error('useCategoryCreate.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setDisabled(false);
    }
  };

  const cancel = () => {
    goToEntityPage(Routes.Finance, {
      params: {
        entity,
      },
      screen: Routes.FinanceExtracts,
    });
  };

  const formatList = () => {
    const list = clients?.map((c: Client) => ({
      label: c.name,
      value: c.id,
      metadata: c.virtualCard?.balance,
    }));
    const listSorted = list.sort((a: any, b: any) => a.label.localeCompare(b.label));

    setClientsSearchList(listSorted);
  };

  useEffect(() => {
    if (clients?.length && !loading) {
      formatList();
    }
  }, [clients]);


  return {
    adminData,
    entityData,
    clientOf,
    clientTo,
    value,
    disabled,
    clientsSearchList,
    clientIdOf,
    clientIdTo,
    clientOfVc,
    clientToVc,
    hasAPIError,
    APIErrorMessage,
    setClientIdOf,
    setClientIdTo,
    setClientOfVc,
    setClientToVc,
    setValue,
    setClientOf,
    setClientTo,
    recharge,
    cancel,
  };
};
