import { fonts } from '../consts';

export const typography = {
  h1: {
    fontFamily: fonts.Regular,
    fontSize: 34,
    lineHeight: 38,
  },
  h2: {
    fontFamily: fonts.Regular,
    fontSize: 24,
    lineHeight: 28,
  },
  h3: {
    fontFamily: fonts.Regular,
    fontSize: 22,
    lineHeight: 26,
  },
  p1: {
    fontFamily: fonts.Regular,
    fontSize: 18,
    lineHeight: 22,
  },
  p2: {
    fontFamily: fonts.Regular,
    fontSize: 16,
    lineHeight: 20,
  },
  p3: {
    fontFamily: fonts.Regular,
    fontSize: 14,
    lineHeight: 18,
  },
  p4: {
    fontFamily: fonts.Regular,
    fontSize: 12,
    lineHeight: 16,
  },
};
