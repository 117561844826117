import { Dimensions, StyleSheet } from 'react-native';

import { colors } from '../../consts';
import { typography } from '../fonts';

const { width, height } = Dimensions.get('window');

export const LoginRegisterStyle = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imageContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
    height,
    width: width * 0.5,
  },
  image: {
    width: 100,
    height: 100,
  },
  formWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height,
    width: width * 0.5,
    padding: 25,
  },
  formContainer: {
    maxWidth: 600,
    minWidth: 400,
    alignSelf: 'center',
  },
  title: {
    ...typography.h1,
    marginBottom: 30,
    fontWeight: '700',
  },
  inputWrapper: {
    marginBottom: 30,
  },
  verificationCodeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  verificationCode: {
    ...typography.p3,
    fontWeight: '700',
    marginLeft: 25,
  },

  buttonsContainer: {
    width: 170,
  },

  buttonsWarpper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  steps: {
    color: colors.register.step,
    ...typography.p1,
    fontWeight: '700',
  },
});
