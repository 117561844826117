import { StyleSheet } from 'react-native';
import { colors } from '../../consts';

export const styles = StyleSheet.create({
  container: {
    width: 178,
    height: 178,
    borderRadius: 24,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.uploadComponent.bg,
  },
  plusImg: {
    width: 56,
    height: 56,
    borderRadius: 56 / 2,
    backgroundColor: colors.uploadComponent.plusBg,
    padding: 20,
  },
  img: {
    width: 178,
    height: 178,
    borderRadius: 24,
  },
});
