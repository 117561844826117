import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import {
  useGetEntity,
} from '@/api/entities';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import {
  genEmailCode,
} from '@/utils/strUtils';
import { Routes } from '@/navigation/routes';

import { invitationIsActive } from '@/types/invitationType';
import { useCreateInvitationGeneral, useGetInvitationGeneral, useUpdateInvitationGeneral } from '@/api/invitationGeneral/hooks';
import i18n from '@/internationalization';

export const useInvitationsGeneralCreate = (entity: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: InvitationGeneral } = useGetInvitationGeneral(entity, !!entity);

  const [authCode, setAuthCode] = useState('');
  const [isupdate, setIsupdate] = useState(false);
  const [isActiveInactive, setIsActiveInactive,
  ] = useState<invitationIsActive>(invitationIsActive.Active);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { goBack, goToEntityPage } = useNavigate();
  const queryClient = useQueryClient();

  const generateAuthCode = () => {
    setAuthCode(genEmailCode());
  };

  const mutatorCreate = useCreateInvitationGeneral();
  const createInvitationGeneral = () => mutatorCreate.mutateAsync({
    code: authCode,
    status: isActiveInactive === 'ativo',
    entityId: entity,
  });

  const mutatorUpdate = useUpdateInvitationGeneral();
  const updateInvitationGeneral = () => mutatorUpdate.mutateAsync({
    code: authCode,
    status: isActiveInactive !== 'inativo',
    entityId: entity,
  });

  const onSubmit = async () => {
    setLoading(true);

    try {
      const result = isupdate ? await updateInvitationGeneral() : await createInvitationGeneral();

      if (result.status !== 201 && result.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        setAPIErrorMessage(i18n.t('errors.400'));
      }

      setLoading(false);

      await queryClient.invalidateQueries('invitationGeneral');
      goToEntityPage(
        Routes.Invitations,
        { params: { entity }, screen: Routes.InvitationsList },
      );
    } catch (e) {
      console.error('useInvitationsCreate.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(i18n.t('errors.500'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (InvitationGeneral?.id) {
      setIsupdate(true);
      setAuthCode(InvitationGeneral.code);
      setIsActiveInactive(InvitationGeneral.status
        === true ? invitationIsActive.Active : invitationIsActive.Inactive);
    }
  }, [InvitationGeneral]);

  return {
    adminData,
    entityData,
    authCode,
    isActiveInactive,
    APIErrorMessage,
    hasAPIError,
    loading,
    setIsActiveInactive,
    goBack,
    onSubmit,
    generateAuthCode,
  };
};
