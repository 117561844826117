import fetch from '../fetch';
import { CreateSector, UpdateSector } from './dto';

export const getSector = (id: string) => fetch(`/segments/id/${id}`).then((result) => result.json());
export const getSectors = (id: string) => fetch(`/segments/entity/${id}`).then((result) => result.json());
export const createSector = (dto: CreateSector) => fetch('/segments', {
  method: 'POST',
  body: JSON.stringify(dto),
});
export const updateSector = async (dto: UpdateSector) => fetch(`/segments/${dto.id}`, {
  method: 'PUT',
  body: JSON.stringify(dto),
});
