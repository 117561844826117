import { useQuery } from 'react-query';
import { getClientTransactions, getExtractTransaction } from './requests';
import { ExtractTransaction } from './dto';

export const useExtractTransaction = (entity: string) => useQuery(['extractTransaction', entity], () => getExtractTransaction(entity));
export const useGetClientTransactions = (
  id: string,
  isEnabled: boolean,
) => useQuery<ExtractTransaction[], any>(
  ['useGetClientTransactions', id],
  () => getClientTransactions(id),
  { enabled: isEnabled },
);
