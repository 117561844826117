import { useQuery } from 'react-query';
import { getClientOrders, getOrderItem } from './requests';
import { Order } from '../extract/dto';

export const useGetOrderItem = (entity: string) => useQuery(['OrderItem', entity], () => getOrderItem(entity));
export const useGetClientOrders = (id: string, isEnabled: boolean) => useQuery<Order[], any>(
  ['useGetClientOrders', id],
  () => getClientOrders(id),
  { enabled: isEnabled },
);
