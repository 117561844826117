import { useEffect, useState } from 'react';
import { formatNumber } from 'react-native-currency-input';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity } from '@/api/entities';
import { useGetClientById, useGetEntityClients, useManualRecharge } from '@/api/client';
import { Client } from '@/types/client';
import { Admin } from '@/types/admin';
import { Routes } from '@/navigation/routes';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import { currencyFormat } from '@/utils/strUtils';
import { formatOptions } from '@/utils/currencyMask';

export const useManualRechargeCreate = (entity: string) => {
  const { goToEntityPage } = useNavigate();

  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);

  const { data: clients, isLoading: loading } = useGetEntityClients(entity, !!entity);

  const [client, setClient] = useState('');
  const [responsible, setResponsible] = useState('');
  const [value, setValue] = useState<number | null>(0);

  const [clientId, setClientId] = useState('');
  const [clientsSearchList, setClientsSearchList] = useState<any[]>([]);
  const [disabled, setDisabled] = useState(false);

  const { data: clientDetails } = useGetClientById(clientId, !!clientId);

  const { mutateAsync: createManualRecharge } = useManualRecharge();

  const recharge = async () => {
    setDisabled(true);
    if (clientId && clientDetails.id && clientId === clientDetails.id) {
      await createManualRecharge({
        amount: currencyFormat(formatNumber(value || 0, formatOptions)),
        isManual: true,
        adminClient: clientDetails.admins[0].id,
        virtualCard: clientDetails.virtualCard.id,
        isRecurrent: false,
        isReimbursement: false,
        paymentMethod: '-1',
      });

      goToEntityPage(Routes.Finance, {
        params: {
          entity,
        },
        screen: Routes.FinanceExtracts,
      });
      window.location.reload();
    }
  };

  const cancel = () => {
    goToEntityPage(Routes.Finance, {
      params: {
        entity,
      },
      screen: Routes.FinanceExtracts,
    });
  };

  const formatList = () => {
    const list = clients?.map((c: Client) => ({
      label: c.name,
      value: c.id,
      metadata: c.admins.map((r: Admin) => r.name).join(', '),
    }));

    const listSorted = list.sort((a: any, b: any) => a.label.localeCompare(b.label));

    setClientsSearchList(listSorted);
  };

  useEffect(() => {
    if (clients?.length && !loading) {
      formatList();
    }
  }, [clients]);

  return {
    adminData,
    entityData,
    client,
    value,
    disabled,
    setValue,
    setClient,
    clientsSearchList,
    clientId,
    setClientId,
    responsible,
    setResponsible,
    recharge,
    cancel,
  };
};
