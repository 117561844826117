import { useEffect, useState } from 'react';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity, useGetEntityInvitations } from '@/api/entities';
import { Invitation } from '@/api/entities/responses';
import { roleTranslator } from '@/utils/strUtils';
import { standardDate } from '@/utils/date';
import { Routes } from '@/navigation/routes';
import useNavigate from '@/navigation/routes/hooks/useNavigate';

export const useInvitationsList = (entity: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: invitations, isLoading } = useGetEntityInvitations(entity, !!entity);

  const [invitationsDataTable, setInvitationsDataTable] = useState<any>([]);

  const { goToEntityPage } = useNavigate();

  const formatDataTableData = () => {
    const list = invitations?.map((invitation: Invitation) => ({
      ...invitation,
      role: roleTranslator(invitation.role),
      createdAt: standardDate(new Date(invitation.createdAt)),
    }));

    setInvitationsDataTable(list);
  };

  const goToEdit = (idInvitation: string) => {
    const index = invitationsDataTable.findIndex((c: Invitation) => c.id === idInvitation);
    if (invitations && invitations[index]) {
      const { id } = invitations[index];
      goToEntityPage(Routes.Invitations, {
        params: {
          entity,
          id,
        },
        screen: Routes.InvitationsCreate,
      });
    }
  };

  useEffect(() => {
    if (invitations?.length && !isLoading) {
      formatDataTableData();
    }
  }, [invitations]);

  return {
    adminData,
    entityData,
    goToEdit,
    invitations: invitationsDataTable,
  };
};
