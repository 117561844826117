import fetch from '../fetch';
import { SignUpDto, VerificationCodeRequest } from './dto';

export const signUpRequest = (data: SignUpDto) => fetch('/signup/admin-entity', {
  method: 'POST',
  body: JSON.stringify(data),
});

export const verificationCodeRequest = (data: VerificationCodeRequest) => fetch('/verification-code', {
  method: 'POST',
  body: JSON.stringify(data),
});
