import React from 'react';
import {
  View, Text,
} from 'react-native';
import { AutoComplete } from 'antd';
import { SearchableInputTypes } from './SearchableInput.types';
import { styles } from './SearchableInput.styles';

export const SearchableInput = ({
  onSelect,
  data,
  placeholder,
  value,
  label,
  onChange,
}: SearchableInputTypes) => (
  <View>
    <Text style={styles.textLabel}>{label}</Text>
    <View style={styles.inputWarpper}>
      <AutoComplete
        size="large"
        bordered={false}
        options={data}
        placeholder={placeholder}
        filterOption={(inputValue, option) => option?.label.toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1}
        onSelect={(val, option) => onSelect && onSelect(option)}
        value={value}
        onChange={(inputValue) => onChange(inputValue)}
      />
    </View>
  </View>
);
