import { CreateProductCategory, UpdateProductCategory } from './dto';
import fetch from '../fetch';

export const createProductCategory = async (dto: CreateProductCategory) => fetch('/products/categories', {
  method: 'POST',
  body: JSON.stringify(dto),
});
export const updateProductCategory = async (dto: UpdateProductCategory) => fetch(`/products/categories/${dto.id}`, {
  method: 'PUT',
  body: JSON.stringify(dto),
});
export const getProductCategories = (entity: string) => fetch(`/products/categories/${entity}`).then((result) => result.json());
export const getProductCategory = (id: string) => fetch(`/products/category/id/${id}`).then((result) => result.json());
