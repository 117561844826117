import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { subheaderStyles } from './Subheader.styles';
import { SubheaderTypes } from './Subheader.types';
import { subheaderList } from './Subheader.consts';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { colorSchema } from '@/consts/colors';

export const Subheader = ({
  entityName, entityId, activeScreen, role,
}: SubheaderTypes) => {
  
  const { goToEntityPage } = useNavigate();
  const subheaderListFilter = role === 'manager' ? subheaderList.filter((
    item,
  ) => item.title !== 'Convites'
  && item.title !== 'Administradores') : subheaderList;

  return (
    <View style={subheaderStyles.container}>
      <Text style={subheaderStyles.menuItemText}>{entityName}</Text>
      <View style={subheaderStyles.menuWrapper}>
       { role && (
subheaderListFilter.map(({ page, screen, title }) => {
          const color =  screen === activeScreen ? colorSchema.Gray5 : colorSchema.PureWhite;
          return (
            <TouchableOpacity
              key={page}
              activeOpacity={0.7}
              onPress={() => {
                  goToEntityPage(page, { params: { entity: entityId }, screen });
              }}
            >
              <Text style={[subheaderStyles.menuItemText, { color }]}>{title}</Text>
            </TouchableOpacity>
          );
        })
       )

       } 
      </View>
    </View>
  );
};

export default Subheader;
