import { Payment } from './payment';

export type Entity = {
  id?: string;
  name: string;
  address: string;
  addressLine2: string;
  addressNumber: string;
  city: string;
  state: string;
  phone: string;
  zipCode: string;
  country: string;
  enableAddButton?: boolean;
  logo: string;
  systemMinProductQuantity: number;
  systemMinRecurrentPrice: number;
  payments: Payment[];
}

export const emptyEntity: Entity = {
  id: '',
  name: '',
  address: '',
  city: '',
  addressLine2: '',
  country: '',
  phone: '',
  state: '',
  zipCode: '',
  addressNumber: '',
  logo: '',
  systemMinRecurrentPrice: 0,
  systemMinProductQuantity: 0,
  payments: [],
};
