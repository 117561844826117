import { DatatableHeader } from '../../components/datatable';
import { ColumnComponents } from '../../components/datatable/header/datatableHeader.types';

export const categoryListHeader: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'isActive',
    label: 'Status',
    width: '15%',
    renderComponent: ColumnComponents.Switch,
  },
  {
    isOrderResultEnabled: false,
    key: 'name',
    label: 'Nome',
    width: '60%',
  },
  {
    isOrderResultEnabled: false,
    key: 'picture',
    label: 'Foto',
    width: '19%',
    renderComponent: ColumnComponents.Image,
  },
  {
    isOrderResultEnabled: false,
    key: 'manage',
    label: 'Gerenciar',
    width: '6%',
    renderComponent: ColumnComponents.Edit,
  },
];
