/* eslint-disable no-useless-escape */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export default (text: string, { removeSpaces = true }) => {
  const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (
    text
      .toString()
      .toLowerCase()
      // Replace spaces with -
      .replace(/\s+/g, removeSpaces ? '-' : ' ')
      // Replace special chars
      .replace(p, (c) => b.charAt(a.indexOf(c)))
      // Replace & with 'and'
      .replace(/&/g, removeSpaces ? '-and-' : ' and ')
      // Remove all non-word chars
      .replace(/[^\w\-]+/g, '')
      // Replace multiple - with single -
      .replace(/\-\-+/g, '-')
      // Trim - from start of text
      .replace(/^-+/, '')
      // Trim - from end of text
      .replace(/-+$/, '')
  );
};
