import { ResponsibleRoles } from '@/types/responsibleRoles';
import locale from '../../internationalization';

export const ResponsibleTypeSelect = [
  {
    label: locale.t('responsibleCreate.select.responsible'),
    value: ResponsibleRoles.AdminClient,
  },
  {
    label: locale.t('responsibleCreate.select.staff'),
    value: ResponsibleRoles.Staff,
  },
];