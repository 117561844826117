import { NavigationProp, useNavigation } from '@react-navigation/native';
import { CategoryRouteParams, SelectedEntityParams } from '@/navigation/routes/RoutesParams';
import { Routes } from '@/navigation/routes';

export const useNavigateToCreateCategory = () => {
  const navigation = useNavigation<NavigationProp<CategoryRouteParams, Routes.CategoryCreate>>();

  const goToCreateCategory = (params: SelectedEntityParams) => {
    navigation.navigate(Routes.CategoryCreate, params);
  };

  return { goToCreateCategory };
};
