import { DatatableHeader } from '../../components/datatable';

export const headers: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'plan',
    label: 'Plano',
  },
  {
    isOrderResultEnabled: false,
    key: 'entity',
    label: 'Entidade',
  },
  {
    isOrderResultEnabled: false,
    key: 'createdAt',
    label: 'Início',
  },
  {
    isOrderResultEnabled: false,
    key: 'renewAt',
    label: 'Renovação',
  },
  {
    isOrderResultEnabled: false,
    key: 'value',
    label: 'Valor',
  },
  {
    isOrderResultEnabled: false,
    key: 'manage',
    label: 'Gerenciar',
  },
];
