import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId, useUpdateAdmin } from '@/api/admin';
import {
  addMask, emailIsValid, phoneNumberRemoveDDI, phoNumberRemoveMask,
} from '@/utils/strUtils';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import locale from '../../internationalization';
import { useSendVerificationCode } from '@/common/hooks/useSendVerificationCode';

export const VERIFICATION_CODE_TIME = 45;

export const useProfile = () => {
  const { data: adminId } = useGetAdminId();
  const { data: admin } = useGetAdmin(adminId, !!adminId);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [code, setCode] = useState('');

  const [shouldValidatePhone, setShouldValidatePhone] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const [loading, setLoading] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);

  const [APISuccess, setAPISuccess] = useState(true);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');

  const queryClient = useQueryClient();
  const mutatorUpdate = useUpdateAdmin();

  const { goBack } = useNavigate();

  const {
    countDown,
    isDisabledByCuntDown,
    verificationCodeResponse,
  } = useSendVerificationCode(phone);

  const emailChange = (e: string) => {
    setEmail(e);
    setEmailError(emailIsValid(e));
  };

  const cancel = () => {
    goBack();
  };

  const onChangePhone = (val: string) => {
    setPhone(val);
    if (`+55${phoNumberRemoveMask(val)}` !== admin.phone && val.length === 15) {
      setShouldValidatePhone(true);
    } else {
      setShouldValidatePhone(false);
    }
  };

  const onChangeCode = (val: string) => {
    setCode(val);
    if (val.trim() !== verificationCodeResponse.current?.code
      || new Date(verificationCodeResponse.current?.dueAt).getTime() < new Date().getTime()
    ) {
      setIsPhoneNumberValid(false);
      setShouldValidatePhone(true);
    } else {
      setIsPhoneNumberValid(true);
      setShouldValidatePhone(false);
    }
  };

  const sendVerificationCode = async () => {
    countDown.start();
  };

  const update = async () => {
    try {
      setLoading(true);
      const response = await mutatorUpdate.mutateAsync({
        id: admin?.id,
        name,
        email,
        password,
        adminType: admin?.adminType.type,
        phone: `+55${phoNumberRemoveMask(phone)}`,
        user: password ? admin?.user.id : '',
      });

      const data = await response.json();

      if (response.status !== 201 && response.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        const err = data.code ? locale.t(`errors.${data.code}`) : locale.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }

      await queryClient.invalidateQueries(['admin', admin?.id]);

      setLoading(false);
      setHasAPIError(false);
      setAPISuccess(true);
      setApiSuccessMessage(locale.t('success.200'));
    } catch (e) {
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (admin) {
      const phoneWithNoDDI = phoneNumberRemoveDDI(admin.phone);
      const phoneMask = addMask(phoneWithNoDDI, '(00) 00000-0000');
      setName(admin.name);
      setEmail(admin.email);
      setPhone(phoneMask);
    }
  }, [admin]);

  return {
    admin,
    name,
    email,
    phone,
    code,
    emailError,
    loading,
    hasAPIError,
    APIErrorMessage,
    password,
    confirmPassword,
    APISuccess,
    apiSuccessMessage,
    shouldValidatePhone,
    countDown,
    isDisabledByCuntDown,
    isPhoneNumberValid,
    sendVerificationCode,
    cancel,
    update,
    setPassword,
    setConfirmPassword,
    setName,
    emailChange,
    onChangePhone,
    onChangeCode,
  };
};
