import { useEffect, useState } from 'react';
import { formatNumber } from 'react-native-currency-input';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity } from '@/api/entities';
import { useGetEntityClients, useRemoveBalance } from '@/api/client';
import { Client } from '@/types/client';
import locale from '@/internationalization';
import { Routes } from '@/navigation/routes';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import { currencyFormat } from '@/utils/strUtils';
import { formatOptions } from '@/utils/currencyMask';

export const useRemoveBalanceCreatePage = (entity: string) => {
  const { goToEntityPage } = useNavigate();

  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);

  const { data: clients, isLoading: loading } = useGetEntityClients(
    entity,
    !!entity,
  );

  const [client, setClient] = useState('');
  const [clientId, setClientId] = useState('');
  const [balance, setBalance] = useState('');
  const [amount, setAmount] = useState<number | null>(0);
  const [hasAPIError, setHasAPIError] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');

  const [clientsSearchList, setClientsSearchList] = useState<any[]>([]);
  const [disabled, setDisabled] = useState(false);

  const mutation = useRemoveBalance();

  const parseCurrency = (val: string) => {
    const parsedValue = val
      .replace('R$', '')
      .replace(/\s/g, '')
      .replace(',', '.');
    return parseFloat(parsedValue);
  };

  const remove = async () => {
    setDisabled(true);
    setHasAPIError(false);
    setAPIErrorMessage('');
    try {
      if (clientId) {
        const clientBalance = parseCurrency(balance);
        if (amount && amount > clientBalance) {
          setDisabled(false);
          setHasAPIError(true);
          const err = `${locale.t(`removeBalanceCreate.error`)}`;
          setAPIErrorMessage(err);
          return;
        }

        const result = await mutation.mutateAsync({
          id: clientId,
          amount: currencyFormat(formatNumber(amount || 0, formatOptions)),
        });

        if (result.status !== 200) {
          setDisabled(false);
          setHasAPIError(true);
          const err = locale.t('errors.400');
          setAPIErrorMessage(err);
          return;
        }

        goToEntityPage(Routes.Finance, {
          params: {
            entity,
          },
          screen: Routes.FinanceExtracts,
        });
        window.location.reload();
      }
    } catch (e) {
      console.error('useCategoryCreate.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setDisabled(false);
    }
  };

  const cancel = () => {
    goToEntityPage(Routes.Finance, {
      params: {
        entity,
      },
      screen: Routes.FinanceExtracts,
    });
  };

  const formatList = () => {
    const list = clients?.map((c: Client) => ({
      label: c.name,
      value: c.id,
      metadata: c.virtualCard?.balance,
    }));
    const listSorted = list.sort((a: any, b: any) =>
      a.label.localeCompare(b.label),
    );

    setClientsSearchList(listSorted);
  };

  useEffect(() => {
    if (clients?.length && !loading) {
      formatList();
    }
  }, [clients]);

  return {
    adminData,
    entityData,
    disabled,
    clientsSearchList,
    hasAPIError,
    APIErrorMessage,
    client,
    balance,
    amount,
    setAmount,
    setBalance,
    setClient,
    setClientId,
    remove,
    cancel,
  };
};
