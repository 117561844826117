import { SelectedEntityParams } from '@/navigation/routes/RoutesParams';
import { invitationIsActive } from '@/types/invitationType';

export type InvitationsCreateGeneralTypes = {
  route: SelectedEntityParams;
};

export const codeActiveInactive = [
  {
    label: 'Ativo',
    value: invitationIsActive.Active,
  },
  {
    label: 'Inativo',
    value: invitationIsActive.Inactive,
  },
];
