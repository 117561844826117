import { DatatableHeader } from '@/components/datatable';
import { ColumnComponents } from '@/components/datatable/header/datatableHeader.types';

export const sectorPageHeaders: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'isActive',
    label: 'Status',
    width: '30%',
    renderComponent: ColumnComponents.Switch,
  },
  {
    isOrderResultEnabled: false,
    key: 'name',
    label: 'Nome',
    width: '60%',
  },
  {
    isOrderResultEnabled: false,
    key: 'manage',
    label: 'Gerenciar',
    width: '6%',
    renderComponent: ColumnComponents.Edit,
  },
];
