import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { Header } from '@/components/header';
import { Subheader } from '@/components/subheader';
import { Routes } from '@/navigation/routes';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import { InvitationsTabs } from '@/components/invitationstabs';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { PageStyles } from '@/styles/common/PageStyles';
import { colors, Images } from '@/consts';
import { Styles } from '@/pages/invitationscreategeneral/invitationsCreateGeneral.styles';
// import { SearchableInput } from '@/components/searchableinput/SearchableInput';
// import { ErrorHandler } from '../../components/errorhandler';
import { InvitationsCreateGeneralTypes, codeActiveInactive } from './invitationsCreateGeneral.types';
import { useInvitationsGeneralCreate } from './useInvitationsCreateGeneral';
import { colorSchema } from '@/consts/colors';
import Select from '@/components/select/Select';
import { ErrorHandler } from '@/components/errorhandler';

const InvitationsCreateGeneral = ({
  route: {
    params: { entity },
  },
}: InvitationsCreateGeneralTypes) => {
  const {
    adminData, entityData, authCode, isActiveInactive, hasAPIError, APIErrorMessage,
    loading,
    goBack, onSubmit, generateAuthCode, setIsActiveInactive,
  } = useInvitationsGeneralCreate(entity);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.InvitationsList}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('invitations')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <InvitationsTabs list={false} createIndividual={false} createGeneral entityId={entity} />
          <View style={PaperColumns.tableContainerForm}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.columnForm}>
                  <View style={PageStyles.imageWrapper}>
                    <img
                      src={Images.InvitationIcon}
                      alt={locale.t('imagesAlt.new')}
                    />
                  </View>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('new_invitation_general')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('new_invitation_content_general')}
                  </Text>
                </View>
                <View style={PaperColumns.columnFormRight}>
                  <View style={PageStyles.subtitleHeader}>
                    <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                      {locale.t('invitation_info')}
                    </Text>
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          onPress={goBack}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('save')}
                          onPress={onSubmit}
                          disabled={
                             authCode.trim() === ''
                            || loading
                          }
                          height={42}
                          fontSize={14}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={Styles.inputContainer}>
                    <Text style={Styles.content}>
                      {locale.t('invitations_general_subtitle')}
                    </Text>
                    {hasAPIError && (
                    <ErrorHandler message={APIErrorMessage} />
                    )}
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('invitations_general_label_input')}
                          placeholder={locale.t('invitations_general_placeholder')}
                          value={authCode}
                          height={42}
                          borderRadius={14}
                          mask="000000"
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <View style={Styles.SelectContainer}>
                          <Select
                            onValueChange={(value: any) => setIsActiveInactive(value)}
                            selectedValue={isActiveInactive}
                            values={codeActiveInactive}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                    <View style={Styles.buttonWrapper}>
                      <MFullWidthButton
                        label={locale.t('generate_new_code')}
                        onPress={generateAuthCode}
                        height={36}
                        bgColor={colorSchema.Gray2}
                        labelColor={colorSchema.PureBlack}
                        fontSize={14}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default InvitationsCreateGeneral;
