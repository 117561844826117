import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '../../api/admin';
import { useGetEntity } from '../../api/entities';
import { useGetEntityClients, useUpdateClient } from '../../api/client';
import { Client } from '../../types/client';
import locale from '../../internationalization';
import { Routes } from '../../navigation/routes';
import { useNavigate } from '../../navigation/routes/hooks/useNavigate';
import { removeMenuPassDomainFromEmailCode } from '../../utils/strUtils';
import { standardDateHrs } from '@/utils/date';

export const useClientProfiles = (entity: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: clients, isLoading: loading } = useGetEntityClients(
    entity,
    !!entity,
  );

  const { goToEntityPage } = useNavigate();

  const [clientsList, setClientsList] = useState<any[]>([]);

  const queryClient = useQueryClient();
  const mutator = useUpdateClient();

  const formatData = (list: Client[]) => {
    const listFormatted = list.map((client: Client) => ({
      id: client.id,
      isActive: {
        label: client.user.isActive ? locale.t('active') : locale.t('inactive'),
        value: client.user.isActive,
      },
      name: client.name,
      responsible: client.admins.map(a => a.name),
      username: removeMenuPassDomainFromEmailCode(client.user.username),
      password: client.user.password,
      picture: client.picture,
      createdAt: standardDateHrs(new Date(client.createdAt)),
      manage: '',
    }));

    listFormatted.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    setClientsList(listFormatted);
  };

  const updateStatus = async (val: boolean, idClientProfile: string) => {
    const indexClientIndex = clientsList.findIndex(
      c => c.id === idClientProfile,
    );

    const clientId = clientsList[indexClientIndex].id;

    const index = clients.findIndex((c: any) => c.id === clientId);

    if (clients[index]) {
      const { id, name, email, identityNumber, admins, user } = clients[
        index
      ] as Client;

      const preparedData = {
        id,
        name,
        email,
        entity,
        identityNumber,
        username: email,
        admins: admins?.map((admin: any) => admin.id),
        picture: '',
        user: user.id,
        isActive: val,
      };

      await mutator.mutateAsync(preparedData);
      await queryClient.invalidateQueries('entityClients');
    }
  };

  const goToEdit = (idClient: string) => {
    const index = clientsList.findIndex(c => c.id === idClient);
    if (clientsList[index]) {
      const { id } = clientsList[index];
      goToEntityPage(Routes.Client, {
        params: {
          entity,
          id,
        },
        screen: Routes.ClientCreate,
      });
    }
  };

  useEffect(() => {
    if (clients?.length && !loading) {
      formatData(clients);
    }
  }, [clients]);

  return {
    adminData,
    entityData,
    loading,
    clientsList,
    goToEdit,
    updateStatus,
  };
};
