import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import locale from '@/internationalization';

import { Routes } from '../../navigation/routes';
import { useSectorCreate } from './SectorCreatePage.functions';
import { SectorCreatePageTypes, sectorActiveInactive } from './SectorCreatePage.types';
import { Container } from '@/components/container';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import SectorTabs from '@/components/sectortabs/SectorTabs';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { PageStyles } from '@/styles/common/PageStyles';
import { colors } from '@/consts';
import { Styles } from './SectorCreatePage.styles';
import Select from '@/components/select/Select';
import { ErrorHandler } from '@/components/errorhandler';

const SectorCreatePage = ({
  route: { params: { entity, id } },
}: SectorCreatePageTypes) => {
  const {
    entityData,
    adminData,
    goBack,
    onSubmit,
    hasAPIError,
    APIErrorMessage,
    name,
    setIsActiveInactive,
    isActiveInactive,
    setName,
    loading,
  } = useSectorCreate(entity, id || '');
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.sectorCreate}
        role={adminData?.adminType?.type}
      />

      <Container>
        <EntitiesPageTitle
          title={locale.t('sectorCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <SectorTabs entityId={entity} list={false} create />
          <View style={PaperColumns.tableContainer}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('sectorCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('sectorCreate.content')}
                  </Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                      {locale.t('sectorCreate.basicInfo')}
                    </Text>
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          onPress={goBack}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('save')}
                          onPress={onSubmit}
                          disabled={
                             name.trim() === ''
                            || loading
                          }
                          height={42}
                          fontSize={14}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={Styles.inputContainer}>
                    {hasAPIError && (
                    <ErrorHandler message={APIErrorMessage} />
                    )}
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('name')}
                          placeholder={locale.t('sectorCreate.namePlaceholder')}
                          value={name}
                          onChangeText={setName}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <View style={Styles.SelectContainer}>
                          <Select
                            onValueChange={(value: any) => setIsActiveInactive(value)}
                            selectedValue={isActiveInactive}
                            values={sectorActiveInactive}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};
export default SectorCreatePage;
