import { useEffect, useState } from 'react';
import IMask from 'imask';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId, useUpdateAdminPaymentAddress } from '@/api/admin';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import locale from '@/internationalization';
import { useSearchZipCode } from '@/api/global';
import { Routes } from '@/navigation/routes';
import { MaskCpf, useNationalIdMask } from '@/common/hooks/useNationalIdMask';
import { CNPJValidation, CPFValidation } from '@/utils/strUtils';
import { Flows } from '@/navigation/routes/enums/Flows';

export const usePaymentData = (flow: 'onBoarding' | undefined) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);

  const [name, setName] = useState('');
  const [addressHolderName, setAddressHolderName] = useState('');
  const [nationalId, setNationalId] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');

  const [nationalIdMaskType, setNationalIdMaskType] = useState<'cpf' | 'cnpj'>('cnpj');

  const {
    onChangeNationalIdRadio, nationalIdRadio, nationalIdMask,
  } = useNationalIdMask(nationalIdMaskType);

  const [nationalIdHasError, setNationalIdHasError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);

  const [APISuccess, setAPISuccess] = useState(true);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');

  const [forwardBtnText, setForwardBtnText] = useState('');

  const queryClient = useQueryClient();

  const { goBack, goToPayment } = useNavigate();

  const maskNationalIdCreator = IMask.createMask({ mask: nationalIdMask });

  const onChangeNationalId = (value: string) => {
    const n = maskNationalIdCreator.resolve(value);
    setNationalId(n);

    const isValid = nationalIdMask === MaskCpf ? CPFValidation(n) : CNPJValidation(n);

    if (!isValid) {
      setNationalIdHasError(true);
    } else {
      setNationalIdHasError(false);
    }
  };

  const maskZipcode = IMask.createMask({ mask: '00000-000' });

  const onChangeZipCode = (value: string) => {
    const z = maskZipcode.resolve(value);
    setZipCode(z);
  };

  const searchZipCodeMutation = useSearchZipCode();

  const onSearchZipCode = async () => {
    if (zipCode.length === 9) {
      const result = await searchZipCodeMutation.mutateAsync({
        zipCode,
      });

      const data = await result.json();

      setState(data.state || '');
      setCity(data.city || '');
      setStreet(data.address || '');
      setComplement(data.complement || '');
      setNumber(data.addressNumber || '');
      setZipCode(data.zipCode || '');
    }
  };

  const mutator = useUpdateAdminPaymentAddress();

  const submit = async () => {
    if (
      addressHolderName.trim() !== ''
      && nationalId.trim() !== ''
      && zipCode.trim() !== ''
      && state.trim() !== ''
      && city.trim() !== ''
      && street.trim() !== ''
      && number.trim() !== ''
    ) {
      try {
        setLoading(true);

        const result = await mutator.mutateAsync({
          id: adminData.id,
          city,
          number,
          complement,
          addressHolderName,
          state,
          street,
          zipCode,
          nationalId,
        });

        if (result.status !== 200) {
          setLoading(false);

          setHasAPIError(true);
          setAPIErrorMessage(locale.t('errors.400'));
          return;
        }

        await queryClient.invalidateQueries(['admin', adminData.id]);

        setLoading(false);
        setHasAPIError(false);
        setAPISuccess(true);
        setApiSuccessMessage(locale.t('success.200'));

        if (flow && flow === Flows.onBoarding) {
          goToPayment({
            params: {
              flow,
            },
            screen: Routes.PaymentAddCard,
          });
        }
      } catch (e) {
        setLoading(false);
        console.error('usePaymentData.submit', e);
        setHasAPIError(true);
        setAPIErrorMessage(locale.t('errors.500'));
      }
    }
  };

  useEffect(() => {
    if (flow && flow === 'onBoarding') {
      setForwardBtnText(locale.t('paymentData.next'));
    } else {
      setForwardBtnText(locale.t('paymentData.save'));
    }
  }, []);

  useEffect(() => {
    if (adminData?.id) {
      setName(adminData?.name);
      setAddressHolderName(adminData?.addressHolderName || '');
      setNationalId(adminData?.nationalId || '');
      setZipCode(adminData?.zipCode || '');
      setState(adminData?.state || '');
      setStreet(adminData?.street || '');
      setCity(adminData?.city || '');
      setNumber(adminData?.number || '');
      setComplement(adminData?.complement || '');

      setNationalIdMaskType(
        adminData?.nationalId && adminData?.nationalId.length === 14 ? 'cpf' : 'cnpj',
      );
    }
  }, [adminData]);

  return {
    name,
    addressHolderName,
    nationalId,
    zipCode,
    state,
    street,
    city,
    number,
    complement,
    loading,
    adminData,
    APIErrorMessage,
    hasAPIError,
    apiSuccessMessage,
    APISuccess,
    forwardBtnText,
    nationalIdRadio,
    nationalIdMask,
    nationalIdHasError,
    onChangeNationalId,
    onChangeZipCode,
    goBack,
    submit,
    onSearchZipCode,
    setAddressHolderName,
    setState,
    setStreet,
    setCity,
    setNumber,
    setComplement,
    onChangeNationalIdRadio,
  };
};
