import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '../routes';
import { SystemRouteParams } from '../routes/RoutesParams';
import { SystemConfigPage } from '@/pages/systemconfig';
import { SystemPeriodCreatePage } from '@/pages/systemperiodcreate';
import { SystemPeriodListPage } from '@/pages/systemperiodlist';

const Stack = createStackNavigator<SystemRouteParams>();

export const System = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.SystemConfig}
      component={SystemConfigPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.periodCreate}
      component={SystemPeriodCreatePage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.periodList}
      component={SystemPeriodListPage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default System;
