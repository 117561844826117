import React from 'react';

import { View } from 'react-native';
import { Header } from '@/components/header';
import { Subheader } from '@/components/subheader';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Datatable } from '@/components/datatable';
import { clientProfilesPageHeaders } from './ClientProfilesPage.consts';
import { ClientTabs } from '@/components/clienttabs';
import { ClientProfilesPageTypes } from './ClientProfilesPage.types';
import { useClientProfiles } from './ClientProfilesPage.functions';
import { Routes } from '@/navigation/routes';

export const ClientProfilesPage = ({
  route: { params: { entity } },
}: ClientProfilesPageTypes) => {
  const {
    adminData,
    entityData,
    loading,
    clientsList,
    goToEdit,
    updateStatus,
  } = useClientProfiles(entity);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.ClientProfiles}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('client.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <ClientTabs entityId={entity} profiles create={false} />
          <View style={[PaperColumns.tableContainer]}>
            <Datatable
              headers={clientProfilesPageHeaders}
              data={clientsList}
              loading={loading}
              onEditPress={goToEdit}
              onSwitchChange={updateStatus}
              searchFields={['name']}
            />
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default ClientProfilesPage;
