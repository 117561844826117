import { useMutation, useQuery } from 'react-query';
import {
  cancelSubscription,
  createNewEntity,
  getAdminEntities,
  getEntitiesAll,
  getEntity,
  getEntityAdmins,
  getEntityBank,
  getEntityInfo,
  getEntityInvitations,
  getInvitation,
  updateEntity,
  updateSystemEntity,
} from './requests';
import { Invitation } from '@/api/entities/responses';
import { createInvitation, updateInvitation } from '@/api/entities/requests';

export const useGetAdminEntities = (admin: string, isEnabled: boolean) => useQuery(['adminEntities', admin], () => getAdminEntities(admin), {
  enabled: isEnabled,
});
export const useGetEntityAdmins = (entity: string, isEnabled: boolean) => useQuery(['entityAdmins', entity], () => getEntityAdmins(entity), {
  enabled: isEnabled,
});
export const useGetEntity = (entity: string, isEnabled: boolean) => useQuery(['entity', entity], () => getEntity(entity), { enabled: isEnabled });
export const useGetEntitiesAll = (entity: string, isEnabled: boolean) => useQuery(['entities', entity], () => getEntitiesAll(entity), { enabled: isEnabled });
export const useGetEntityInfo = (identity: string, isEnabled: boolean) => useQuery(['entityInfo', identity], () => getEntityInfo(identity), { enabled: isEnabled });
export const useGetEntityBank = (identity: string, isEnabled: boolean) => useQuery(['entityBank', identity], () => getEntityBank(identity), { enabled: isEnabled });
export const useCreateNewEntity = () => useMutation(createNewEntity);
export const useUpdateEntity = () => useMutation(updateEntity);
export const useUpdateSystemEntity = () => useMutation(updateSystemEntity);
export const useCancelSubscription = () => useMutation(cancelSubscription);
export const useGetEntityInvitations = (entity: string, isEnabled: boolean) => useQuery<Invitation[], Error>(['entityInvitations', entity], () => getEntityInvitations(entity), { enabled: isEnabled });
export const useCreateInvitation = () => useMutation(createInvitation);
export const useUpdateInvitation = () => useMutation(updateInvitation);
export const useGetInvitation = (id: string, isEnabled: boolean) => useQuery<Invitation, Error>(
  ['useGetInvitation', id],
  () => getInvitation(id),
  { enabled: isEnabled },
);
