import { DatatableHeader } from '@/components/datatable';
import { ColumnComponents } from '@/components/datatable/header/datatableHeader.types';

export const invitationsListPageHeaders: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'name',
    label: 'Nome',
    width: '25%',
  },
  {
    isOrderResultEnabled: false,
    key: 'code',
    label: 'Código',
    width: '5%',
  },
  {
    isOrderResultEnabled: false,
    key: 'role',
    label: 'Tipo',
    width: '10%',
  },
  {
    isOrderResultEnabled: false,
    key: 'email',
    label: 'E-mail',
    width: '20%',
  },
  {
    isOrderResultEnabled: false,
    key: 'phone',
    label: 'Celular',
    width: '14%',
  },
  {
    isOrderResultEnabled: false,
    key: 'createdAt',
    label: 'Data',
    width: '10%',
  },
  {
    isOrderResultEnabled: false,
    key: 'isActive',
    label: 'Status',
    width: '10%',
  },
  {
    isOrderResultEnabled: false,
    key: 'manage',
    label: 'Gerenciar',
    width: '6%',
    renderComponent: ColumnComponents.Edit,
  },
];
