import { StyleSheet } from 'react-native';
import { colors } from '../../consts';
import { typography } from '../../styles';

export const Styles = StyleSheet.create({
  columns: {
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  filledCardBg: {
    backgroundColor: colors.home.bg,
  },
  addBtn: {
    width: 56,
    height: 56,
    borderRadius: 56 / 2,
    padding: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.home.addBtnBg,
  },
  logoWrapper: {
    width: 140,
    height: 140,
    borderRadius: 140 / 2,
    overflow: 'hidden',
    alignSelf: 'center',
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  actionButton: {
    width: 100,
  },
  name: {
    ...typography.h3,
    fontWeight: '700',
    color: colors.home.name,
    textAlign: 'center',
    alignSelf: 'center',
    marginVertical: 20,
  },
  actionButtonsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});
