import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../consts';
import { typography } from '../../styles';
import { shadow } from '../../styles/common/Shadow';

export const headerStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: dimensions.headerHeight,
    backgroundColor: colors.header.bg,
    paddingHorizontal: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 9999,
  },
  profile: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    marginRight: 10,
  },
  username: {
    ...typography.p3,
    color: colors.header.username,
    fontWeight: '700',
  },
  logo: {
    marginRight: 40,
  },
  menuWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menu: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: 40,
    backgroundColor: colors.header.menuButtonBg,
    paddingHorizontal: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 34,
    borderRadius: 12,
  },
  menuItemText: {
    ...typography.p3,
    color: colors.header.menuButtonText,
    fontWeight: '700',
  },
  submenu: {
    backgroundColor: colors.header.submenuBg,
    position: 'absolute',
    top: 40,
    right: -30,
    width: 200,
    padding: 16,
    borderRadius: 16,
    ...shadow,
  },
  submenuButton: {
    paddingVertical: 16,
  },
  submenuText: {
    ...typography.p3,
    color: colors.header.menuButtonBg,
  },
});
