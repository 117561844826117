import fetch from '../fetch';
import { CreatePaymentMethodDto } from './dto';

export const createPaymentMethod = (dto: CreatePaymentMethodDto) => fetch('/payment/admin/method', {
  method: 'POST',
  body: JSON.stringify(dto),
});
export const deleteAdminPaymentMethod = (id: string) => fetch(`/payment/admin-entity/${id}`, {
  method: 'DELETE',
});
export const getAdminPaymentMethods = (id: string) => fetch(`/payment/admin-entity/${id}`).then((result) => result.json());
