import React from 'react';

import { View } from 'react-native';
import { InvitationsListTypes } from '@/pages/invitationslist/InvitationsList.types';
import { useInvitationsList } from '@/pages/invitationslist/useInvitationsList';
import { Header } from '@/components/header';
import { Subheader } from '@/components/subheader';
import { Routes } from '@/navigation/routes';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import { InvitationsTabs } from '@/components/invitationstabs';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Datatable } from '@/components/datatable';
import { invitationsListPageHeaders } from '@/pages/invitationslist/InvitationsList.consts';

const InvitationsList = ({
  route: {
    params: { entity },
  },
}: InvitationsListTypes) => {
  const {
    adminData, entityData, invitations, goToEdit,
  } = useInvitationsList(entity);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.InvitationsList}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('invitations')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <InvitationsTabs list createIndividual={false} createGeneral={false} entityId={entity} />
          <View style={PaperColumns.tableContainer}>
            <Datatable
              headers={invitationsListPageHeaders}
              data={invitations || []}
              searchFields={[
                'name',
                'email',
                'phone',
                'isActive',
                'role',
                'createdAt',
              ]}
              onEditPress={goToEdit}
            />
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default InvitationsList;
