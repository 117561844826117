import { StyleSheet } from 'react-native';
import { colors, dimensions } from '@/consts';
import { typography } from '@/styles';

export const Styles = StyleSheet.create({
  progressbarContainer: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
  },
  inputContainer: {
    width: '100%',
  },
  content: {
    ...typography.p2,
    color: colors.newEntity.subtitle,
    marginBottom: 15,
  },
  inputsContainerStep3: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
  },
});
