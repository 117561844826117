import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Routes } from '../index';
import { InternalRouteParams, NewEntityParams } from '../RoutesParams';

export const useNavigateEditEntity = () => {
  const navigation = useNavigation<NavigationProp<InternalRouteParams, Routes.NewEntity>>();

  const goToEditEntity = (params: NewEntityParams) => {
    navigation.navigate(Routes.NewEntity, params);
  };

  return { goToEditEntity };
};
