import { DatatableHeader } from '../../components/datatable';
import { ColumnComponents } from '../../components/datatable/header/datatableHeader.types';

export const administrationProfilesPageHeaders: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'isActive',
    label: 'Status',
    width: '15%',
    renderComponent: ColumnComponents.Switch,
  },
  {
    isOrderResultEnabled: false,
    key: 'name',
    label: 'Nome',
    width: '25%',
  },
  {
    isOrderResultEnabled: false,
    key: 'adminType',
    label: 'Atribuição',
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'email',
    label: 'Email',
    width: '29%',
  },
  {
    isOrderResultEnabled: false,
    key: 'authorizationCode',
    label: 'Código',
    width: '9%',
  },
  {
    isOrderResultEnabled: false,
    key: 'manage',
    label: 'Gerenciar',
    width: '6%',
    renderComponent: ColumnComponents.Edit,
  },
];
