import { Dimensions, StyleSheet } from 'react-native';
import { colorSchema } from '@/consts/colors';
import { typography } from '@/styles';
import { shadow } from '@/styles/common/Shadow';

const { width, height } = Dimensions.get('window');

export const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width,
    height,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: colorSchema.PureWhite,
    paddingHorizontal: 25,
    ...shadow,
    width: 343,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 25,
    borderRadius: 18,
  },
  closeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 25,
    marginBottom: 10,
    width: '100%',
  },
  title: {
    ...typography.p1,
    color: colorSchema.PureBlack,
    marginBottom: 25,
    textAlign: 'center',
    fontWeight: '700',
  },
  content: {
    ...typography.p2,
    fontWeight: '400',
    color: colorSchema.PureBlack,
    textAlign: 'center',
    marginBottom: 25,
  },
  btnContainer: {
    width: 200,
  },
});
