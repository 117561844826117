import { Dimensions } from 'react-native';

const {
  height,
  width,
} = Dimensions.get('window');

const headerHeight = 52;
const paddingDiff = 75;
const submenuHeight = 38;
const titleDiff = 49 + 10;
// eslint-disable-next-line max-len
const paperHeightWithSubHeader = height - headerHeight - paddingDiff - titleDiff - submenuHeight - 100;
const paperHeight = height - headerHeight - paddingDiff - titleDiff;
const entityCardHeight = paperHeight / 2 - 20;
const paperWidth = width > 1488 ? 1488 - 50 - 40 : width - 50 - 40;

export const dimensions = {
  height,
  width,
  paperHeightWithSubHeader,
  paperHeight,
  headerHeight,
  submenuHeight,
  entityCardHeight,
  paperWidth,
};
