import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity } from '@/api/entities';
import { useGetAdminClient, useUpdateAdminClient } from '@/api/client';
import locale from '@/internationalization';
import { Responsible } from '@/types/responsible';
import { Routes } from '@/navigation/routes';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { phoNumberRemoveMask } from '@/utils/strUtils';
import { standardDateHrs } from '@/utils/date';

export const useResponsibleProfiles = (entityParam: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entityParam, !!entityParam);
  const { data: adminClients, isLoading: loading } = useGetAdminClient(
    entityParam,
    !!entityParam,
  );

  const { goToEntityPage } = useNavigate();

  const queryClient = useQueryClient();
  const mutator = useUpdateAdminClient();

  const [adminClientsList, setAdminClientsList] = useState<any[]>([]);

  const formatAdminClientList = (list: Responsible[]) => {
    const listFormatted = list.map(admin => ({
      isActive: {
        label: admin.user.isActive ? locale.t('active') : locale.t('inactive'),
        value: admin.user.isActive,
      },
      name: admin.name,
      nationalId: admin.nationalId,
      email: admin.email,
      city: admin.city,
      state: admin.state,
      manage: '',
      id: admin.id,
      createdAt: standardDateHrs(new Date(admin.createdAt)),
    }));

    listFormatted.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    setAdminClientsList(listFormatted);
  };

  const goToEdit = (idAdminClient: string) => {
    const index = adminClients.findIndex(
      (admin: any) => admin.id === idAdminClient,
    );
    const { id } = adminClients[index];
    goToEntityPage(Routes.Responsible, {
      params: {
        entity: entityParam,
        id,
      },
      screen: Routes.ResponsibleCreate,
    });
  };

  // TODO refactor
  const updateStatus = async (val: boolean, idResponsible: string) => {
    const index = adminClientsList.findIndex(c => c.id === idResponsible);
    if (adminClients[index]) {
      const {
        id,
        name,
        email,
        entity,
        state,
        city,
        zipCode,
        nationalId,
        phone,
        address,
        addressLine2,
        addressNumber,
        country,
        user,
        createdAt,
      } = adminClients[index] as Responsible;
      const preparedData = {
        id,
        name,
        email,
        entity,
        state,
        city,
        zipCode,
        nationalId,
        address,
        addressLine2,
        addressNumber,
        country,
        isActive: val,
        createdAt,
        user: user.id,
        phone: `+55${phoNumberRemoveMask(phone)}`,
        username: email,
      };

      await mutator.mutateAsync(preparedData);
      await queryClient.invalidateQueries('adminClients');
    }
  };

  useEffect(() => {
    if (adminClients?.length && !loading) {
      formatAdminClientList(adminClients);
    }
  }, [adminClients]);

  return {
    adminClientsList,
    adminData,
    entityData,
    loading,
    goToEdit,
    updateStatus,
  };
};
