import React, { useState } from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';

const MultiSelectDropdown = ({ label, selectedValues, values, onValueChange }: { label: string, selectedValues: any[], values: any[], onValueChange: (values: any[]) => void }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Alternar visibilidade do dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Função para lidar com a seleção/deseleção de itens
  const handleSelect = (value: any) => {
    if (selectedValues.includes(value)) {
      onValueChange(selectedValues.filter(item => item !== value));
    } else {
      onValueChange([...selectedValues, value]);
    }
  };

  // Verifica se o item está selecionado
  const isSelected = (value: any) => selectedValues.includes(value);

  // Mapeia os IDs selecionados para os rótulos (nomes)
  const selectedLabels = selectedValues
    .map((selectedValue) => values.find((item) => item.value === selectedValue)?.label)
    .filter(Boolean); // Remove undefined caso não encontre o valor

  return (
    <View>
      <Text>{label}</Text>
      <TouchableOpacity onPress={toggleDropdown}>
        <View style={{
          padding: 10,
          borderWidth: 1,
          borderRadius: 8,
          borderColor: '#ccc',
        }}>
          <Text>
            {selectedLabels.length > 0
              ? selectedLabels.join(', ') // Mostra os rótulos selecionados
              : 'Selecione...'}
          </Text>
        </View>
      </TouchableOpacity>

      {isDropdownOpen && (
        <View style={{
          borderWidth: 1,
          borderRadius: 8,
          borderColor: '#ccc',
          marginTop: 5,
          maxHeight: 150, // Limite de altura para o dropdown
        }}>
          <FlatList
            data={values}
            keyExtractor={(item) => item.value}
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => handleSelect(item.value)}>
                <View style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 10,
                }}>
                  <Text>{item.label}</Text>
                  {isSelected(item.value) && <Text>✓</Text>}
                </View>
              </TouchableOpacity>
            )}
          />
        </View>
      )}
    </View>
  );
};

export default MultiSelectDropdown;
