import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import locale from '@/internationalization';
import { colors } from '@/consts';
import { Routes } from '@/navigation/routes';
import { financeTabsStyles } from './FinanceTabs.styles';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { FinanceTabsTypes } from './FinanceTabs.types';

export const FinanceTabs = ({
  entityId,
  manualRecharge,
  rechargeTransfer,
  extract,
  removeBalance,
}: FinanceTabsTypes) => {
  const { goToEntityPage } = useNavigate();
  return (
    <View style={financeTabsStyles.container}>
      <TouchableOpacity
        style={[
          financeTabsStyles.btnContainer,
          {
            borderColor: extract
              ? colors.tabs.selectedBorder
              : colors.tabs.border,
          },
        ]}
        onPress={() =>
          goToEntityPage(Routes.Finance, {
            params: { entity: entityId },
            screen: Routes.FinanceExtracts,
          })
        }>
        <Text
          style={[
            financeTabsStyles.btnText,
            { color: extract ? colors.tabs.selectedText : colors.tabs.text },
          ]}>
          {locale.t('financeTabs.order')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          financeTabsStyles.btnContainer,
          {
            borderColor: manualRecharge
              ? colors.tabs.selectedBorder
              : colors.tabs.border,
          },
        ]}
        onPress={() =>
          goToEntityPage(Routes.Finance, {
            params: { entity: entityId },
            screen: Routes.FinanceManualRecharge,
          })
        }>
        <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <Path
            d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
            fill={manualRecharge ? colors.tabs.selectedText : colors.tabs.text}
          />
        </Svg>
        <Text
          style={[
            financeTabsStyles.btnText,
            {
              marginLeft: 6,
              color: manualRecharge
                ? colors.tabs.selectedText
                : colors.tabs.text,
            },
          ]}>
          {locale.t('financeTabs.manualRecharge')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          financeTabsStyles.btnContainer,
          {
            borderColor: rechargeTransfer
              ? colors.tabs.selectedBorder
              : colors.tabs.border,
          },
        ]}
        onPress={() =>
          goToEntityPage(Routes.Finance, {
            params: { entity: entityId },
            screen: Routes.FinanceRechargeTransfer,
          })
        }>
        <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <Path
            d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
            fill={
              rechargeTransfer ? colors.tabs.selectedText : colors.tabs.text
            }
          />
        </Svg>
        <Text
          style={[
            financeTabsStyles.btnText,
            {
              marginLeft: 6,
              color: rechargeTransfer
                ? colors.tabs.selectedText
                : colors.tabs.text,
            },
          ]}>
          {locale.t('financeTabs.rechargeTransfer')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          financeTabsStyles.btnContainer,
          {
            borderColor: removeBalance
              ? colors.tabs.selectedBorder
              : colors.tabs.border,
          },
        ]}
        onPress={() =>
          goToEntityPage(Routes.Finance, {
            params: { entity: entityId },
            screen: Routes.FinanceRemoveBalance,
          })
        }>
        <Text
          style={[
            financeTabsStyles.btnText,
            {
              marginLeft: 6,
              color: removeBalance
                ? colors.tabs.selectedText
                : colors.tabs.text,
            },
          ]}>
          {locale.t('financeTabs.removeBalance')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default FinanceTabs;
