import { useEffect, useState } from 'react';
import IMask from 'imask';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { cardBrands } from '@/consts/cardBrands';
import { useCreatePaymentMethod, useGetAdminPaymentMethods } from '@/api/payment';
import locale from '@/internationalization';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { Routes } from '@/navigation/routes';
import { MaskCpf, useNationalIdMask } from '@/common/hooks/useNationalIdMask';
import { CNPJValidation, CPFValidation } from '@/utils/strUtils';
import { Flows } from '@/navigation/routes/enums/Flows';

export const usePaymentAddCard = (flow: Flows) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: paymentMethods } = useGetAdminPaymentMethods(adminId, !!adminId);

  const isDefault = true;
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [cardHolderNationalId, setCardHolderNationalId] = useState('');
  const [cardValidation, setCardValidation] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [alreadyHaveCard, setAlreadyHaveCard] = useState(false);
  const [hasAPIError, setHasAPIError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardBrand, setCardBrand] = useState(cardBrands[0].value);

  const [nationalIdHasError, setNationalIdHasError] = useState(false);
  const { onChangeNationalIdRadio, nationalIdRadio, nationalIdMask } = useNationalIdMask();

  const [forwardBtnText, setForwardBtnText] = useState('');

  const cardBrandSelect = cardBrands;

  const { navigate } = useNavigate();

  const queryClient = useQueryClient();
  const mutator = useCreatePaymentMethod();

  const maskCardNumber = IMask.createMask({ mask: '0000 0000 0000 0000' });

  const onChangeCardNumber = (value: string) => {
    const card = maskCardNumber.resolve(value);
    setCardNumber(card);
  };

  const maskCardValidation = IMask.createMask({ mask: '00/00' });

  const onChangeCardValidation = (value: string) => {
    const validation = maskCardValidation.resolve(value);
    setCardValidation(validation);
  };

  const maskSecurityCode = IMask.createMask({ mask: '0000' });

  const onChangeSecurityCode = (value: string) => {
    const sc = maskSecurityCode.resolve(value);
    setSecurityCode(sc);
  };

  const maskCardHolderNationalId = IMask.createMask({ mask: nationalIdMask });

  const onChangeCardHolderNationalId = (value: string) => {
    const nationalId = maskCardHolderNationalId.resolve(value);
    setCardHolderNationalId(nationalId);

    const isValid = nationalIdMask === MaskCpf
      ? CPFValidation(nationalId) : CNPJValidation(nationalId);

    if (!isValid) {
      setNationalIdHasError(true);
    } else {
      setNationalIdHasError(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeCardBrand = (name: string, _: number) => {
    setCardBrand(name);
  };

  const goBackToCards = () => {
    navigate(Routes.PaymentCards);
  };

  const submit = async () => {
    if (
      cardHolder.trim() !== ''
      && cardNumber.trim() !== ''
      && cardBrand.trim() !== ''
      && cardValidation.trim() !== ''
      && securityCode.trim() !== ''
      && cardHolderNationalId.trim() !== ''
    ) {
      try {
        setLoading(true);

        const result = await mutator.mutateAsync({
          cardHolder,
          cardValidation,
          cardBrand,
          isDefault,
          securityCode,
          cardNumber: cardNumber.replace(/ /g, ''),
          adminEntity: adminData.id,
          externalId: 'no integration yet',
        });

        if (result.status !== 201) {
          setLoading(false);

          setHasAPIError(true);
          setAPIErrorMessage(locale.t('errors.400'));
          return;
        }

        await queryClient.invalidateQueries(['paymentMethods', adminData.id]);

        if (flow && flow === 'onBoarding') {
          navigate(Routes.NewEntity);
        } else {
          navigate(Routes.PaymentCards);
        }
      } catch (e) {
        setLoading(false);
        console.error('usePaymentAddCard.submit', e);
        setHasAPIError(true);
        setAPIErrorMessage(locale.t('errors.500'));
      }
    }
  };

  useEffect(() => {
    if (flow && flow === 'onBoarding') {
      setForwardBtnText(locale.t('paymentData.next'));
    } else {
      setForwardBtnText(locale.t('paymentData.save'));
    }
  }, []);

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      setAlreadyHaveCard(true);
    }
  }, [paymentMethods]);

  return {
    adminData,
    cardNumber,
    cardHolder,
    cardBrand,
    cardValidation,
    securityCode,
    cardBrandSelect,
    cardHolderNationalId,
    hasAPIError,
    APIErrorMessage,
    alreadyHaveCard,
    loading,
    forwardBtnText,
    nationalIdRadio,
    nationalIdMask,
    nationalIdHasError,
    onChangeCardNumber,
    onChangeCardValidation,
    onChangeSecurityCode,
    onChangeCardBrand,
    submit,
    onChangeCardHolderNationalId,
    goBackToCards,
    setHasAPIError,
    setCardHolder,
    onChangeNationalIdRadio,
  };
};
