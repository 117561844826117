import React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '@/consts';
import { getCurrentMedia, Media } from '@/utils';

export const EntityCard = (props: any) => {
  const mediaStyle = styles[getCurrentMedia()].container;

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <View style={[baseStyle.container, mediaStyle, props.style]} testID="entity-card">
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </View>
  );
};

const styles: any = {
  [Media.FullHd]: StyleSheet.create({
    container: {
      maxWidth: 300,
      width: '100%',
      height: 300,
      margin: 20,
    },
  }),
  [Media.Inches13]: StyleSheet.create({
    container: {
      maxWidth: 270,
      width: '100%',
      height: 270,
      margin: 20,
    },
  }),
  [Media.FullHdPortrait]: StyleSheet.create({
    container: {
      maxWidth: 200,
      width: '100%',
      height: 200,
      margin: 10,
    },
  }),
  [Media.TabletLandscape]: StyleSheet.create({
    container: {
      maxWidth: 200,
      width: '100%',
      height: 200,
      margin: 10,
    },
  }),
  [Media.TabletPortrait]: StyleSheet.create({
    container: {
      maxWidth: 150,
      width: '100%',
      height: 150,
      margin: 5,
    },
  }),
};

const baseStyle = StyleSheet.create({
  container: {
    backgroundColor: colors.entityCard.bg,
    alignSelf: 'center',
    borderRadius: 24,
    justifyContent: 'center',
  },
});

export default EntityCard;
