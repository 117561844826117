import { StyleSheet } from 'react-native';
import { typography } from '../../styles';

export const systemConfigStyles = StyleSheet.create({
  subtitle: {
    ...typography.p1,
    fontWeight: '700',
    marginBottom: 20,
  },
  content: {
    ...typography.p2,
    marginBottom: 20,
    fontWeight: '400',
  },
});
