import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { typography } from '@/styles';
import { colors } from '@/consts';
import { PageTitleTypes } from '@/components/pagetitle/PageTitle.types';

export const PageTitle = ({ title, component }: PageTitleTypes) => (
  <View style={styles.container}>
    <Text style={styles.title}>{title}</Text>
    {component && component}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  title: {
    ...typography.p1,
    color: colors.pageTitle.title,
    fontWeight: '700',
  },
});

export default PageTitle;
