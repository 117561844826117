import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import {
  useGetEntity,
} from '@/api/entities';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import { Routes } from '@/navigation/routes';
import i18n from '@/internationalization';
import { useCreateSector, useGetSector, useUpdateSector } from '@/api/sector/hooks';
import { sectorIsActive } from '@/types/sectorType';

export const useSectorCreate = (entity: string, id: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: sector } = useGetSector(id, !!id);

  const [name, setName] = useState('');
  const [isActiveInactive, setIsActiveInactive,
  ] = useState<sectorIsActive>(sectorIsActive.Active);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { goBack, goToEntityPage } = useNavigate();
  const queryClient = useQueryClient();

  const mutatorCreate = useCreateSector();
  const createInvitationGeneral = () => mutatorCreate.mutateAsync({
    name,
    isActive: isActiveInactive === 'ativo',
    entity,
  });

  const mutatorUpdate = useUpdateSector();
  const updateInvitationGeneral = () => mutatorUpdate.mutateAsync({
    id,
    name,
    isActive: isActiveInactive !== 'inativo',
    entity,
  });

  const onSubmit = async () => {
    setLoading(true);

    try {
      const result = id ? await updateInvitationGeneral() : await createInvitationGeneral();

      if (result.status !== 201 && result.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        setAPIErrorMessage(i18n.t('errors.400'));
      }

      setLoading(false);

      await queryClient.invalidateQueries('sectors');
      goToEntityPage(
        Routes.sector,
        { params: { entity }, screen: Routes.sectorList },
      );
    } catch (e) {
      setHasAPIError(true);
      setAPIErrorMessage(i18n.t('errors.500'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sector?.id) {
      setName(sector.name);
      setIsActiveInactive(sector.isActive
        === true ? sectorIsActive.Active : sectorIsActive.Inactive);
    }
  }, [sector]);

  return {
    adminData,
    entityData,
    isActiveInactive,
    APIErrorMessage,
    hasAPIError,
    loading,
    name,
    setName,
    setIsActiveInactive,
    goBack,
    onSubmit,
  };
};
