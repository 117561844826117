import { useMutation, useQuery } from 'react-query';
import {
  createTotem, getTotem,
  getTotems,
  updateTotem,
} from './requests';

export const useGetTotems = (id: string, isEnabled: boolean) => useQuery(['totems', id], () => getTotems(id), { enabled: isEnabled });
export const useGetTotem = (id: string, isEnabled: boolean) => useQuery(['totem', id], () => getTotem(id), { enabled: isEnabled });

export const useCreateTotem = () => useMutation(createTotem);
export const useUpdateTotem = () => useMutation(updateTotem);
