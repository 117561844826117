import React from 'react';
import { Text, View } from 'react-native';
import { SuccessHandlerTypes } from './SuccessHandler.types';
import { styles } from './SuccessHandler.styles';

export const SuccessHandler = ({ message }: SuccessHandlerTypes) => (
  <View style={styles.container}>
    <Text style={styles.message}>{message}</Text>
  </View>
);

export default SuccessHandler;
