import { AdminType } from '../../types';
import locale from '../../internationalization';

export const AdminTypeSelect = [
  {
    label: locale.t('administrationCreate.select.owner'),
    value: AdminType.Owner,
  },
  {
    label: locale.t('administrationCreate.select.manager'),
    value: AdminType.Manager,
  },

];

export const AdminTypeManager = [
  {
    label: locale.t('administrationCreate.select.manager'),
    value: AdminType.Manager,
  },
];
