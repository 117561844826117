import React from 'react';

import { Text, TouchableOpacity, View } from 'react-native';
import { MFullWidthButton } from 'menupass-ui';
import { CancelSubscriptionModalTypes } from '@/components/cancelsubscriptionmodal/CancelSubscriptionModal.types';
import { Images } from '@/consts';
import { styles } from '@/components/cancelsubscriptionmodal/CancelSubscriptionModal.styles';

const CancelSubscriptionModal = ({
  onConfirm, onClose, title, buttonTitle, content,
}: CancelSubscriptionModalTypes) => (
  <View style={styles.container}>
    <View style={styles.modalContainer}>
      <View style={styles.closeContainer}>
        <TouchableOpacity onPress={onClose}>
          <img src={Images.CloseCross} alt={title} height={14} width={14} />
        </TouchableOpacity>
      </View>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.content}>{content}</Text>
      <View style={styles.btnContainer}>
        <MFullWidthButton label={buttonTitle} onPress={onConfirm} height={36} fontSize={12} />
      </View>
    </View>
  </View>
);

export default CancelSubscriptionModal;
