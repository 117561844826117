import React from 'react';
import { Text, View } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import type { SelectTypes, SelectItems } from './SelectTypes';
import { styles } from './SelectStyles';

export const Select = (props: SelectTypes) => {
  const {
    label,
    values,
    selectedValue,
    onValueChange,
    placeholder,
    enabled,
    height,
    borderRadius,
  } = props;

  const valuesList = values;

  if (valuesList?.length === 0) {
    valuesList?.push({
      label: placeholder || '',
      value: '',
    });
  }

  return (
    <View>
      <Text style={styles.label}>{label}</Text>
      <Picker
        style={[
          styles.picker,
          {
            height: height || 56,
            borderRadius: borderRadius || 10,
          },
        ]}
        selectedValue={selectedValue}
        onValueChange={onValueChange}
        enabled={enabled}>
        {valuesList?.map((item: SelectItems) => (
          <Picker.Item label={item.label} key={item.value} value={item.value} />
        ))}
      </Picker>
    </View>
  );
};

export default Select;
