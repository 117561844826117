import { useState } from 'react';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { base64Encode, emailIsValid } from '@/utils/strUtils';
import { Routes } from '@/navigation/routes';

export const useRegisterStep1 = () => {
  const { goToStep2 } = useNavigate();

  const { navigate } = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [emailError, setEmailError] = useState(false);

  const emailChange = (e: string) => {
    setEmail(e);
    setEmailError(emailIsValid(e));
  };

  const goToLogin = () => {
    navigate(Routes.Login);
  };

  const step2 = () => {
    if (name && email && password && passwordConfirm) {
      goToStep2({
        name,
        email,
        password: base64Encode(password),
        passwordConfirm: base64Encode(passwordConfirm),
      });
    }
  };

  return {
    name,
    email,
    password,
    passwordConfirm,
    setName,
    setPassword,
    setPasswordConfirm,
    emailError,
    step2,
    emailChange,
    goToLogin,
  };
};
