import React, { useEffect, useState } from 'react';
import { useFonts } from 'expo-font';
import AppLoading from 'expo-app-loading';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PaperProvider } from 'react-native-paper';
import { Main } from './src/navigation/main';
import theme from '@/config/theme';
import { LocalStorage } from '@/services/localStorage';
import { ADMIN } from '@/consts/storageKeys';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const logout = () => {
  LocalStorage.remove(ADMIN);
  LocalStorage.remove('role');
  window.location.reload();
};

export const App = () => {
  const [role, setRole] = useState<string | null>(null);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const storedRole = localStorage.getItem('role');
        if (storedRole) {
          setRole(storedRole);
        } else {
          setRole(null);
        }
      } catch (error) {
        return error;
      }
      return null;
    };

    fetchRole();
  }, []);

  useEffect(() => {

    const checkRoleAndSetTimer = () => {
      if (role === 'menupass_super') {
        const timer = setTimeout(() => {
          logout();
        }, 900000);

        return () => clearTimeout(timer);
      }
      return null;
    };

    if (role) {
      checkRoleAndSetTimer();
    }
  }, [role]);

  const [fontsLoaded] = useFonts({
    // eslint-disable-next-line global-require
    'Roboto-Regular': require('./src/assets/fonts/Roboto-Regular.ttf'),
    // eslint-disable-next-line global-require
    'Roboto-Bold': require('./src/assets/fonts/Roboto-Bold.ttf'),
    // eslint-disable-next-line global-require
    'Roboto-Medium': require('./src/assets/fonts/Roboto-Medium.ttf'),
    // eslint-disable-next-line global-require
    'Roboto-Light': require('./src/assets/fonts/Roboto-Light.ttf'),
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <PaperProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </PaperProvider>
  );
};

export default App;
