import React from 'react';
import { StyleSheet, View } from 'react-native';

type Props = {
  children: any
}

const Container = ({ children }: Props) => (
  <View style={styles.container} testID="container">
    {children}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 1488,
    width: '100%',
    alignSelf: 'center',
    padding: 25,
  },
});

export default Container;
