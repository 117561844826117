import { useEffect, useRef, useState } from 'react';
import locale from '../../internationalization';

export const MaskCpf = '000.000.000-00';
export const MaskCnpj = '00.000.000/0000-00';

export const useNationalIdMask = (type: 'cpf' | 'cnpj' = 'cnpj') => {
  const [nationalIdMask, setNationalIdMask] = useState('');
  const nationalIdRadio = useRef<any[]>([
    {
      label: locale.t('business'),
      value: MaskCnpj,
    },
    {
      label: locale.t('personal'),
      value: MaskCpf,
    },
  ]);

  useEffect(() => {
    setNationalIdMask(type === 'cnpj' ? MaskCnpj : MaskCpf);
  }, [type]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeNationalIdRadio = (val: string, _: number) => {
    setNationalIdMask(val);
  };

  return {
    onChangeNationalIdRadio,
    nationalIdMask,
    nationalIdRadio,
  };
};
