import React from 'react';
import { View } from 'react-native';
import { Header } from '../../components/header';
import { Container } from '../../components/container';
import { PageTitle } from '../../components/pagetitle';
import locale from '../../internationalization';
import { Paper } from '../../components/paper';
import { Datatable } from '../../components/datatable';
import { headers } from './planHistoryPage.consts';
import { PaperColumns } from '../../styles/common/PaperColumns';
import { PlanTabs } from '../../components/plantabs';
import { usePlanHistory } from './PlanHistoryPage.functions';

export const PlanHistoryPage = () => {
  const {
    adminData,
    list,
    loading,
    updateStatus,
    goToEdit,
  } = usePlanHistory();
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Container>
        <PageTitle title={locale.t('planHistory.pageTitle')} />
        <Paper disableInternalPadding bgColor="transparent">
          <PlanTabs plans plansAdd={false} />
          <View style={PaperColumns.tableContainer}>
            <Datatable
              headers={headers}
              data={list}
              loading={loading}
              onSwitchChange={updateStatus}
              onEditPress={goToEdit}
              searchFields={['name']}
            />
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default PlanHistoryPage;
