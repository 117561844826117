import { useMutation, useQuery } from 'react-query';
import { getPeriods, createNewPeriod, updatePeriod, getPeriod } from './requests';



export const useCreateNewPeriod = () => useMutation(createNewPeriod);
export const useUpdatePeriod = () => useMutation(updatePeriod);
export const useGetPeriod = (id: string, isEnabled: boolean) => useQuery(['period', id], () => getPeriod(id), { enabled: isEnabled });
export const useGetPeriods = (id: string, isEnabled: boolean) => useQuery<any>(
  ['useGetPeriods', id],
  () => getPeriods(id),
  { enabled: isEnabled },
);
