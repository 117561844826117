import { useMutation, useQuery } from 'react-query';
import {
  createProduct, getProduct, getProducts, updateProduct, getCategories
} from './requests';

export const useCreateProduct = () => useMutation(createProduct);
export const useUpdateProduct = () => useMutation(updateProduct);
export const useGetProducts = (entity: string, isEnabled: boolean) => useQuery(['products', entity], () => getProducts(entity), { enabled: isEnabled });
export const useGetProduct = (id: string, isEnabled: boolean) => useQuery(['product', id], () => getProduct(id), { enabled: isEnabled });
export const useGetCategories = (entity: string, isEnabled: boolean) => useQuery(['categories', entity], () => getCategories(entity), { enabled: isEnabled });
