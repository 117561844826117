import fetch from '../fetch';
import { CreateInvitationGeneral, UpdateInvitationGeneral } from './dto';

export const getInvitationGeneral = (id: string) => fetch(`/invitation-general/${id}`).then((result) => result.json());
export const createInvitationGeneral = (dto: CreateInvitationGeneral) => fetch('/invitation-general', {
  method: 'POST',
  body: JSON.stringify(dto),
});
export const updateInvitationGeneral = async (dto: UpdateInvitationGeneral) => fetch(`/invitation-general/${dto.entityId}`, {
  method: 'PUT',
  body: JSON.stringify(dto),
});
