import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { DatatableHeaderProps } from './datatableHeader.types';
import { datatableHeaderStyles } from './datatableHeader.styles';

export const DatatableHeader = ({ headers }: DatatableHeaderProps) => (
  <View style={datatableHeaderStyles.container}>
    {headers.map((h) => (
      <View key={h.key} style={{ width: h?.width ? h?.width : `${100 / headers.length}%` }}>
        <TouchableOpacity activeOpacity={0.7} disabled={!h.isOrderResultEnabled}>
          <Text style={datatableHeaderStyles.label}>{h.label}</Text>
        </TouchableOpacity>
      </View>
    ))}
  </View>
);

export default DatatableHeader;
