import fetch from '../fetch';
import { CreatePeriodDto, UpdatePeriodDto } from './dto';

export const createNewPeriod = (data: CreatePeriodDto) => fetch('/entity-period', {
    method: 'POST',
    body: JSON.stringify(data),
  });
export const updatePeriod = (dto: UpdatePeriodDto) => fetch(`/entity-period/${dto.id}`, {
    method: 'PUT',
    body: JSON.stringify(dto),
  });
export const getPeriods = (entityId: string) => fetch(`/entity-period/periods/${entityId}`).then((result) => result.json());
export const getPeriod = (id: string) => fetch(`/entity-period/id/${id}`).then((result) => result.json());
