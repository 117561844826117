import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '../routes';
import { ResponsibleProfilesPage } from '../../pages/responsibleprofiles';
import { ResponsibleCreatePage } from '../../pages/responsiblecreate';
import { ResponsibleRouteParams } from '../routes/RoutesParams';

const Stack = createStackNavigator<ResponsibleRouteParams>();

export const Responsible = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.ResponsibleProfiles}
      component={ResponsibleProfilesPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.ResponsibleCreate}
      component={ResponsibleCreatePage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Responsible;
