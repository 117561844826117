import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '@/navigation/routes';
import { PlanHistoryPage } from '@/pages/planhistory';
import { PlanCreatePage } from '@/pages/plancreate';
import { PlansRouteParams } from '@/navigation/routes/RoutesParams';

const Stack = createStackNavigator<PlansRouteParams>();

export const Plans = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.PlanHistory}
      component={PlanHistoryPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.PlanAdd}
      component={PlanCreatePage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Plans;
