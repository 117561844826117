import { useRef, useState } from 'react';
import { useCountdown } from './useCountdown';
import locale from '@/internationalization';
import { phoNumberRemoveMask } from '@/utils/strUtils';
import { useVerificationCode } from '@/api/signup';

export const VERIFICATION_CODE_TIME = 45;

export const useSendVerificationCode = (phone: string) => {
  const [isDisabledByCuntDown, setIsDisabledByCuntDown] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [code, setCode] = useState('');
  const verificationCodeResponse = useRef<any>();

  const verificationCodeMutator = useVerificationCode();

  const countDown = useCountdown(
    VERIFICATION_CODE_TIME,
    () => setIsDisabledByCuntDown(false),
    async () => {
      setIsDisabledByCuntDown(true);
      const result = await verificationCodeMutator.mutateAsync({
        message: locale.t('verificationCodeRequest'),
        replacer: locale.t('verificationCodeRequestReplacer'),
        to: `+55${phoNumberRemoveMask(phone)}`,
      });

      const data = await result.json();

      if (result.status !== 201) {
        setHasAPIError(true);
        const err = data.code ? locale.t(`errors.${data.code}`) : locale.t('errors.400');
        setAPIErrorMessage(err);
        setLoading(false);
        setIsDisabledByCuntDown(false);
        return;
      }

      verificationCodeResponse.current = data;
      setLoading(false);
    },
  );

  const onChangeCode = (val: string) => {
    setCode(val);
    if (val.trim() !== verificationCodeResponse.current?.code
      || new Date(verificationCodeResponse.current?.dueAt).getTime() < new Date().getTime()
    ) {
      setIsPhoneNumberValid(false);
    } else {
      setIsPhoneNumberValid(true);
    }
  };

  return {
    countDown,
    verificationCodeResponse,
    loading,
    hasAPIError,
    APIErrorMessage,
    isDisabledByCuntDown,
    code,
    isPhoneNumberValid,
    onChangeCode,
  };
};
