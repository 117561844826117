import React from 'react';
import Svg, {
  Circle, Defs, LinearGradient, Rect, Stop,
} from 'react-native-svg';
import { ProgressBarTypes } from '@/components/progressbar/ProgressBar.types';

export const ProgressBar = ({
  step2, step3, step1, step4, step5, steps, width,
}: ProgressBarTypes) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const FiveSteps = () => (
    <Svg width={width} height="12" viewBox="0 0 722 12" fill="none">
      <Rect y="2" width="722" height="7.99987" rx="3.99994" fill="#C9C9CC" />
      <Rect y="2" width="140" height="7.99996" rx="3.99998" fill="url(#paint0_linear)" />
      <Rect x="140" y="2" width="140" height="7.99996" rx="3.99998" fill="url(#paint1_linear)" />
      <Rect x="282" y="2" width="145" height="7.99996" rx="3.99998" fill="url(#paint2_linear)" />
      <Rect x="432" y="2" width="145" height="7.99996" rx="3.99998" fill="url(#paint3_linear)" />
      <Rect
        x="510"
        y="2"
        width="212"
        height="7.99998"
        rx="3.99999"
        // transform="rotate(-180 722 10)"
        fill="url(#paint4_linear)"
      />
      <Circle cx="140" cy="6" r="6" fill="black" />
      <Circle cx="280" cy="6" r="6" fill="black" />
      <Circle cx="430" cy="6" r="6" fill="black" />
      <Circle cx="580" cy="6" r="6" fill="black" />
      <Defs>
        {step1 && (
          <LinearGradient
            id="paint0_linear"
            x1="7.45779e-07"
            y1="5.99994"
            x2="241"
            y2="5.99985"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#31EEB6" />
            <Stop offset="1" stopColor="#31D8DB" />
          </LinearGradient>
        )}
        {step2 && (
          <LinearGradient id="paint1_linear" x1="241" y1="5.99994" x2="481" y2="5.99985" gradientUnits="userSpaceOnUse">
            <Stop stopColor="#31D6DE" />
            <Stop offset="1" stopColor="#31B6EE" />
          </LinearGradient>
        )}
        {step3 && (
          <LinearGradient id="paint2_linear" x1="722" y1="13.9999" x2="963" y2="13.9999" gradientUnits="userSpaceOnUse">
            <Stop stopColor="#31B6EE" />
            <Stop offset="1" stopColor="#3188EE" />
          </LinearGradient>
        )}
        {step4 && (
          <LinearGradient id="paint3_linear" x1="1444" y1="13.9999" x2="1926" y2="13.9999" gradientUnits="userSpaceOnUse">
            <Stop stopColor="#3188EE" />
            <Stop offset="1" stopColor="#31B6EE" />
          </LinearGradient>
        )}
        {step5 && (
          <LinearGradient id="paint4_linear" x1="1444" y1="13.9999" x2="1926" y2="13.9999" gradientUnits="userSpaceOnUse">
            <Stop stopColor="#3188EE" />
            <Stop offset="1" stopColor="#31B6EE" />
          </LinearGradient>
        )}
      </Defs>
    </Svg>
  );
  // eslint-disable-next-line react/no-unstable-nested-components
  const ThreeSteps = () => (
    <Svg width={width} height="12" viewBox="0 0 722 12" fill="none">
      <Rect y="2" width="722" height="7.99987" rx="3.99994" fill="#C9C9CC" />
      <Rect y="2" width="241" height="7.99996" rx="3.99998" fill="url(#paint0_linear)" />
      <Rect x="241" y="2" width="240" height="7.99996" rx="3.99998" fill="url(#paint1_linear)" />
      <Rect
        x="722"
        y="10"
        width="241"
        height="7.99998"
        rx="3.99999"
        transform="rotate(-180 722 10)"
        fill="url(#paint2_linear)"
      />
      <Circle cx="241" cy="6" r="6" fill="black" />
      <Circle cx="481" cy="6" r="6" fill="black" />
      <Defs>
        {step1 && (
          <LinearGradient
            id="paint0_linear"
            x1="7.45779e-07"
            y1="5.99994"
            x2="241"
            y2="5.99985"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#31EEB6" />
            <Stop offset="1" stopColor="#31D8DB" />
          </LinearGradient>
        )}
        {step2 && (
          <LinearGradient id="paint1_linear" x1="241" y1="5.99994" x2="481" y2="5.99985" gradientUnits="userSpaceOnUse">
            <Stop stopColor="#31D6DE" />
            <Stop offset="1" stopColor="#31B6EE" />
          </LinearGradient>
        )}
        {step3 && (
          <LinearGradient id="paint2_linear" x1="722" y1="13.9999" x2="963" y2="13.9999" gradientUnits="userSpaceOnUse">
            <Stop stopColor="#3188EE" />
            <Stop offset="1" stopColor="#31B6EE" />
          </LinearGradient>
        )}
      </Defs>
    </Svg>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const TwoSteps = () => (
    <Svg width={width} height="12" viewBox="0 0 722 12" fill="none">
      <Rect y="2" width="722" height="7.99987" rx="3.99994" fill="#C9C9CC" />
      <Rect y="2" width="364" height="7.99994" rx="3.99997" fill="url(#paint0_linear_521:10215)" />
      <Rect x="722" y="10" width="358" height="7.99997" rx="3.99999" transform="rotate(-180 722 10)" fill="url(#paint1_linear_521:10215)" />
      <Circle cx="364" cy="6" r="6" fill="black" />
      <Defs>
        {step1 && (
          <LinearGradient id="paint0_linear_521:10215" x1="1.1264e-06" y1="5.99993" x2="364" y2="5.99973" gradientUnits="userSpaceOnUse">
            <stop stopColor="#31EEB6" />
            <stop offset="1" stopColor="#31C7E6" />
          </LinearGradient>
        )}
        {step2 && (
          <LinearGradient id="paint1_linear_521:10215" x1="722" y1="13.9999" x2="1080" y2="13.9998" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3188EE" />
            <stop offset="1" stopColor="#31C6E6" />
          </LinearGradient>
        )}
      </Defs>
    </Svg>
  );

  if (steps === 5) {
    return <FiveSteps />;
  }
  return steps && steps === 2 ? <TwoSteps /> : <ThreeSteps />;
};

export default ProgressBar;
