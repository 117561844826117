import { MSelectItems } from 'menupass-ui/lib/typescript/components/select/MSelectTypes';

export const SystemPlanStatusList: MSelectItems[] = [
  {
    label: 'Ativo',
    value: '1',
  },
  {
    label: 'Inativo',
    value: '0',
  },
];
