import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import locale from '@/internationalization';
import { colors } from '@/consts';
import { Routes } from '@/navigation/routes';
import { Styles } from './SystemTabs.styles';
import { SystemTabsTypes } from './SystemTabs.types';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';

const SystemTabs = ({ entityId, system, periodCreate, periodList }: SystemTabsTypes) => {
  const { goToEntityPage } = useNavigate();

  return (
    <View style={Styles.container}>
      <TouchableOpacity
        style={[
          Styles.btnContainer,
          { borderColor: system ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => goToEntityPage(Routes.System, {
          params: { entity: entityId },
          screen: Routes.SystemConfig,
        })}
      >
        <Text
          style={[
            Styles.btnText, { color: system ? colors.tabs.selectedText : colors.tabs.text },
          ]}
        >
          {locale.t('systemTabs.system')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          Styles.btnContainer,
          { borderColor: periodList ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => goToEntityPage(Routes.System, {
          params: { entity: entityId },
          screen: Routes.periodList,
        })}
      >
        <Text
          style={[
            Styles.btnText, { color: periodList ? colors.tabs.selectedText : colors.tabs.text },
          ]}
        >
          {locale.t('systemTabs.periodList')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          Styles.btnContainer,
          { borderColor: periodCreate ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => goToEntityPage(Routes.System, {
          params: { entity: entityId },
          screen: Routes.periodCreate,
        })}
      >
        <Text style={[Styles.btnText, {
          marginLeft: 6,
          color: periodCreate ? colors.tabs.selectedText : colors.tabs.text,
        }]}
        >
          {locale.t('systemTabs.periodCreate')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default SystemTabs;
