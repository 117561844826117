import { StyleSheet } from 'react-native';
import { colorSchema } from '../../../consts/colors';
import { typography } from '../../../styles';

export const styles = StyleSheet.create({
  warpper: {
    flexDirection: 'row',
  },
  container: {
    flexDirection: 'row',
    paddingTop: 5,
  },
  selectContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    marginTop: -18,
    marginLeft: 20,
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 42,
    borderRadius: 14,
    borderWidth: 1,
    borderColor: colorSchema.Gray2,
    width: 340,
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginBottom: 24,
  },
  input: {
    height: 40,
    width: 278,
    ...typography.p3,
    color: colorSchema.PureBlack,
  },
  imageWrapper: {
    width: 24,
    height: 24,
  },
  spacer20: {
    width: 20,
  },
});
