import React from 'react';
import * as DocumentPicker from 'expo-document-picker';
import { Image, TouchableOpacity, View } from 'react-native';
import { Images } from '../../consts';
import locale from '../../internationalization';
import { styles } from './UploadComponent.styles';
import { UploadComponentTypes } from './UploadComponent.types';

export const UploadComponent = ({ onFileSelect, uri }: UploadComponentTypes) => {
  const pick = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: 'image/*',
      copyToCacheDirectory: true,
      multiple: false,
    });

    if (result.type === 'success') {
      onFileSelect(result.file as File, result.uri);
    }
  };
  return (
    <TouchableOpacity style={styles.container} onPress={pick} activeOpacity={0.7}>
      {uri ? (
        <Image source={{ uri }} width={178} height={178} style={styles.img} />
      ) : (
        <View style={styles.plusImg}>
          <img src={Images.PlusIcon} alt={locale.t('imagesAlt.add')} />
        </View>
      )}
    </TouchableOpacity>
  );
};

export default UploadComponent;
