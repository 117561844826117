// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import Access from '@/assets/images/login.svg';
import MiniLogo from '@/assets/images/mini-logo.svg';
import ProfileHeader from '@/assets/images/profile-header.svg';
import PlusIcon from '@/assets/images/plus-icon.svg';
import EntityIcon from '@/assets/images/entity-icon.svg';
import HalfArrowLeft from '@/assets/images/half-arrow-left.svg';
import HalfArrowRight from '@/assets/images/half-arrow-right.svg';
import PaymentDataIcon from '@/assets/images/payment-data.svg';
import PaymentCardsIcon from '@/assets/images/cards.svg';
import ProfileIcon from '@/assets/images/profile.svg';
import AdminCreateIcon from '@/assets/images/admin-creaate.svg';
import ProductCreateIcon from '@/assets/images/product-create.svg';
import EditIcon from '@/assets/images/edit.svg';
import PlusISquareIcon from '@/assets/images/plus.svg';
import SearchIcon from '@/assets/images/search-icon.svg';
import CloseCross from '@/assets/images/close-cross.svg';
import InvitationIcon from '@/assets/images/invitation.svg';

export const Images = {
  Access,
  MiniLogo,
  ProfileHeader,
  PlusIcon,
  EntityIcon,
  HalfArrowLeft,
  HalfArrowRight,
  PaymentDataIcon,
  PaymentCardsIcon,
  ProfileIcon,
  AdminCreateIcon,
  ProductCreateIcon,
  EditIcon,
  PlusISquareIcon,
  SearchIcon,
  CloseCross,
  InvitationIcon,
};
