import React from 'react';
import { View } from 'react-native';
import { Switch as RNSwitch } from 'react-native-switch';
import { SwitchTypes } from './Switch.types';
import { switchStyles } from './Switch.styles';

export const Switch = ({
  onValueChange,
  value,
  label,
  circleColorActive,
  circleColorInactive,
  backgroundActive,
  backgroundInactive,
  disabled,
}: SwitchTypes) => (
  <View style={switchStyles.container}>
    <RNSwitch
      onValueChange={onValueChange}
      circleActiveColor={circleColorActive}
      circleInActiveColor={circleColorInactive}
      value={value}
      backgroundActive={backgroundActive}
      backgroundInactive={backgroundInactive}
      renderActiveText={false}
      renderInActiveText={false}
      circleBorderActiveColor={circleColorActive}
      circleBorderInactiveColor={circleColorActive}
      circleBorderWidth={2}
      barHeight={18}
      circleSize={18}
      disabled={disabled}
    />
    {!!label && <View style={{ marginLeft: label ? 20 : 0 }}>{label}</View>}
  </View>
);
