import { PeriodRoles } from '@/types/periodsRoles';
import { SelectedEntityParams } from '../../navigation/routes/RoutesParams';

export type SystemPeriodCreatePageTypes = {
  route: SelectedEntityParams
}

export const selectPeriod = [
  {
    label: 'Manhã',
    value: PeriodRoles.manha,
  },
  {
    label: 'Tarde',
    value: PeriodRoles.tarde,
  },
  {
    label: 'Noite',
    value: PeriodRoles.noite,
  },
];
