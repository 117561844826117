import { ColumnComponents } from '@/components/datatable/header/datatableHeader.types';
import { DatatableHeader } from '../../components/datatable';
import { productRole } from '@/types/productRoles';
import locale from '@/internationalization/';

export const financeExtractsPageHeader: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'type',
    label: locale.t('finance.transaction'),
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'user',
    label: locale.t('finance.user'),
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'total',
    label: locale.t('finance.total'),
    width: '10%',
  },
  {
    isOrderResultEnabled: false,
    key: 'status',
    label: locale.t('finance.status'),
    width: '10%',
  },
  {
    isOrderResultEnabled: false,
    key: 'data',
    label: locale.t('finance.date'),
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'deliveredAt',
    label: locale.t('finance.deliveredAt'),
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'balance',
    label: locale.t('finance.balance'),
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'detalhes',
    label: locale.t('finance.details'),
    width: '10%',
    renderComponent: ColumnComponents.Detalhes,
  },
];

export const financeExtractOrderProductPageHeader: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'produto',
    label: locale.t('finance.product'),
    width: '20%',
  },
  {
    isOrderResultEnabled: false,
    key: 'quantidade',
    label: locale.t('finance.quantity'),
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'valor',
    label: locale.t('finance.value'),
    width: '15%',
  },
];

export const financeActiveInactive = [
  {
    label: 'Ativo',
    value: productRole.Activo,
  },
  {
    label: 'Inativo',
    value: productRole.Inactive,
  },
];
