import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '../routes';
import { TotemRouteParams } from '../routes/RoutesParams';
import { TotemProfilesPage } from '@/pages/totemprofiles';
import { TotemCreatePage } from '@/pages/totemcreate';

const Stack = createStackNavigator<TotemRouteParams>();

export const Totem = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.TotemProfiles}
      component={TotemProfilesPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.TotemProfilesCreate}
      component={TotemCreatePage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Totem;
