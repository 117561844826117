import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity } from '@/api/entities';
import { Routes } from '@/navigation/routes';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import { useGetSectors, useUpdateSector } from '@/api/sector/hooks';
import { Sector } from '@/types/sector';
import locale from '../../internationalization';

export const useSectorList = (entity: string) => {
  const { goToEntityPage } = useNavigate();

  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: sectors, isLoading: loading } = useGetSectors(entity, !!entity);

  const [sectorList, setSectorList] = useState<any[]>([]);

  const queryClient = useQueryClient();
  const mutator = useUpdateSector();

  const goToEdit = (idSector: string) => {
    const index = sectorList.findIndex((c) => c.id === idSector);
    const { id } = sectorList[index];
    goToEntityPage(Routes.sector, {
      params: {
        entity,
        id,
      },
      screen: Routes.sectorCreate,
    });
  };

  const formatSectorList = (list: Sector[]) => {
    const listFormatted = list.map((sector) => ({
      id: sector.id,
      name: sector.name,
      isActive: {
        label: sector.isActive ? locale.t('active') : locale.t('inactive'),
        value: sector.isActive,
      },
      manage: '',
    }));

    setSectorList(listFormatted);
  };

  const updateStatus = async (val: boolean, idSector: string) => {
    const index = sectorList.findIndex((c) => c.id === idSector);
    if (sectors[index]) {
      const sectorData = sectors[index] as Sector;

      await mutator.mutateAsync({
        id: idSector,
        name: sectorData.name,
        isActive: val,
        entity: sectorData.id,
      });
      await queryClient.invalidateQueries('sectors');
    }
  };

  useEffect(() => {
    if (sectors?.length && !loading) {
      formatSectorList(sectors);
    }
  }, [sectors]);

  return {
    adminData,
    loading,
    sectorList,
    entityData,
    updateStatus,
    goToEdit,
  };
};
