import { useEffect, useState } from 'react';
import { RegisterStep2RouteParams } from '@/navigation/routes/RoutesParams';
import { Routes } from '@/navigation/routes';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { useUserSignup } from '@/api/signup';
import { AdminRole, AdminType } from '@/types';
import { base64Decode, phoNumberRemoveMask } from '@/utils/strUtils';
import locale from '@/internationalization';
import { LocalStorage } from '@/services/localStorage';
import { ADMIN } from '@/consts/storageKeys';
import { useSendVerificationCode } from '@/common/hooks/useSendVerificationCode';

export const VERIFICATION_CODE_TIME = 45;

export const useRegisterStep2 = ({
  name, email, password, passwordConfirm,
}: RegisterStep2RouteParams) => {
  const [phone, setPhone] = useState('');
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);

  const [loading, setLoading] = useState(false);

  const { navigate } = useNavigate();
  const mutator = useUserSignup();

  const {
    countDown, isDisabledByCuntDown, isPhoneNumberValid, code, onChangeCode,
  } = useSendVerificationCode(phone);

  useEffect(() => {
    if (!name || !email || !password || !passwordConfirm) {
      navigate(Routes.Register);
    }
  }, []);

  const goToRegister = () => {
    navigate(Routes.Register);
  };

  const submit = async () => {
    setLoading(true);
    try {
      const result = await mutator.mutateAsync({
        name,
        email,
        phone: `+55${phoNumberRemoveMask(phone)}`,
        password: base64Decode(password),
        username: email,
        role: AdminRole.Admin,
        adminType: AdminType.Owner,
      });

      const data = await result.json();

      if (result.status !== 201) {
        setHasAPIError(true);
        const err = data.code ? locale.t(`errors.${data.code}`) : locale.t('errors.400');
        setAPIErrorMessage(err);
        setLoading(false);
        return;
      }

      await LocalStorage.save(ADMIN, data);
      window.location.reload();
    } catch (e) {
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setLoading(false);
    }
  };

  const sendVerificationCode = async () => {
    countDown.start();
  };

  return {
    submit,
    sendVerificationCode,
    onChangeCode,
    goToRegister,
    code,
    isPhoneNumberValid,
    hasAPIError,
    APIErrorMessage,
    phone,
    setPhone,
    loading,
    isDisabledByCuntDown,
    countDown,
  };
};
