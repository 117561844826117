import { StyleSheet } from 'react-native';

export const Styles = StyleSheet.create({
  radioWrapper: {
    marginTop: 25,
  },
  radio: {
    marginBottom: 15,
  },
});
