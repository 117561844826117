import { NavigationProp, useNavigation } from '@react-navigation/native';
import { InternalRouteParams } from '../RoutesParams';
import { Routes } from '../Routes.types';
import { Flows } from '@/navigation/routes/enums/Flows';

export const useNavigatePayment = () => {
  const navigation = useNavigation<NavigationProp<InternalRouteParams, Routes.Payment>>();

  const goToPayment = (params: { screen: Routes; params: { flow: Flows } }) => {
    navigation.navigate(Routes.Payment, params);
  };

  return { goToPayment };
};

export default useNavigatePayment;
