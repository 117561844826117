import React from 'react';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { View, Text, TouchableOpacity } from 'react-native';
import { colors, dimensions, Images } from '../../consts';
import locale from '../../internationalization';
import { LoginRegisterStyle } from '../../styles/common';
import { Styles } from './login.styles';
import { useLogin } from './Login.functions';
import { ErrorHandler } from '../../components/errorhandler';

export const Login = () => {
  const {
    setPassword,
    password,
    emailError,
    email,
    hasAPIError,
    APIErrorMessage,
    emailChange,
    goToRegister,
    login,
    goToRecoveryPassword,
  } = useLogin();
  return (
    <View style={LoginRegisterStyle.container}>
      <View style={LoginRegisterStyle.imageContainer}>
        <img
          src={Images.Access}
          height={dimensions.height}
          width={dimensions.width * 0.5}
          alt={locale.t('imagesAlt.access')}
        />
      </View>
      <View style={LoginRegisterStyle.formWrapper}>
        <View style={LoginRegisterStyle.formContainer}>
          <Text style={LoginRegisterStyle.title}>{locale.t('login.title')}</Text>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              label={locale.t('login.form.login')}
              placeholder={locale.t('login.form.loginPlaceholder')}
              onChangeText={emailChange}
              hasError={emailError}
              value={email}
              height={42}
              borderRadius={14}
            />
          </View>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              label={locale.t('login.form.password')}
              placeholder={locale.t('login.form.passwordPlaceholder')}
              secureTextEntry
              isPassword
              value={password}
              onChangeText={setPassword}
              onSubmitEditing={() => login()}
              height={42}
              borderRadius={14}
            />
            <TouchableOpacity
              activeOpacity={0.7}
              style={Styles.forgotPassword}
              onPress={() => goToRecoveryPassword()}
            >
              <Text style={Styles.forgotPasswordText}>{locale.t('login.forgotPassword')}</Text>
            </TouchableOpacity>
            {hasAPIError && (
              <ErrorHandler message={APIErrorMessage} />
            )}
            <View style={LoginRegisterStyle.buttonsContainer}>
              <MFullWidthButton
                label={locale.t('login.form.signIn')}
                onPress={() => login()}
                disabled={email === '' || password === '' || emailError}
                height={42}
                fontSize={14}
              />
            </View>
            <View style={LoginRegisterStyle.buttonsContainer}>
              <Text style={Styles.notRegistered}>{locale.t('login.notRegistered')}</Text>
              <MFullWidthButton
                onPress={() => goToRegister()}
                label={locale.t('login.register')}
                bgColor={colors.login.notRegisteredBg}
                labelColor={colors.login.notRegistered}
                height={42}
                fontSize={14}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Login;
