import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { formatNumber } from 'react-native-currency-input';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { Paper } from '@/components/paper';
import { colors } from '@/consts';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Subheader } from '@/components/subheader';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { PageStyles } from '@/styles/common/PageStyles';
import { Styles } from './RemoveBalanceCreatePage.styles';
import { FinanceTabs } from '@/components/financetabs';
import { RemoveBalanceCreatePageTypes } from './RemoveBalanceCreatePage.types';
import { useRemoveBalanceCreatePage } from './RemoveBalanceCreatePage.functions';
import { Routes } from '@/navigation/routes';
import { SearchableInput } from '@/components/searchableinput/SearchableInput';
import { currencyFormatFloat } from '@/utils/strUtils';
import { formatOptions } from '@/utils/currencyMask';
import { ErrorHandler } from '@/components/errorhandler';

export const RemoveBalanceCreatePage = ({
  route: {
    params: { entity },
  },
}: RemoveBalanceCreatePageTypes) => {
  const {
    adminData,
    entityData,
    disabled,
    clientsSearchList,
    hasAPIError,
    APIErrorMessage,
    client,
    balance,
    amount,
    setAmount,
    setBalance,
    setClient,
    setClientId,
    remove,
    cancel,
  } = useRemoveBalanceCreatePage(entity);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.FinanceRemoveBalance}
      />

      <Container>
        <EntitiesPageTitle
          title={locale.t('rechargeTransferCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <FinanceTabs
            entityId={entity}
            manualRecharge={false}
            extract={false}
            rechargeTransfer={false}
            removeBalance
          />
          <View style={[PaperColumns.tableContainer]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.imageWrapper} />
                  <Text style={PageStyles.subtitle}>
                    {locale.t('removeBalanceCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('removeBalanceCreate.content')}
                  </Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <View />
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('removeBalanceCreate.cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          height={42}
                          onPress={cancel}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('removeBalanceCreate.save')}
                          height={42}
                          fontSize={14}
                          onPress={remove}
                          disabled={
                            disabled ||
                            client.trim() === '' ||
                            amount === 0 ||
                            amount === null
                          }
                        />
                      </View>
                    </View>
                  </View>
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  <View style={PageStyles.formRow}>
                    <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                      <SearchableInput
                        value={client}
                        onChange={inputValue => setClient(inputValue)}
                        label={locale.t('removeBalanceCreate.form.user')}
                        data={clientsSearchList}
                        onSelect={item => {
                          setClientId(item.value);
                          setClient(item.label);
                          setBalance(
                            formatNumber(
                              currencyFormatFloat(item.metadata as number),
                              formatOptions,
                            ),
                          );
                        }}
                      />
                      {client.length > 0 && (
                        <Text style={Styles.textBalance}>
                          {locale.t('finance.balance')}: {balance}
                        </Text>
                      )}
                    </View>

                    <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                      <MTextInput
                        label={locale.t('rechargeTransferCreate.form.value')}
                        value={amount}
                        prefix="R$ "
                        onChangeValue={setAmount}
                        isCurrency
                        height={42}
                        borderRadius={14}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default RemoveBalanceCreatePage;
