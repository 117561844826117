import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { DataTable } from 'react-native-paper';
import DatatableHeader from './header/DatatableHeader';
import { DatatableProps } from './datatable.types';
import DatatableBody from './body/DatatableBody';
import { colors, dimensions } from '../../consts';
import { datatableStyles } from './datatable.styles';
import DatatableFilter from './filter/DatatableFilter';
import { searchMatch } from '../../utils/search/searchMatch';
import locale from '@/internationalization';

export const Datatable = ({
  data,
  height,
  headers,
  loading,
  onSwitchChange,
  onEditPress,
  onDetalhes,
  access,
  searchFields,
  showfilterSelect,
  values,
  orderValues,
  segmentValues,
  onValueChange,
  onOrderChange,
  onSegmentChange,
  selectedValue,
  selectedOrder,
  selectedSegment,
  label,
}: DatatableProps) => {
  const [filteredData, setFilteredData] = useState<any[]>(data);
  const tableHeight = { height: height || dimensions.paperHeight - 240 };
  const itemsPerPage = 10;
  const [page, setPage] = useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = useState(itemsPerPage);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setFilteredData(data);
    if (searchText) {
      _search(searchText);
    } else {
      setFilteredData(data);
      setPage(0);
    }
  }, [data, selectedValue, selectedOrder, selectedSegment]);

  useEffect(() => {
    setPage(0);
  }, [numberOfItemsPerPage]);

  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, filteredData.length);

  useEffect(() => {
    setPage(0);
  }, [numberOfItemsPerPage]);

  const _search = (val: string) => {
    setSearchText(val);
    const result = searchMatch(val, data, searchFields);
    setPage(0);
    setFilteredData(result);
  };

  const getItems = (): any[] => filteredData.slice(from, to);

  const labelPagination = `${locale.t('datatable.paginationQuantity')} ${from + 1}-${to} ${locale.t('datatable.paginationQuantityOf')} ${filteredData.length} ${locale.t('datatable.paginationQuantityResults')}`;

  return (
    <View>
      <DatatableFilter
        label={label}
        selectedValue={selectedValue}
        selectedOrder={selectedOrder}
        selectedSegment={selectedSegment}
        onValueChange={onValueChange}
        onOrderChange={onOrderChange}
        onSegmentChange={onSegmentChange}
        onSearch={_search}
        showfilterSelect={showfilterSelect}
        values={values}
        orderValues={orderValues}
        segmentValues={segmentValues}
      />

      <DataTable.Pagination
        page={page}
        numberOfPages={Math.ceil(filteredData.length / numberOfItemsPerPage)}
        onPageChange={pageC => setPage(pageC)}
        label={labelPagination}
        showFastPaginationControls
        numberOfItemsPerPage={numberOfItemsPerPage}
        onItemsPerPageChange={onItemsPerPageChange}
        selectPageDropdownLabel={`${locale.t('datatable.itemsPerPage')}`}
      />

      <DatatableHeader headers={headers} />
      
      {loading ? (
        <View style={[datatableStyles.loader, tableHeight]}>
          <ActivityIndicator size="large" color={colors.loading} />
        </View>
      ) : (
        <DatatableBody
          items={getItems()}
          height={height}
          headers={headers}
          onSwitchChange={onSwitchChange}
          onEditPress={onEditPress}
          onDetalhes={onDetalhes}
          access={access}
        />
      )}

      <DataTable.Pagination
        page={page}
        numberOfPages={Math.ceil(filteredData.length / numberOfItemsPerPage)}
        onPageChange={pageC => setPage(pageC)}
        label={labelPagination}
        showFastPaginationControls
        numberOfItemsPerPage={numberOfItemsPerPage}
        onItemsPerPageChange={onItemsPerPageChange}
        selectPageDropdownLabel={`${locale.t('datatable.itemsPerPage')}`}
      />
    </View>
  );
};

export default Datatable;
