import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import locale from '@/internationalization';
import { colors } from '@/consts';
import { Routes } from '@/navigation/routes';
import { totemStyles } from './TotemTabs.styles';
import { TotemTabsTypes } from './TotemTabs.types';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';

export const TotemTabs = ({ entityId, create, profiles }: TotemTabsTypes) => {
  const { goToEntityPage } = useNavigate();

  return (
    <View style={totemStyles.container}>
      <TouchableOpacity
        style={[
          totemStyles.btnContainer,
          { borderColor: profiles ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => goToEntityPage(Routes.Totem, {
          params: { entity: entityId },
          screen: Routes.TotemProfiles,
        })}
      >
        <Text
          style={[
            totemStyles.btnText, { color: profiles ? colors.tabs.selectedText : colors.tabs.text },
          ]}
        >
          {locale.t('administrationTabs.profiles')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          totemStyles.btnContainer,
          { borderColor: create ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => goToEntityPage(Routes.Totem, {
          params: { entity: entityId },
          screen: Routes.TotemProfilesCreate,
        })}
      >
        <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <Path
            d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
            fill={create ? colors.tabs.selectedText : colors.tabs.text}
          />
        </Svg>
        <Text style={[totemStyles.btnText, {
          marginLeft: 6,
          color: create ? colors.tabs.selectedText : colors.tabs.text,
        }]}
        >
          {locale.t('administrationTabs.create')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default TotemTabs;
