import { NavigationProp, useNavigation } from '@react-navigation/native';
import {
  ExternalRouteParams,
  RecoveryPasswordStep2Params,
} from '@/navigation/routes/RoutesParams';
import { Routes } from '@/navigation/routes';

export const useNavigateRecoveryPassword2 = () => {
  const navigation = useNavigation<NavigationProp<ExternalRouteParams, Routes.RecoveryPassword2>>();

  const goToRecoveryPassword = (params: RecoveryPasswordStep2Params) => {
    navigation.navigate(Routes.RecoveryPassword2, params);
  };

  return { goToRecoveryPassword };
};

export default useNavigateRecoveryPassword2;
