import fetch from '../fetch';
import { CreateTotemDto, UpdateTotemDto, UpdateTotemStatus } from '@/api/totem/dto';

export const getTotems = (entityId: string) => fetch(`/totem/entity/${entityId}`).then((result) => result.json());

export const getTotem = (id: string) => fetch(`/totem/${id}`).then((result) => result.json());

export const createTotem = (data: CreateTotemDto) => fetch('/totem', {
  method: 'POST',
  body: JSON.stringify(data),
});

export const updateTotem = (data: UpdateTotemDto | UpdateTotemStatus) => fetch(`/totem/${data.id}`, {
  method: 'PATCH',
  body: JSON.stringify(data),
});
