import { StyleSheet } from 'react-native';
import { typography } from '../../../styles';
import { colors } from '../../../consts';

export const datatableHeaderStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.datatableHeader.headerBg,
    height: 40,
    borderRadius: 40 / 2,
    paddingHorizontal: 25,
    marginTop: 20,
  },
  label: {
    ...typography.p3,
    color: colors.datatableHeader.label,
  },
});
