import { DatatableHeader } from '../../components/datatable';
import { ColumnComponents } from '../../components/datatable/header/datatableHeader.types';

export const homeListHeaders: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'name',
    label: 'Nome',
    width: '50%',
  },
  {
    isOrderResultEnabled: false,
    key: 'picture',
    label: 'Logo',
    width: '30%',
    renderComponent: ColumnComponents.Image,
  },
  {
    isOrderResultEnabled: false,
    key: 'access',
    label: 'Acessar',
    width: '10%',
    renderComponent: ColumnComponents.access,
  },
];
