import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '../routes';
import { ProductsListPage } from '../../pages/productslist';
import { ProductCreatePage } from '../../pages/productcreate';
import { ProductRouteParams } from '../routes/RoutesParams';

const Stack = createStackNavigator<ProductRouteParams>();

export const Product = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.ProductList}
      component={ProductsListPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.ProductCreate}
      component={ProductCreatePage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Product;
