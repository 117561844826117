import { NewEntityParams } from '@/navigation/routes/RoutesParams';
import locale from '@/internationalization';
import { AccountType } from '@/types/accountType';

export type NewEntityTypes = {
  route: {
    params: NewEntityParams
  }
}

export const AccountTypeSelect = [
  {
    label: locale.t('checking'),
    value: AccountType.Checking,
  },
  {
    label: locale.t('saving'),
    value: AccountType.Savings,
  },
];
