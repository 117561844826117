// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import slugfy from './slugfy';

/**
 *
 * @param searchTerm The value to compare
 * @param items List of items to be filtered
 * @param searchFields List of fields from each item that will be compared, default "name"
 */
export const searchMatch = (
  searchTerm: string,
  items: object[],
  searchFields = ['name'],
) => searchFields.reduce((list: any[], field) => {
  const filtered = items.filter((item) => {
    // skip if value is null or the item already inside filtered list
    if (!item[field] || list.indexOf(item) !== -1) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return compare(item[field], searchTerm, items.length > 99999);
  });
  return list.concat(filtered);
}, []);

export const matchFields = (
  searchTerm: string,
  item: object,
  fields: string[] = [],
  strict = false,
  clear = true,
): boolean => {
  const match = fields.find((field) => {
    if (!item[field]) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return compare(item[field], searchTerm, strict, clear);
  });

  return Boolean(match);
};

const compare = (
  left: string,
  right: string,
  strict = false,
  clear = false,
) => {
  if (clear) {
    // eslint-disable-next-line no-param-reassign
    left = left.replace(/\W/g, '');
    // eslint-disable-next-line no-param-reassign
    right = right.replace(/\W/g, '');
  }
  // Apply faster and simpler comparation if list is too big
  if (strict) {
    return left.toLowerCase() === right.toLowerCase();
  }
  return slugfy(left, { removeSpaces: false }).match(
    new RegExp(slugfy(right, { removeSpaces: false }), 'gi'),
  );
};
