import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '../routes';
import { ClientProfilesPage } from '../../pages/clientprofiles';
import { ClientCreatePage } from '../../pages/clientcreate';
import { ClientRouteParams } from '../routes/RoutesParams';

const Stack = createStackNavigator<ClientRouteParams>();

export const Client = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.ClientProfiles}
      component={ClientProfilesPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.ClientCreate}
      component={ClientCreatePage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Client;
