export const colorSchema = {
  PureBlack: '#000000',
  Gray12: '#0D0D15',
  Gray11: '#161723',
  Gray10: '#2D2D3F',
  Gray9: '#4D4D59',
  Gray8: '#5C5C67',
  Gray7: '#6F6F78',
  Gray6: '#7F7F88',
  Gray5: '#95959D',
  Gray4: '#A9A9AD',
  Gray3: '#C1C1C5',
  Gray2: '#E5E5E8',
  Gray1Half: '#F2F2F4',
  Gray1: '#F9F9F9',
  PureWhite: '#FFFFFF',
  Error: '#FF0000',
  Success: '#1B8E87',
};

export const colors = {
  loading: colorSchema.PureBlack,
  default: {
    pageBg: colorSchema.Gray1,
  },
  login: {
    bg: colorSchema.PureBlack,
    forgotPassword: colorSchema.Gray4,
    notRegistered: colorSchema.PureBlack,
    notRegisteredBg: colorSchema.Gray2,
  },
  register: {
    step: colorSchema.Gray4,
    confirmationText: colorSchema.PureBlack,
    sendCodeLabel: colorSchema.PureBlack,
    sendCodeBg: colorSchema.Gray2,
  },
  header: {
    bg: colorSchema.PureBlack,
    username: colorSchema.PureWhite,
    menuButtonBg: colorSchema.Gray11,
    menuButtonText: colorSchema.PureWhite,
    submenuBg: colorSchema.PureWhite,
  },
  subheader: {
    bg: colorSchema.Gray10,
    menuBgItem: colorSchema.PureBlack,
    menuText: colorSchema.PureWhite,
  },
  pageTitle: {
    title: colorSchema.PureBlack,
  },
  paper: {
    bg: colorSchema.PureWhite,
  },
  entityCard: {
    bg: colorSchema.Gray2,
  },
  home: {
    addBtnBg: colorSchema.PureBlack,
    name: colorSchema.PureBlack,
    editBg: colorSchema.Gray2,
    editText: colorSchema.PureBlack,
    bg: colorSchema.PureWhite,
  },
  newEntity: {
    subtitle: colorSchema.PureBlack,
    subtitleRegister: colorSchema.PureBlack,
    entitiesBtn: colorSchema.Gray2,
    entitiesBtnTitle: colorSchema.PureBlack,
    divider: colorSchema.Gray7,
  },
  tabs: {
    selectedBorder: colorSchema.PureBlack,
    selectedText: colorSchema.PureBlack,
    text: colorSchema.Gray7,
    border: colorSchema.Gray1,
  },
  paymentHistory: {
    bg: colorSchema.PureWhite,
  },
  paymentData: {
    bg: colorSchema.PureWhite,
    subtitle: colorSchema.PureBlack,
  },
  datatableHeader: {
    label: colorSchema.Gray5,
    headerBg: colorSchema.Gray1,
  },
  datatablePagination: {
    total: colorSchema.Gray5,
    navigation: colorSchema.PureBlack,
  },
  datatableBody: {
    border: colorSchema.Gray5,
    text: colorSchema.PureBlack,
    switchDisabled: colorSchema.Gray2,
    switchEnabled: colorSchema.PureBlack,
    thumb: colorSchema.PureWhite,
  },
  entitiesPageTitle: {
    labelColor: colorSchema.PureBlack,
    bgColor: colorSchema.Gray2,
  },
  errorMessage: colorSchema.Error,
  successMessage: colorSchema.Success,
  uploadComponent: {
    bg: colorSchema.Gray2,
    plusBg: colorSchema.PureBlack,
  },
  searchableInput: {
    bg: colorSchema.PureWhite,
    item: colorSchema.PureBlack,
    hover: colorSchema.Gray1,
  },
  select: {
    labelColor: colorSchema.PureBlack,
    border: colorSchema.Gray2,
    color: colorSchema.Gray8,
  },
  createProduct: {
    add: colorSchema.Gray5,
  },
};
