import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity } from '@/api/entities';
import { Routes } from '@/navigation/routes';
import locale from '@/internationalization';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import { useGetPeriods, useUpdatePeriod } from '@/api/period';
import { Period } from '@/types/period';

export const useSystemPeriodList = (entity: string) => {
  const { goToEntityPage } = useNavigate();
  const queryClient = useQueryClient();

  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: periods, isLoading: loading } = useGetPeriods(entity, !!entity);

  const [periodList, setPeriodList] = useState<any[]>([]);

  const goToEdit = (idSector: string) => {
    const index = periodList.findIndex((c) => c.id === idSector);
    const { id } = periodList[index];
    goToEntityPage(Routes.System, {
      params: {
        entity,
        id,
      },
      screen: Routes.periodCreate,
    });
  };

  const formatPeriodsList = (list: Period[]) => {
    const listFormatted = list.map((period) => ({
      id: period.id,
      isActive: {
        label: period.isActive ? locale.t('active') : locale.t('inactive'),
        value: period.isActive,
      },
      period: period.period,
      time: period.time.slice(0,5),
      manager: '',
      description: period.description,
    }));

    setPeriodList(listFormatted);
  };

  const mutatorUpdate = useUpdatePeriod();

  const updateStatus = async (val: boolean, idPeriod: string) => {
    const index = periodList.findIndex((c) => c.id === idPeriod);
    if (periods[index]) {
      const periodData = periods[index] as Period;

      await mutatorUpdate.mutateAsync({
        id: idPeriod,
        period: periodData.period,
        time: periodData.time,
        isActive: val,
        entity: periodData.entity,
        description: periodData.description,
      });

      await queryClient.invalidateQueries('useGetPeriods');
    }
  };

  useEffect(() => {
    if (periods?.length && !loading) {
      formatPeriodsList(periods);
    }
  }, [periods]);

  return {
    adminData,
    loading,
    periodList,
    entityData,
    updateStatus,
    goToEdit,
  };
};
