import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { Select } from 'antd';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { Paper } from '@/components/paper';
import { colors, dimensions, Images } from '@/consts';
import { ProgressBar } from '@/components/progressbar';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Subheader } from '@/components/subheader';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { PageStyles } from '@/styles/common/PageStyles';
import { Styles } from './ClientCreatePage.styles';
import { ClientTabs } from '@/components/clienttabs';
import { ClientCreatePageTypes } from './ClientCreatePage.types';
import { useClientCreate } from './ClientCreatePage.functions';
import { UploadComponent } from '@/components/uploadcomponent';
import { ErrorHandler } from '@/components/errorhandler';
import { Routes } from '@/navigation/routes';

export const ClientCreatePage = ({
  route: {
    params: { entity, id },
  },
}: ClientCreatePageTypes) => {
  const {
    adminData,
    entityData,
    loading,
    responsibleSearchList,
    responsibleId,
    onSetName,
    nameError,
    name,
    step3,
    step1,
    step2,
    password,
    changeConfirmPassword,
    confirmPassword,
    changePassword,
    picture,
    imageCache,
    email,
    emailError,
    identity,
    setIdentity,
    hasAPIError,
    APIErrorMessage,
    isEditing,
    onChangeSelect,
    goBack,
    goToStep1,
    goToStep2,
    goToStep3,
    submit,
    onImagePick,
  } = useClientCreate(entity, id || '');
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.ClientProfiles}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('clientCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <ClientTabs entityId={entity} create profiles={false} />
          <View style={[PaperColumns.tableContainerForm]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.columnForm}>
                  <View style={PageStyles.imageWrapper}>
                    <img
                      src={Images.AdminCreateIcon}
                      alt={locale.t('imagesAlt.new')}
                    />
                  </View>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('clientCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('clientCreate.content')}
                  </Text>
                </View>
                <View style={PaperColumns.columnFormRight}>
                  <View style={Styles.progressbarContainer}>
                    <ProgressBar
                      width={dimensions.paperWidth / 2 - 70}
                      step1={step1}
                      step2={step2}
                      step3={step3}
                      steps={3}
                    />
                  </View>
                  <View style={PageStyles.subtitleHeader}>
                    {step1 && !step2 && !step3 && (
                      <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                        {locale.t('clientCreate.basicInfo')}
                      </Text>
                    )}
                    {step1 && step2 && !step3 && (
                      <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                        {locale.t('clientCreate.password')}
                      </Text>
                    )}
                    {step1 && step2 && step3 && (
                      <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                        {locale.t('clientCreate.picture')}
                      </Text>
                    )}
                    {step1 && !step2 && !step3 && (
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('clientCreate.cancel')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goBack}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('clientCreate.next')}
                            disabled={
                              nameError ||
                              name.trim() === '' ||
                              identity.trim() === '' ||
                              loading
                            }
                            onPress={goToStep2}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    )}
                    {step1 && step2 && !step3 && (
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('clientCreate.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep1}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('clientCreate.next')}
                            disabled={
                              (email.trim() === '' ||
                                emailError ||
                                loading ||
                                password.trim() === '' ||
                                confirmPassword.trim() === '' ||
                                password.trim() !== confirmPassword.trim()) &&
                              !isEditing
                            }
                            onPress={goToStep3}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    )}
                    {step1 && step2 && step3 && (
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('clientCreate.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep2}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('clientCreate.register')}
                            disabled={
                              (loading || imageCache.trim() === '') &&
                              !isEditing
                            }
                            onPress={submit}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  {step1 && !step2 && !step3 && (
                    <View style={Styles.inputContainer}>
                      <Text style={Styles.content}>
                        {locale.t('clientCreate.content1')}
                      </Text>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('clientCreate.form.name')}
                            value={name}
                            onChangeText={onSetName}
                            hasError={nameError}
                            height={42}
                            borderRadius={14}
                          />
                          {nameError && (
                            <Text style={Styles.errorText}>
                              {locale.t('clientCreate.form.nameError')}
                            </Text>
                          )}
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('clientCreate.form.identity')}
                            value={identity}
                            onChangeText={setIdentity}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[
                            PageStyles.inputWrapper,
                            {
                              width: '46%',
                              marginRight: 10,
                            },
                          ]}>
                          <Text style={Styles.textLabel}>
                            {locale.t('clientCreate.form.search')}
                          </Text>
                          <View style={Styles.selectContainer}>
                            <Select
                              mode="multiple"
                              allowClear
                              bordered={false}
                              maxTagCount="responsive"
                              value={responsibleId}
                              filterOption={(inputValue, option) =>
                                option?.label
                                  .toLowerCase()
                                  .indexOf(inputValue.toLowerCase()) !== -1
                              }
                              onChange={onChangeSelect}
                              options={responsibleSearchList}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && !step3 && (
                    <View style={Styles.inputContainer}>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('clientCreate.form.email')}
                            value={email}
                            disabled
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('clientCreate.form.password')}
                            value={password}
                            onChangeText={changePassword}
                            secureTextEntry
                            isPassword
                            maxLength={4}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t(
                              'clientCreate.form.confirmPassword',
                            )}
                            value={confirmPassword}
                            onChangeText={changeConfirmPassword}
                            secureTextEntry
                            isPassword
                            maxLength={4}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && step3 && (
                    <View style={Styles.inputsContainerStep3}>
                      <Text style={Styles.content}>
                        {locale.t('imageContent')}
                      </Text>
                      <View>
                        <UploadComponent
                          onFileSelect={onImagePick}
                          uri={imageCache || picture}
                        />
                      </View>
                    </View>
                  )}
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default ClientCreatePage;
