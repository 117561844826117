import { useEffect, useState } from 'react';
import { NavigationState } from '@react-navigation/native';
import { LocalStorage } from '../../services/localStorage';
import { ADMIN, NAVIGATION_STATE } from '../../consts/storageKeys';

export const useMain = () => {
  const [isReady, setIsReady] = useState(false);
  const [initialState, setInitialState] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const isUserAuthenticated = async () => {
    const client = await LocalStorage.get(ADMIN);
    setIsAuthenticated(client !== null);
  };

  const restoreState = async () => {
    try {
      const savedStateString = await LocalStorage.get(NAVIGATION_STATE);
      const state = savedStateString ? JSON.parse(savedStateString) : undefined;

      if (state !== undefined) {
        setInitialState(state);
      }
    } finally {
      setIsReady(true);
    }
  };

  const onStatusChange = async (state: NavigationState | undefined) => {
    await LocalStorage.save(NAVIGATION_STATE, state);
  };

  useEffect(() => {
    (async () => {
      await isUserAuthenticated();
      if (!isReady) {
        await restoreState();
      }
    })();
  }, [isReady]);

  return {
    isReady, initialState, isAuthenticated, onStatusChange,
  };
};
