import { useNavigation } from '@react-navigation/native';
import { RegisterStep2RouteParams } from '../RoutesParams';
import { Routes } from '../index';

export const useNavigateRegisterStep2 = () => {
  const navigation = useNavigation();

  const goToStep2 = (params: RegisterStep2RouteParams) => {
    navigation.navigate(Routes.Register2, params);
  };

  return { goToStep2 };
};

export default useNavigateRegisterStep2;
