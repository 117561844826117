import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AdminRole, AdminType } from '@/types';
import { useGetAdmin, useGetAdminId, useUpdateAdmin, useGetEntities } from '@/api/admin';
import {
  applyPhoneNumberMask, emailIsValid, genAuthCode, phoNumberRemoveMask,
} from '@/utils/strUtils';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { useUserSignup } from '@/api/signup';
import locale from '@/internationalization';
import { Routes } from '@/navigation/routes';
import { useGetEntity } from '@/api/entities';
import { Entity } from '@/types/entity';

export const useAdministrationCreate = (entity: string, id: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: editedAdmin } = useGetAdmin(id, !!id);
  const { data: entities } = useGetEntities(adminId, !!adminId);

  const { goBack, goToEntityPage } = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [adminType, setAdminType] = useState<AdminType>(AdminType.Owner);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [allEntities, setAllentities] = useState<Entity[]>([]);

  const [authorizationCode, setAuthorizationCode] = useState(genAuthCode());

  const [loading, setLoading] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);

  const queryClient = useQueryClient();
  const mutator = useUserSignup();
  const mutatorUpdate = useUpdateAdmin();

  const goToStep2 = () => {
    setStep1(true);
    setStep2(true);
  };

  const goToStep1 = () => {
    setStep1(true);
    setStep2(false);
  };

  const emailChange = (e: string) => {
    setEmail(e);
    setEmailError(emailIsValid(e));
  };

  const createAdmin = () => mutator.mutateAsync({
    name,
    email,
    password,
    adminType,
    authorizationCode,
    phone: `+55${phoNumberRemoveMask(phone)}`,
    entities: selectedEntities,
    username: email,
    role: AdminRole.Admin,
  });

  const updateAdmin = (i: string) => mutatorUpdate.mutateAsync({
    id: i,
    name,
    email,
    password,
    adminType,
    authorizationCode,
    entities: selectedEntities,
    phone: `+55${phoNumberRemoveMask(phone)}`,
    user: password ? editedAdmin?.user.id : '',
  });

  const submit = async () => {
    setLoading(true);
    try {
      const result = id ? await updateAdmin(id) : await createAdmin();
      const data = await result.json();

      if (result.status !== 201 && result.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        const err = data.code ? locale.t(`errors.${data.code}`) : locale.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }

      setLoading(false);

      await queryClient.invalidateQueries('entityAdmins');
      goToEntityPage(
        Routes.Administration,
        { params: { entity }, screen: Routes.AdministrationProfiles },
      );
    } catch (e) {
      console.error('useAdministrationCreate.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setLoading(false);
    }
  };

  const generateAuthorizationCode = () => {
    setAuthorizationCode(genAuthCode());
  };

  useEffect(() => {
    goToStep1();
  }, []);

  useEffect(() => {
    if (adminData?.adminType?.type === 'super_admin') {
      setAdminType(AdminType.Manager);
    }
  }, [adminData]);

  useEffect(() => {
    if (editedAdmin) {
      setName(editedAdmin?.name);
      setEmail(editedAdmin?.email);
      setAdminType(editedAdmin?.adminType.type);
      setIsEditing(true);
      setPhone(applyPhoneNumberMask(editedAdmin?.phone));
      setAuthorizationCode(editedAdmin?.authorizationCode || genAuthCode());
      
      // Preencher selectedEntities com as entidades associadas ao administrador
      const entityIds = editedAdmin.entities.map((ent: { id: any; }) => ent.id); // Supondo que as entidades tenham a propriedade 'id'
      setSelectedEntities(entityIds);
    }
  }, [editedAdmin]);

  useEffect(() => {
    if (entityData?.id) {
      setSelectedEntities((prev) => {
        if (!prev.includes(entityData.id)) {
          return [...prev, entityData.id]; 
        }
        return prev;
      });
    }
  }, [entityData]);
  
  useEffect(() => {
    if (entities) {
      setAllentities(entities);
    }
  }, [entities]);
  
  return {
    adminData,
    name,
    email,
    phone,
    password,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    setName,
    setPhone,
    step1,
    step2,
    emailError,
    adminType,
    setAdminType,
    loading,
    APIErrorMessage,
    hasAPIError,
    isEditing,
    entityData,
    emailChange,
    goBack,
    goToStep1,
    goToStep2,
    submit,
    authorizationCode,
    generateAuthorizationCode,
    selectedEntities,
    setSelectedEntities,
    allEntities,
  };
};
