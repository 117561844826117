import React from 'react';
import {
  Image, Text, TouchableOpacity, View,
} from 'react-native';
import { MFullWidthButton } from 'menupass-ui';
import { Container } from '@/components/container';
import { Header } from '@/components/header';
import { PageTitle } from '@/components/pagetitle';
import locale from '@/internationalization';
import { useHome } from './Home.functions';
import { Paper } from '@/components/paper';
import { Styles } from './home.styles';
import { EntityCard } from '@/components/entitycard';
import { colors, Images } from '@/consts';
import { colorSchema } from '@/consts/colors';
import { Entity } from '@/types/entity';
import { Datatable } from '@/components/datatable';
import { homeListHeaders } from './Home.consts';
import { PaperColumns } from '@/styles/common/PaperColumns';

export const Home = () => {
  const {
    adminData, rows, loading, goToEntityInternal, goToAddEntity, editEntity, show, onEdit, onShowTable,
    entitiesList, goToAccess,
  } = useHome();
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Container>
        <PageTitle title={locale.t('home.title')} />
        <Paper disableInternalPadding={!!onShowTable} bgColor={onShowTable ? 'transparent' : colorSchema.Gray1Half} withScroll loading={loading}>
          {!loading && onShowTable && (
            <View style={[PaperColumns.tableContainer]}>
              <Datatable
                headers={homeListHeaders}
                data={entitiesList}
                loading={loading}
                access={goToAccess}
                searchFields={['name']}
              />
            </View>
          )}
          {!loading && rows.length && !onShowTable && (
            <>
              {rows.map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <View style={Styles.row} key={`${index}_row`}>
                  {row.map((item: Entity, itemIndex: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <EntityCard
                      style={item.id ? Styles.filledCardBg : null}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${itemIndex}_item`}>
                      {item.enableAddButton && show ? (
                        <TouchableOpacity
                          activeOpacity={0.7}
                          style={Styles.addBtn}
                          onPress={() => goToAddEntity()}>
                          <img
                            src={Images.PlusIcon}
                            alt={locale.t('imagesAlt.add')}
                          />
                        </TouchableOpacity>
                      ) : (
                        <>
                          {item.id && (
                            <View>
                              <View style={Styles.logoWrapper}>
                                <Image
                                  source={{ uri: item.logo }}
                                  style={Styles.logo}
                                />
                              </View>
                              <View>
                                <Text style={Styles.name}>{item.name}</Text>
                              </View>
                              <View style={Styles.actionButtonsWrapper}>
                                {onEdit && (
                                  <View style={Styles.actionButton}>
                                    <MFullWidthButton
                                      label={locale.t('edit')}
                                      onPress={() => editEntity(item.id || '')}
                                      disabled={!item.id}
                                      height={36}
                                      bgColor={colors.home.editBg}
                                      labelColor={colors.home.editText}
                                      fontSize={14}
                                    />
                                  </View>
                                )}

                                <View style={Styles.actionButton}>
                                  <MFullWidthButton
                                    label={locale.t('access')}
                                    onPress={() =>
                                      goToEntityInternal(item.id || '')
                                    }
                                    disabled={!item.id}
                                    height={36}
                                  />
                                </View>
                              </View>
                            </View>
                          )}
                        </>
                      )}
                    </EntityCard>
                  ))}
                </View>
              ))}
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Home;
