import { useState } from 'react';
import { useSendVerificationCode } from '@/common/hooks/useSendVerificationCode';
import useNavigateRecoveryPassword2 from '@/navigation/routes/hooks/useNavigateRecoveryPassword2';
import { encodeRecoveryPassword } from '@/utils/strUtils';

export const VERIFICATION_CODE_TIME = 45;

export const useRecoveryPassword = () => {
  const [phone, setPhone] = useState('');

  const {
    countDown,
    isDisabledByCuntDown,
    code,
    isPhoneNumberValid,
    onChangeCode,
  } = useSendVerificationCode(phone);

  const { goToRecoveryPassword } = useNavigateRecoveryPassword2();

  const sendRecoveryPasswordCode = () => {
    countDown.start();
  };

  const goToStep2 = () => {
    const hash = encodeRecoveryPassword(code, phone);
    goToRecoveryPassword({ hash });
  };

  return {
    phone,
    countDown,
    isDisabledByCuntDown,
    code,
    isPhoneNumberValid,
    goToStep2,
    onChangeCode,
    sendRecoveryPasswordCode,
    setPhone,
  };
};

export default useRecoveryPassword;
