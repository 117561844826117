import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import locale from '@/internationalization';
import { colors } from '@/consts';
import { Routes } from '@/navigation/routes';
import { CategoryTabsTypes } from './CategoryTabs.types';
import { categoryTabsStyles } from './CategoryTabs.styles';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';

export const CategoryTabs = ({ entityId, list, create }: CategoryTabsTypes) => {
  const { goToEntityPage } = useNavigate();
  return (
    <View style={categoryTabsStyles.container}>
      <TouchableOpacity
        style={[
          categoryTabsStyles.btnContainer,
          { borderColor: list ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => goToEntityPage(
          Routes.Category,
          { params: { entity: entityId }, screen: Routes.CategoryList },
        )}
      >
        <Text
          style={[
            categoryTabsStyles.btnText,
            { color: list ? colors.tabs.selectedText : colors.tabs.text },
          ]}
        >
          {locale.t('categoryTabs.list')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          categoryTabsStyles.btnContainer,
          { borderColor: create ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => goToEntityPage(
          Routes.Category,
          { params: { entity: entityId }, screen: Routes.CategoryCreate },
        )}
      >
        <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <Path
            d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
            fill={create ? colors.tabs.selectedText : colors.tabs.text}
          />
        </Svg>
        <Text style={[categoryTabsStyles.btnText, {
          marginLeft: 6,
          color: create ? colors.tabs.selectedText : colors.tabs.text,
        }]}
        >
          {locale.t('categoryTabs.create')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default CategoryTabs;
