import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import locale from '@/internationalization';
import { colors } from '@/consts';
import { Routes } from '@/navigation/routes';
import { responsibleStyles } from './ResponsibleTabs.styles';
import { ResponsibleTabsTypes } from './ResponsibleTabs.types';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';

export const ResponsibleTabs = ({
  entityId,
  create,
  profiles,
}: ResponsibleTabsTypes) => {
  const { goToEntityPage } = useNavigate();
  return (
    <View style={responsibleStyles.container}>
      <TouchableOpacity
        style={[
          responsibleStyles.btnContainer,
          { borderColor: profiles ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => goToEntityPage(Routes.Responsible, {
          params: { entity: entityId },
          screen: Routes.ResponsibleProfiles,
        })}
      >
        <Text
          style={[
            responsibleStyles.btnText,
            { color: profiles ? colors.tabs.selectedText : colors.tabs.text },
          ]}
        >
          {locale.t('responsibleTabs.profiles')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          responsibleStyles.btnContainer,
          { borderColor: create ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => goToEntityPage(Routes.Responsible, {
          params: { entity: entityId },
          screen: Routes.ResponsibleCreate,
        })}
      >
        <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <Path
            d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
            fill={create ? colors.tabs.selectedText : colors.tabs.text}
          />
        </Svg>
        <Text style={[responsibleStyles.btnText, {
          marginLeft: 6,
          color: create ? colors.tabs.selectedText : colors.tabs.text,
        }]}
        >
          {locale.t('responsibleTabs.create')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default ResponsibleTabs;
