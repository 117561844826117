import { useEffect, useRef, useState } from 'react';
import { decodeRecoveryPassword, phoNumberRemoveMask } from '@/utils/strUtils';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import { useUpdatePassword } from '@/api/admin';
import locale from '@/internationalization';
import { Routes } from '@/navigation/routes';

export const useRecoveryPassword2Functions = (hash: string) => {
  const code = useRef<string>('');
  const phone = useRef<string>('');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const mutator = useUpdatePassword();

  const { goBack, navigate } = useNavigate();

  const submitUpdatePassword = async () => {
    try {
      setIsLoading(true);
      const response = await mutator.mutateAsync({
        password,
        phone: `+55${phoNumberRemoveMask(phone.current)}`,
        code: code.current,
      });

      if (response.status !== 201) {
        setIsLoading(false);
        setApiError(locale.t('recoveryPasswordError'));
        return;
      }

      setIsLoading(false);
      navigate(Routes.Login);
    } catch (e) {
      setIsLoading(false);
      setApiError(locale.t('errors.500'));
    }
  };

  useEffect(() => {
    if (hash) {
      const values = decodeRecoveryPassword(hash);
      code.current = values.code;
      phone.current = values.phone;
    } else {
      goBack();
    }
  }, [hash]);

  return {
    password,
    confirmPassword,
    apiError,
    isLoading,
    setPassword,
    setConfirmPassword,
    submitUpdatePassword,
  };
};

export default useRecoveryPassword2Functions;
