import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MSelect, MTextInput } from 'menupass-ui';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { Paper } from '@/components/paper';
import { colors, dimensions, Images } from '@/consts';
import { ProgressBar } from '@/components/progressbar';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Subheader } from '@/components/subheader';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { PageStyles } from '@/styles/common/PageStyles';
import { Styles } from './AdministrationCreatePage.styles';
import { AdministrationTabs } from '@/components/administrationtabs';
import { AdministrationCreatePageTypes } from './AdministrationCreatePage.types';
import { useAdministrationCreate } from './AdministrationCreatePage.functions';
import { AdminType } from '@/types';
import {
  AdminTypeManager,
  AdminTypeSelect,
} from './AdministrationCreatePage.consts';
import { ErrorHandler } from '@/components/errorhandler';
import { Routes } from '@/navigation/routes';
import { colorSchema } from '@/consts/colors';
import MultiSelectDropdown from '../../components/entityselect/EntitySelect';

export const AdministrationCreatePage = ({
  route: {
    params: { entity, id },
  },
}: AdministrationCreatePageTypes) => {
  const {
    adminData,
    setName,
    setPhone,
    phone,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    password,
    name,
    email,
    step1,
    step2,
    emailError,
    setAdminType,
    adminType,
    loading,
    hasAPIError,
    APIErrorMessage,
    isEditing,
    entityData,
    authorizationCode,
    generateAuthorizationCode,
    emailChange,
    goBack,
    goToStep1,
    goToStep2,
    submit,
    selectedEntities,
    setSelectedEntities,
    allEntities,
  } = useAdministrationCreate(entity, id || '');

  console.log("AllEntities state:", allEntities);

  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.AdministrationProfiles}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('administrationCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <AdministrationTabs entityId={entity} profiles={false} create />
          <View style={[PaperColumns.tableContainerForm]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.columnForm}>
                  <View style={PageStyles.imageWrapper}>
                    <img
                      src={Images.AdminCreateIcon}
                      alt={locale.t('imagesAlt.new')}
                    />
                  </View>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('administrationCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('administrationCreate.content')}
                  </Text>
                </View>
                <View style={PaperColumns.columnFormRight}>
                  <View style={Styles.progressbarContainer}>
                    <ProgressBar
                      steps={2}
                      width={dimensions.paperWidth / 2 - 70}
                      step1={step1}
                      step2={step2}
                    />
                  </View>
                  <View style={PageStyles.subtitleHeader}>
                    <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                      {locale.t('administrationCreate.basicInfo')}
                    </Text>
                    {step1 && !step2 && (
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('administrationCreate.cancel')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goBack}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('administrationCreate.next')}
                            disabled={
                              name.trim() === '' ||
                              email.trim() === '' ||
                              phone.trim() === '' ||
                              phone.length !== 15 ||
                              adminType.trim() === '' ||
                              emailError ||
                              loading
                            }
                            onPress={goToStep2}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    )}
                    {step1 && step2 && (
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('administrationCreate.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep1}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('administrationCreate.register')}
                            disabled={
                              (password.trim() === '' ||
                                confirmPassword.trim() === '' ||
                                password.trim() !== confirmPassword.trim() ||
                                loading) &&
                              !isEditing
                            }
                            onPress={submit}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  {step1 && !step2 && (
                    <View style={Styles.inputContainer}>
                      <Text style={Styles.content}>
                        {locale.t('administrationCreate.content1')}
                      </Text>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('administrationCreate.form.name')}
                            value={name}
                            onChangeText={setName}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          {adminData?.adminType?.type === 'owner' ||
                          adminData?.role === 'menupass_super' ? (
                            <MSelect
                              onValueChange={(value: string) =>
                                setAdminType(value as AdminType)
                              }
                              selectedValue={adminType}
                              values={AdminTypeSelect}
                              label={locale.t('administrationCreate.form.role')}
                              height={42}
                              borderRadius={14}
                            />
                          ) : (
                            <MSelect
                              onValueChange={(value: string) =>
                                setAdminType(value as AdminType)
                              }
                              selectedValue={adminType}
                              values={AdminTypeManager}
                              label={locale.t('administrationCreate.form.role')}
                              height={42}
                              borderRadius={14}
                            />
                          )}
                        </View>
  
                      </View>
                      <View style={PageStyles.formRow}>

                      <View style={[PageStyles.inputWrapper, { width: '100%' }]}>
      <MultiSelectDropdown
        label="Selecione as Entidades"
        selectedValues={selectedEntities}
        values={allEntities.map((ent) => ({ label: ent.name, value: ent.id }))}
        onValueChange={setSelectedEntities}
      />
  </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('administrationCreate.form.email')}
                            value={email}
                            onChangeText={emailChange}
                            hasError={emailError}
                            keyboardType="email-address"
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('administrationCreate.form.phone')}
                            mask="(00) 00000-0000"
                            value={phone}
                            onChangeText={setPhone}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('authorization_code')}
                            disabled
                            value={authorizationCode}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <View style={Styles.buttonWrapper}>
                            <MFullWidthButton
                              label={locale.t('generate_new_code')}
                              onPress={generateAuthorizationCode}
                              height={36}
                              bgColor={colorSchema.Gray2}
                              labelColor={colorSchema.PureBlack}
                              fontSize={14}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && (
                    <View style={Styles.inputContainer}>
                      <Text style={Styles.content}>
                        {locale.t('administrationCreate.content1Step2')}
                      </Text>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t(
                              'administrationCreate.form.password',
                            )}
                            value={password}
                            onChangeText={setPassword}
                            secureTextEntry
                            isPassword
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t(
                              'administrationCreate.form.confirmPassword',
                            )}
                            value={confirmPassword}
                            onChangeText={setConfirmPassword}
                            secureTextEntry
                            isPassword
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default AdministrationCreatePage;
