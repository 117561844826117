import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId, useUpdateAdmin } from '../../api/admin';
import { useGetEntity, useGetEntityAdmins } from '../../api/entities';
import { Admin } from '../../types/admin';
import { AdminType } from '../../types';
import locale from '../../internationalization';
import { useNavigate } from '../../navigation/routes/hooks/useNavigate';
import { Routes } from '../../navigation/routes';

export const useAdministrationProfiles = (entity: string) => {
  const { goToEntityPage } = useNavigate();
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: admins, isLoading: loading } = useGetEntityAdmins(entity, !!entity);

  const [adminList, setAdminList] = useState<any[]>([]);

  const queryClient = useQueryClient();
  const mutator = useUpdateAdmin();

  const translateAdminType = (type: AdminType) => {
    switch (type) {
      case AdminType.Admin:
        return locale.t('administration.adminType.admin');
      case AdminType.Manager:
        return locale.t('administration.adminType.manager');
      case AdminType.Owner:
        return locale.t('administration.adminType.owner');
      default:
        return '';
    }
  };

  const formatAdminsList = (list: Admin[]) => {
    const listFormatted = list.map((admin) => ({
      id: admin.id,
      name: admin.name,
      adminType: translateAdminType(admin.adminType.type),
      email: admin.email,
      isActive: {
        label: admin.user.isActive ? locale.t('active') : locale.t('inactive'),
        value: admin.user.isActive,
      },
      authorizationCode: admin.authorizationCode,
      manage: '',
    }));

    setAdminList(listFormatted);
  };

  const updateStatus = async (val: boolean, idAdmin: string) => {
    const index = adminList.findIndex((c) => c.id === idAdmin);
    if (admins[index]) {
      const admin = admins[index] as Admin;
      const preparedData = {
        user: admin.user.id,
        isActive: val,
        id: admin.id,
        name: admin.name,
        adminType: admin.adminType.type,
        email: admin.email,
        phone: admin.phone,
      };

      await mutator.mutateAsync(preparedData);
      await queryClient.invalidateQueries('entityAdmins');
    }
  };

  const goToEdit = (idAdmin: string) => {
    const index = adminList.findIndex((c) => c.id === idAdmin);
    const { id } = adminList[index];
    goToEntityPage(Routes.Administration, {
      params: {
        entity,
        id,
      },
      screen: Routes.AdministrationCreate,
    });
  };

  useEffect(() => {
    if (admins?.length && !loading) {
      formatAdminsList(admins);
    }
  }, [admins]);

  return {
    adminData,
    loading,
    adminList,
    entityData,
    updateStatus,
    goToEdit,
  };
};
