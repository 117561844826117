import { DatatableHeader } from '../../components/datatable';
import { ColumnComponents } from '../../components/datatable/header/datatableHeader.types';

export const responsibleProfilesPageHeaders: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'isActive',
    label: 'Status',
    width: '15%',
    renderComponent: ColumnComponents.Switch,
  },
  {
    isOrderResultEnabled: false,
    key: 'name',
    label: 'Nome',
    width: '20%',
  },
  {
    isOrderResultEnabled: false,
    key: 'nationalId',
    label: 'CPF',
    width: '10%',
  },
  {
    isOrderResultEnabled: false,
    key: 'email',
    label: 'Email',
    width: '19%',
  },
  {
    isOrderResultEnabled: false,
    key: 'createdAt',
    label: 'Criado em',
    width: '15%',
  },
  {
    isOrderResultEnabled: false,
    key: 'city',
    label: 'Cidade',
    width: '10%',
  },
  {
    isOrderResultEnabled: false,
    key: 'state',
    label: 'Estado',
    width: '5%',
  },
  {
    isOrderResultEnabled: false,
    key: 'manage',
    label: 'Gerenciar',
    width: '6%',
    renderComponent: ColumnComponents.Edit,
  },
];
