import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '@/navigation/routes';
import { Home } from '@/pages/home';
import { NewEntity } from '@/pages/newentity';
import { System } from '@/navigation/system';
import { Payment } from '@/navigation/payment';
import { ProfilePage } from '@/pages/profile';
import { Plans } from '@/navigation/plans';
import { Administration } from '@/navigation/administration';
import { Responsible } from '@/navigation/responsible';
import { Client } from '@/navigation/client';
import { Product } from '@/navigation/product';
import { Category } from '@/navigation/category';
import { Finance } from '@/navigation/finance';
import { InternalRouteParams } from '@/navigation/routes/RoutesParams';
import Invitations from '@/navigation/invitations/Invitations';
import { Totem } from '@/navigation/totem';
import { Sector } from '../sector';

const Stack = createStackNavigator<InternalRouteParams>();

export const Internal = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.Home}
      component={Home}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.NewEntity}
      component={NewEntity}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Payment}
      component={Payment}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Profile}
      component={ProfilePage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Plan}
      component={Plans}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Administration}
      component={Administration}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Totem}
      component={Totem}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Responsible}
      component={Responsible}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Client}
      component={Client}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Product}
      component={Product}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Category}
      component={Category}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.System}
      component={System}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Finance}
      component={Finance}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Invitations}
      component={Invitations}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.sector}
      component={Sector}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Internal;
