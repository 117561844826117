import locale from '../../internationalization';
import { TotemType } from '@/types/totemType';

export const TotemSelect = [
  {
    label: locale.t('totemCreate.select.kitchen'),
    value: TotemType.Kitchen,
  },
  {
    label: locale.t('totemCreate.select.totem'),
    value: TotemType.Totem,
  },
];
