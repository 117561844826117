import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../consts';
import { typography } from '../../styles';

export const Styles = StyleSheet.create({
  progressbarContainer: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
  },
  subtitle: {
    ...typography.p2,
    fontWeight: '700',
    color: colors.newEntity.subtitle,
    marginBottom: 25,
  },
  imageWrapper: {
    width: 24,
    height: 24,
    marginBottom: 25,
  },
  content: {
    ...typography.p3,
  },
  entitiesContainer: {
    width: 155,
  },
  subtitlePlansHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 25,
  },
  subtitleRegister: {
    ...typography.p2,
    fontWeight: '700',
    color: colors.newEntity.subtitleRegister,
  },
  subtitlePlansHeaderButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 330,
  },
  subtitlePlansHeaderButtonsWrapper: {
    width: 155,
  },
  inputsContainerStep1: {
    maxWidth: 330,
    width: '100%',
  },
  inputsContainerStep2: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
  },
  inputsContainerStep3: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
  },
  planContent: {
    ...typography.p3,
    color: colors.newEntity.subtitle,
    marginBottom: 15,
  },
  inputWrapper: {
    marginBottom: 40,
  },
  addPaymentWrapper: {
    width: 160,
  },
  planWrapper: {
    marginVertical: 20,
  },
  planTitle: {
    ...typography.p2,
    color: colors.newEntity.subtitle,
    fontWeight: '700',
  },
  planSubTitle: {
    ...typography.p2,
    color: colors.newEntity.subtitle,
    fontWeight: '700',
    marginTop: 20,
  },
  planTrialContent: {
    ...typography.p3,
    color: colors.newEntity.subtitle,
    marginTop: 20,
    fontWeight: '400',
  },
  radioWrapper: {
    marginVertical: 15,
  },
  radio: {
    marginTop: 20,
    marginBottom: 15,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: colors.newEntity.divider,
    marginTop: 10,
    marginBottom: 20,
  },
  cancelWrapper: {
    marginTop: 30,
    width: 163,
  },
});
