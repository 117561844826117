import { Routes } from '@/navigation/routes';
import locale from '@/internationalization';
import { InternalEntityPages } from '@/navigation/routes/hooks/useNavigateEntityInternalPages';

export const subheaderList: {
  title: string;
  page: InternalEntityPages;
  selected: boolean;
  screen: Routes;
}[] = [
  {
    title: locale.t('subheader.invitation'),
    page: Routes.Invitations,
    selected: false,
    screen: Routes.InvitationsList,
  },
  {
    title: locale.t('subheader.administration'),
    page: Routes.Administration,
    selected: false,
    screen: Routes.AdministrationProfiles,
  },
  {
    title: locale.t('subheader.totem'),
    page: Routes.Totem,
    selected: false,
    screen: Routes.TotemProfiles,
  },
  {
    title: locale.t('subheader.responsible'),
    page: Routes.Responsible,
    selected: false,
    screen: Routes.ResponsibleProfiles,
  },
  {
    title: locale.t('subheader.users'),
    page: Routes.Client,
    selected: false,
    screen: Routes.ClientProfiles,
  },
  {
    title: locale.t('subheader.categories'),
    page: Routes.Category,
    selected: false,
    screen: Routes.CategoryList,
  },
  {
    title: locale.t('subheader.products'),
    page: Routes.Product,
    selected: false,
    screen: Routes.ProductList,
  },
  {
    title: locale.t('subheader.sectors'),
    page: Routes.sector,
    selected: false,
    screen: Routes.sectorList,
  },
  {
    title: locale.t('subheader.system'),
    page: Routes.System,
    selected: false,
    screen: Routes.SystemConfig,
  },
  {
    title: locale.t('subheader.finance'),
    page: Routes.Finance,
    selected: false,
    screen: Routes.FinanceExtracts,
  },
  // {
  //   title: locale.t('subheader.highlight'),
  //   page: Routes.AdministrationProfiles,
  //   selected: false,
  //   screen: Routes.AdministrationProfiles,
  // },
];
