import { StyleSheet } from 'react-native';
import { typography } from '@/styles';

export const Styles = StyleSheet.create({
  verificationCode: {
    ...typography.p3,
    fontWeight: '700',
    marginTop: 5,
  },
});

export default Styles;
