export enum ColumnComponents {
  Switch = 'Switch',
  Edit = 'Edit',
  Image = 'Image',
  Detalhes = 'Detalhes',
  Password = 'Password',
  access = 'Access',
}

export type DatatableHeader = {
  label: string;
  key: string;
  isOrderResultEnabled?: boolean;
  width?: string;
  renderComponent?: ColumnComponents;
};

export type DatatableHeaderProps = {
  headers: DatatableHeader[];
};
