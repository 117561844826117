import { sectorIsActive } from '@/types/sectorType';
import { SelectedEntityParams } from '../../navigation/routes/RoutesParams';

export type SectorCreatePageTypes = {
  route: SelectedEntityParams
}

export const sectorActiveInactive = [
  {
    label: 'Ativo',
    value: sectorIsActive.Active,
  },
  {
    label: 'Inativo',
    value: sectorIsActive.Inactive,
  },
];
