import React from 'react';
import { Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { LoginRegisterStyle } from '@/styles/common';
import { dimensions, Images } from '@/consts';
import locale from '@/internationalization';
import { styles } from '@/pages/recoverypassword/RecoveryPassword.styles';
import useRecoveryPassword, { VERIFICATION_CODE_TIME } from '@/pages/recoverypassword/RecoveryPassword.functions';

export const RecoveryPassword = () => {
  const {
    phone,
    countDown,
    isDisabledByCuntDown,
    code,
    isPhoneNumberValid,
    onChangeCode,
    setPhone,
    sendRecoveryPasswordCode,
    goToStep2,
  } = useRecoveryPassword();
  return (
    <View style={LoginRegisterStyle.container}>
      <View style={LoginRegisterStyle.imageContainer}>
        <img
          src={Images.Access}
          height={dimensions.height}
          width={dimensions.width * 0.5}
          alt={locale.t('imagesAlt.access')}
        />
      </View>
      <View style={LoginRegisterStyle.formWrapper}>
        <View style={LoginRegisterStyle.formContainer}>
          <Text style={LoginRegisterStyle.steps}>{locale.t('step1')}</Text>
          <Text style={LoginRegisterStyle.title}>{locale.t('recoveryPassword')}</Text>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              value={phone}
              onChangeText={setPhone}
              mask="(00) 00000-0000"
              label={locale.t('phone')}
              placeholder={locale.t('phonePlaceholder')}
              height={42}
              borderRadius={14}
            />
          </View>
          <Text style={styles.confirmationText}>
            {locale.t('sendRecoveryCode')}
          </Text>
          <View style={[
            LoginRegisterStyle.buttonsContainer,
            styles.sendCodeButtonContainer,
            { marginBottom: 30 },
          ]}
          >
            <MFullWidthButton
              onPress={() => sendRecoveryPasswordCode()}
              label={locale.t('sendCode')}
              height={42}
              fontSize={14}
              disabled={
                phone.trim() === ''
                || phone.trim().length !== 15
                || isDisabledByCuntDown
              }
            />
            <Text style={styles.verificationCode}>
              {countDown.currentSecond === 0 ? `00:${VERIFICATION_CODE_TIME}` : `00:${countDown.currentSecond}`}
            </Text>
          </View>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              value={code}
              onChangeText={onChangeCode}
              label={locale.t('recoveryCode')}
              placeholder={locale.t('recoveryCodePlaceholder')}
              height={42}
              borderRadius={14}
            />
          </View>
          <View style={[LoginRegisterStyle.buttonsContainer, { marginBottom: 30 }]}>
            <MFullWidthButton
              onPress={() => goToStep2()}
              label={locale.t('forward')}
              height={42}
              fontSize={14}
              disabled={!isPhoneNumberValid}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default RecoveryPassword;
