import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { useNavigation } from '@react-navigation/native';
import locale from '@/internationalization';
import { typography } from '@/styles';
import { colors } from '@/consts';
import { Routes } from '@/navigation/routes';
import { TabsStates } from './PlanTabs.types';

export const PlanTabs = ({ plansAdd, plans }: TabsStates) => {
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[
          styles.btnContainer,
          { borderColor: plans ? colors.tabs.selectedBorder : colors.tabs.border }]}
        onPress={() => navigation.navigate(Routes.PlanHistory)}
      >
        <Text
          style={[styles.btnText, { color: plans ? colors.tabs.selectedText : colors.tabs.text }]}
        >
          {locale.t('plantTabs.plans')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.btnContainer,
          { borderColor: plansAdd ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => navigation.navigate(Routes.PlanAdd)}
      >
        <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <Path
            d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
            fill={plansAdd ? colors.tabs.selectedText : colors.tabs.text}
          />
        </Svg>
        <Text style={[styles.btnText, {
          marginLeft: 6,
          color: plansAdd ? colors.tabs.selectedText : colors.tabs.text,
        }]}
        >
          {locale.t('plantTabs.plansAdd')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnContainer: {
    paddingHorizontal: 25,
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 3,
    borderColor: colors.tabs.border,
  },
  btnText: {
    ...typography.p3,
    fontWeight: '700',
    color: colors.tabs.text,
  },
});

export default PlanTabs;
