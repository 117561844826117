import React from 'react';
import { View } from 'react-native';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import locale from '@/internationalization';

import { Routes } from '../../navigation/routes';
import { Container } from '@/components/container';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Datatable } from '@/components/datatable';
import { SystemPeriodListPageHeaders } from './SystemPeriodListPage.consts';
import { useSystemPeriodList } from './SystemPeriodListPage.functions';
import { SystemTabs } from '@/components/systemtabs';
import { SystemPeriodListPageTypes } from './SystemPeriodListPage.types';

const SystemPeriodListPage = ({
  route: { params: { entity } },
}: SystemPeriodListPageTypes) => {
  const {
    entityData,
    adminData,
    periodList,
    loading,
    goToEdit,
    updateStatus,
  } = useSystemPeriodList(entity);

  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.periodList}
        role={adminData?.adminType?.type}
      />

      <Container>
        <EntitiesPageTitle
          title={locale.t('system.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <SystemTabs entityId={entity} system={false} periodCreate={false} periodList />
          <View style={[PaperColumns.tableContainer]}>
            <Datatable
              headers={SystemPeriodListPageHeaders}
              data={periodList}
              loading={loading}
              onEditPress={goToEdit}
              onSwitchChange={updateStatus}
              searchFields={['name']}
            />
          </View>
        </Paper>
      </Container>
    </>
  );
};
export default SystemPeriodListPage;
