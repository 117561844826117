import { useEffect, useState } from 'react';

export const useHeader = (role?: string) => {
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const [menupassSuper, setmenupassSuper] = useState(false);

  useEffect(() => {
    setmenupassSuper(role === 'menupass_super');
  }, [role]);

  const logout = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  return {
    logout, menuIsOpened, setMenuIsOpened, menupassSuper,
  };
};
