import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import {
  useGetEntity, useCreateInvitation, useUpdateInvitation, useGetInvitation,
} from '@/api/entities';
import { Roles } from '@/types/roles';
import i18n from '@/internationalization';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import {
  applyPhoneNumberMask, emailIsValid, phoNumberRemoveMask, roleTranslator,
} from '@/utils/strUtils';
import { Routes } from '@/navigation/routes';
import { useVerificationCode } from '@/api/signup';

export const useInvitationsCreate = (entity: string, id: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: invitation } = useGetInvitation(id, !!id);

  const { goBack, goToEntityPage } = useNavigate();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('');
  const [typeLabel, setTypeLabel] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);

  const queryClient = useQueryClient();

  const rolesList = [
    {
      value: Roles.AdminClient,
      label: i18n.t('admin_client'),
    },
    {
      value: Roles.Staff,
      label: i18n.t('staff'),
    },
  ];

  const [typeList] = useState(rolesList);

  const emailChange = (e: string) => {
    setEmail(e);
    setEmailError(emailIsValid(e));
  };

  const verificationCodeMutator = useVerificationCode();
  const sendInvitationSMS = async (frontEndCode: string) => {
    await verificationCodeMutator.mutateAsync({
      message: i18n.t('invitation_code_request'),
      replacer: i18n.t('invitation_code_request_replacer'),
      to: `+55${phoNumberRemoveMask(phone)}`,
      frontEndCode,
    });
  };

  const mutatorCreate = useCreateInvitation();
  const createInvitation = () => mutatorCreate.mutateAsync({
    name,
    email,
    phone,
    entity,
    role: type,
  });

  const mutatorUpdate = useUpdateInvitation();
  const updateInvitation = () => mutatorUpdate.mutateAsync({
    id,
    name,
    email,
    phone,
    entity,
    role: type,
  });

  const onSubmit = async () => {
    setLoading(true);

    try {
      const result = id ? await updateInvitation() : await createInvitation();
      const data = await result.json();

      if (result.status !== 201 && result.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        const messageErro = 'you are trying to invite a user that already exists but with a different role';
        const err = data.message === messageErro ? i18n.t('errors.ER_DUP_INVITATION') : i18n.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }

      await sendInvitationSMS(data.code);

      setLoading(false);

      await queryClient.invalidateQueries('entityInvitations');
      goToEntityPage(
        Routes.Invitations,
        { params: { entity }, screen: Routes.InvitationsList },
      );
    } catch (e) {
      console.error('useInvitationsCreate.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(i18n.t('errors.500'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (invitation) {
      setName(invitation.name);
      setEmail(invitation.email);
      setType(invitation.role);
      setTypeLabel(roleTranslator(invitation.role) || '');
      setPhone(applyPhoneNumberMask(invitation.phone));
    }
  }, [invitation]);

  return {
    adminData,
    entityData,
    name,
    phone,
    email,
    type,
    typeList,
    typeLabel,
    emailError,
    loading,
    hasAPIError,
    APIErrorMessage,
    goBack,
    onSubmit,
    setType,
    setName,
    setPhone,
    setEmail,
    setTypeLabel,
    emailChange,
  };
};
