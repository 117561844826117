import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import locale from '@/internationalization';
import { colors } from '@/consts';
import { Routes } from '@/navigation/routes';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { InvitationsTabsTypes } from '@/components/invitationstabs/InvitationsTabs.types';
import { styles } from '@/components/invitationstabs/InvitationsTabs.styles';

export const InvitationsTabs = ({
  entityId,
  createIndividual,
  createGeneral,
  list,
}: InvitationsTabsTypes) => {
  const { goToEntityPage } = useNavigate();
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[
          styles.btnContainer,
          {
            borderColor: list ? colors.tabs.selectedBorder : colors.tabs.border,
          },
        ]}
        onPress={() => goToEntityPage(Routes.Invitations, {
          params: { entity: entityId },
          screen: Routes.InvitationsList,
        })}
      >
        <Text
          style={[
            styles.btnText,
            { color: list ? colors.tabs.selectedText : colors.tabs.text },
          ]}
        >
          {locale.t('history')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.btnContainer,
          {
            borderColor: createIndividual
              ? colors.tabs.selectedBorder
              : colors.tabs.border,
          },
        ]}
        onPress={() => goToEntityPage(Routes.Invitations, {
          params: { entity: entityId },
          screen: Routes.InvitationsCreate,
        })}
      >
        <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <Path
            d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
            fill={createIndividual ? colors.tabs.selectedText : colors.tabs.text}
          />
        </Svg>
        <Text
          style={[
            styles.btnText,
            {
              marginLeft: 6,
              color: createIndividual ? colors.tabs.selectedText : colors.tabs.text,
            },
          ]}
        >
          {locale.t('invite')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.btnContainer,
          {
            borderColor: createGeneral
              ? colors.tabs.selectedBorder
              : colors.tabs.border,
          },
        ]}
        onPress={() => goToEntityPage(Routes.Invitations, {
          params: { entity: entityId },
          screen: Routes.InvitationsCreateGeneral,
        })}
      >
        <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <Path
            d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
            fill={createGeneral ? colors.tabs.selectedText : colors.tabs.text}
          />
        </Svg>
        <Text
          style={[
            styles.btnText,
            {
              marginLeft: 6,
              color: createGeneral ? colors.tabs.selectedText : colors.tabs.text,
            },
          ]}
        >
          {locale.t('new_invitation_general')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default InvitationsTabs;
