import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '../../api/admin';
import { useGetEntity } from '../../api/entities';
import { useGetProductCategories, useUpdateProductCategory } from '../../api/categories';
import { Category } from '../../types/category';
import locale from '../../internationalization';
import { Routes } from '../../navigation/routes';
import { useNavigate } from '../../navigation/routes/hooks/useNavigate';

export const useCategoryList = (entity: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: categories, isLoading: loading } = useGetProductCategories(entity, !!entity);

  const { goToEntityPage } = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useUpdateProductCategory();

  const [categoriesDatatable, setCategoriesDatatable] = useState<any[]>([]);

  const formatList = (list: Category[]) => {
    const listFormatted = list.map((c: Category) => ({
      id: c.id,
      isActive: {
        label: c.isActive ? locale.t('active') : locale.t('inactive'),
        value: c.isActive,
      },
      name: c.name,
      picture: c.picture,
      manage: '',
    }));

    setCategoriesDatatable(listFormatted);
  };

  const updateStatus = async (val: boolean, idCategory: string) => {
    const index = categoriesDatatable.findIndex((c) => c.id === idCategory);
    if (categoriesDatatable[index]) {
      const { id, name } = categories[index] as Category;
      await mutation.mutateAsync({
        id,
        name,
        entity,
        isActive: val,
      });

      await queryClient.invalidateQueries('productCategories');
    }
  };

  const goToEdit = (idCategory: string) => {
    const index = categoriesDatatable.findIndex((c) => c.id === idCategory);
    if (categoriesDatatable[index]) {
      const { id } = categoriesDatatable[index];
      goToEntityPage(Routes.Category, {
        params: {
          entity,
          id,
        },
        screen: Routes.CategoryCreate,
      });
    }
  };

  useEffect(() => {
    if (categories?.length && !loading) {
      formatList(categories);
    }
  }, [categories]);

  return {
    entityData,
    adminData,
    loading,
    categoriesDatatable,
    updateStatus,
    goToEdit,
  };
};
