import type { CreateProduct, UpdateProduct } from './dto';
import fetch from '../fetch';

export const createProduct = async (dto: CreateProduct) => fetch('/products', {
  method: 'POST',
  body: JSON.stringify(dto),
});
export const updateProduct = async (dto: UpdateProduct) => fetch(`/products/${dto.id}`, {
  method: 'PUT',
  body: JSON.stringify(dto),
});
export const getProducts = (entity: string) => fetch(`/products/entity/${entity}`).then((result) => result.json());
export const getProduct = (id: string) => fetch(`/products/${id}`).then((result) => result.json());
export const getCategories = (entity: string) => fetch(`/products/categories/${entity}`).then((result) => result.json());
