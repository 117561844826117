export const CurrencyMask = {
  num: {
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: '',
    padFractionalZeros: false,
    normalizeZeros: false,
    radix: ',',
    mapToRadix: ['.'],
  },
};

export const formatOptions = {
  separator: ',',
  prefix: 'R$ ',
  precision: 2,
  delimiter: '.',
};
