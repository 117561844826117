import React from 'react';
import { Text, View } from 'react-native';
import { ErrorHandlerTypes } from './ErrorHandler.types';
import { styles } from './ErrorHandler.styles';

export const ErrorHandler = ({ message }: ErrorHandlerTypes) => (
  <View style={styles.container}>
    <Text style={styles.message}>{message}</Text>
  </View>
);

export default ErrorHandler;
