import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '../../api/admin';
import { useDeleteAdminPaymentMethod, useGetAdminPaymentMethods } from '../../api/payment';
import { PaymentMethod } from '../../types/paymentMethod';

export const usePaymentCards = () => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: paymentMethods } = useGetAdminPaymentMethods(adminId, !!adminId);

  const [defaultCard, setDefaultCard] = useState<PaymentMethod>();
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const mutator = useDeleteAdminPaymentMethod();

  const remove = async () => {
    if (defaultCard?.id) {
      setLoading(true);

      await mutator.mutateAsync(defaultCard.id);
      await queryClient.invalidateQueries(['paymentMethods', adminData.id]);

      setLoading(false);

      window.location.reload();
    }
  };

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      setDefaultCard(paymentMethods[0]);
      setHasPaymentMethod(true);
    }
  }, [paymentMethods]);

  return {
    adminData, defaultCard, hasPaymentMethod, loading, remove,
  };
};
