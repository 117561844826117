import { useRef, useState } from 'react';

type CountdownInForeground = {
  start: () => void;
  stop: () => void;
  reset: () => void;
  currentSecond: number;
};

const useCountdown = (
  seconds: number,
  onFinished?: () => void,
  onStarted?: () => void,
): CountdownInForeground => {
  const intervalTimer = useRef<any>();
  const countdown = useRef<number>(1);
  const [currentSecond, setCurrentSeconds] = useState(seconds);

  const _stop = () => {
    if (intervalTimer.current) {
      clearInterval(intervalTimer.current);
    }
  };

  const _reset = () => {
    _stop();
    setCurrentSeconds(seconds);
  };

  const _onFinished = () => {
    _stop();
    if (onFinished) {
      onFinished();
    }
  };

  const _restart = (newSeconds: number) => {
    _stop();
    countdown.current = 1;
    intervalTimer.current = setInterval(() => {
      const nSec = newSeconds - countdown.current + countdown.current + 1;
      if (nSec <= 0) {
        setCurrentSeconds(0);
        _onFinished();
        return;
      }
      setCurrentSeconds(nSec);
    }, 1000);
  };

  const _start = () => {
    if (onStarted) {
      onStarted();
    }
    _restart(seconds);
  };

  return {
    start: _start,
    stop: _stop,
    reset: _reset,
    currentSecond,
  };
};

export { useCountdown };
