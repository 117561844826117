import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { formatNumber } from 'react-native-currency-input';
import { useGetAdmin, useGetAdminId } from '../../api/admin';
import { useSystemPlans, useUpdatePlan } from '../../api/plans';
import { Plan } from '../../types/plans';
import { currencyFormat, currencyFormatFloat } from '../../utils/strUtils';
import locale from '../../internationalization';
import { useNavigate } from '../../navigation/routes/hooks/useNavigate';
import { formatOptions } from '../../utils/currencyMask';

export const usePlanHistory = () => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: systemPlanList, isLoading: loading } = useSystemPlans();
  const [list, setList] = useState<Plan[]>([]);

  const mutator = useUpdatePlan();
  const { gotoEditPlan } = useNavigate();

  const queryClient = useQueryClient();

  const formatResponse = () => {
    const formattedList = systemPlanList?.map((item: Plan) => ({
      ...item,
      monthlyPrice: formatNumber(currencyFormatFloat(item.monthlyPrice as number), formatOptions),
      annualPrice: formatNumber(currencyFormatFloat(item.annualPrice as number), formatOptions),
      isActive: {
        label: item.isActive ? locale.t('active') : locale.t('inactive'),
        value: item.isActive,
      },
      manage: '',
    }));

    setList(formattedList);
  };

  const updateStatus = async (val: boolean, idHistory: string) => {
    const index = list.findIndex((item) => item.id === idHistory);
    if (list[index]) {
      const plan = list[index];

      const prepareData = {
        ...plan,
        monthlyPrice: currencyFormat(plan.monthlyPrice as string),
        annualPrice: currencyFormat(plan.annualPrice as string),
        id: plan.id as string,
        isActive: val,
      };

      await mutator.mutateAsync(prepareData);

      await queryClient.invalidateQueries('systemPlans');
    }
  };

  const goToEdit = (historyId: string) => {
    const index = list.findIndex((item) => item.id === historyId);
    if (list[index]) {
      const plan = list[index];
      gotoEditPlan(plan);
    }
  };

  useEffect(() => {
    if (systemPlanList?.length) {
      formatResponse();
    }
  }, [systemPlanList]);

  return {
    adminData, list, loading, updateStatus, goToEdit, formatResponse,
  };
};
