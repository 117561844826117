import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MSelect, MTextInput } from 'menupass-ui';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { Paper } from '@/components/paper';
import { colors, dimensions, Images } from '@/consts';
import { ProgressBar } from '@/components/progressbar';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Subheader } from '@/components/subheader';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { PageStyles } from '@/styles/common/PageStyles';
import { Styles } from './TotemCreatePage.styles';
import { TotemCreatePageTypes } from './TotemCreatePage.types';
import { useTotemCreate } from './TotemCreatePage.functions';
import { TotemSelect } from './TotemCreatePage.consts';
import { ErrorHandler } from '@/components/errorhandler';
import { Routes } from '@/navigation/routes';
import { TotemType } from '@/types/totemType';
import { TotemTabs } from '@/components/totemtabs';

export const TotemCreatePage = ({
  route: {
    params: { entity, id },
  },
}: TotemCreatePageTypes) => {
  const {
    adminData,
    setTotemType,
    totemType,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    password,
    email,
    step1,
    step2,
    emailError,
    loading,
    hasAPIError,
    APIErrorMessage,
    isEditing,
    entityData,
    emailChange,
    goBack,
    goToStep1,
    goToStep2,
    submit,
  } = useTotemCreate(entity, id || '');
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.TotemProfiles}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('totemCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <TotemTabs entityId={entity} profiles={false} create />
          <View style={[PaperColumns.tableContainerForm]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.columnForm}>
                  <View style={PageStyles.imageWrapper}>
                    <img
                      src={Images.AdminCreateIcon}
                      alt={locale.t('imagesAlt.new')}
                    />
                  </View>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('totemCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('totemCreate.content')}
                  </Text>
                </View>
                <View style={PaperColumns.columnFormRight}>
                  <View style={Styles.progressbarContainer}>
                    <ProgressBar
                      steps={2}
                      width={dimensions.paperWidth / 2 - 70}
                      step1={step1}
                      step2={step2}
                    />
                  </View>
                  <View style={PageStyles.subtitleHeader}>
                    <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                      {locale.t('totemCreate.basicInfo')}
                    </Text>
                    {step1 && !step2 && (
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('totemCreate.cancel')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goBack}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('totemCreate.next')}
                            disabled={
                              email.trim() === '' ||
                              totemType.trim() === '' ||
                              emailError ||
                              loading
                            }
                            onPress={goToStep2}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    )}
                    {step1 && step2 && (
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('totemCreate.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep1}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('totemCreate.register')}
                            disabled={
                              (password.trim() === '' ||
                                confirmPassword.trim() === '' ||
                                password.trim() !== confirmPassword.trim() ||
                                loading) &&
                              !isEditing
                            }
                            onPress={submit}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  {step1 && !step2 && (
                    <View style={Styles.inputContainer}>
                      <Text style={Styles.content}>
                        {locale.t('totemCreate.content1')}
                      </Text>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('totemCreate.form.email')}
                            value={email}
                            onChangeText={emailChange}
                            hasError={emailError}
                            keyboardType="email-address"
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MSelect
                            onValueChange={(value: string) =>
                              setTotemType(value as TotemType)
                            }
                            selectedValue={totemType}
                            values={TotemSelect}
                            label={locale.t('totemCreate.form.role')}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && (
                    <View style={Styles.inputContainer}>
                      <Text style={Styles.content}>
                        {locale.t('totemCreate.content1Step2')}
                      </Text>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('totemCreate.form.password')}
                            value={password}
                            onChangeText={setPassword}
                            secureTextEntry
                            isPassword
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('totemCreate.form.confirmPassword')}
                            value={confirmPassword}
                            onChangeText={setConfirmPassword}
                            secureTextEntry
                            isPassword
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default TotemCreatePage;
