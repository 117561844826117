import { StyleSheet } from 'react-native';
import { typography } from '../../styles';
import { colors } from '../../consts';

export const Styles = StyleSheet.create({
  forgotPassword: {
    marginVertical: 15,
  },
  forgotPasswordText: {
    ...typography.p4,
    color: colors.login.forgotPassword,
  },
  notRegistered: {
    ...typography.p1,
    color: colors.login.notRegistered,
    marginBottom: 10,
  },
});
