import { StyleSheet } from 'react-native';
import { typography } from '../../styles';
import { colors } from '../../consts';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 30,
  },
  message: {
    ...typography.p1,
    color: colors.errorMessage,
    fontWeight: '400',
  },
});
