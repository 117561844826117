import { StyleSheet } from 'react-native';
import { colorSchema } from '@/consts/colors';
import { typography } from '@/styles';

export const styles = StyleSheet.create({
  inputWarpper: {
    height: 42,
    borderRadius: 14,
    borderWidth: 1,
    borderColor: colorSchema.Gray2,
    justifyContent: 'center',
    paddingTop: 5,
  },
  textLabel: {
    marginBottom: 5,
    ...typography.p3,
    color: colorSchema.PureBlack,
    fontWeight: '700',
  },
});
