import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { useNavigation } from '@react-navigation/native';
import locale from '@/internationalization';
import { typography } from '@/styles';
import { colors } from '@/consts';
import { Routes } from '@/navigation/routes';

export const PaymentTabs = ({
  paymentAddCard, paymentCards, payments, paymentsData,
}: PaymentTabsTypes) => {
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[
          styles.btnContainer,
          { borderColor: payments ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => navigation.navigate(Routes.PaymentHistory)}
      >
        <Text
          style={[
            styles.btnText,
            { color: payments ? colors.tabs.selectedText : colors.tabs.text },
          ]}
        >
          {locale.t('paymentTabs.payments')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.btnContainer,
          { borderColor: paymentsData ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => navigation.navigate(Routes.PaymentData)}
      >
        <Text
          style={[
            styles.btnText,
            { color: paymentsData ? colors.tabs.selectedText : colors.tabs.text },
          ]}
        >
          {locale.t('paymentTabs.paymentsData')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.btnContainer,
          { borderColor: paymentCards ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => navigation.navigate(Routes.PaymentCards)}
      >
        <Text
          style={[
            styles.btnText,
            { color: paymentCards ? colors.tabs.selectedText : colors.tabs.text },
          ]}
        >
          {locale.t('paymentTabs.cards')}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.btnContainer,
          { borderColor: paymentAddCard ? colors.tabs.selectedBorder : colors.tabs.border },
        ]}
        onPress={() => navigation.navigate(Routes.PaymentAddCard)}
      >
        <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <Path
            d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
            fill={paymentAddCard ? colors.tabs.selectedText : colors.tabs.text}
          />
        </Svg>
        <Text style={[styles.btnText, {
          marginLeft: 6,
          color: paymentAddCard ? colors.tabs.selectedText : colors.tabs.text,
        }]}
        >
          {locale.t('paymentTabs.addCard')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnContainer: {
    paddingHorizontal: 25,
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 3,
    borderColor: colors.tabs.border,
  },
  btnText: {
    ...typography.p3,
    fontWeight: '700',
    color: colors.tabs.text,
  },
});

export default PaymentTabs;
