import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton } from 'menupass-ui';
import { Header } from '../../components/header';
import { Container } from '../../components/container';
import locale from '../../internationalization';
import { PageTitle } from '../../components/pagetitle';
import { Paper } from '../../components/paper';
import { PaymentTabs } from '../../components/paymenttabs';
import { PaperColumns } from '../../styles/common/PaperColumns';
import { Images } from '../../consts';
import { PageStyles } from '../../styles/common/PageStyles';
import { usePaymentCards } from './PaymentCards.functions';
import { Styles } from './paymentCards.styles';

export const PaymentCardsPage = () => {
  const {
    adminData, defaultCard, hasPaymentMethod, loading, remove,
  } = usePaymentCards();
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Container>
        <PageTitle title={locale.t('paymentCards.pageTitle')} />
        <Paper disableInternalPadding bgColor="transparent">
          <PaymentTabs
            paymentCards
            paymentAddCard={false}
            paymentsData={false}
            payments={false}
          />
          <View style={PaperColumns.tableContainer}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.imageWrapper}>
                    <img src={Images.PaymentCardsIcon} alt={locale.t('imagesAlt.cards')} />
                  </View>
                  <Text style={PageStyles.subtitle}>{locale.t('paymentCards.subtitle')}</Text>
                  <Text style={PageStyles.content}>{locale.t('paymentCards.content')}</Text>
                </View>
                <View style={PaperColumns.column}>
                  <Text style={PageStyles.subTitleForm}>{locale.t('paymentCards.form.content')}</Text>
                  <Text style={[PageStyles.subTitleForm, { fontWeight: '700' }]}>
                    {locale.t('paymentCards.subtitle')}
                  </Text>
                  {hasPaymentMethod ? (
                    <View style={Styles.cardContainer}>
                      <View style={Styles.radio} />
                      <Text>{`${locale.t('cardTruncation')} ${defaultCard?.cardLast4Numbers}`}</Text>
                      <View style={PageStyles.buttonsWrapper}>
                        <View style={Styles.buttonWrapper}>
                          <MFullWidthButton
                            label={locale.t('paymentCards.delete')}
                            onPress={remove}
                            disabled={loading}
                            height={36}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    </View>
                  ) : (
                    <Text style={[PageStyles.subTitleForm, { opacity: 0.6 }]}>{locale.t('paymentCards.noCards')}</Text>
                  )}
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default PaymentCardsPage;
