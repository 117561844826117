import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '@/navigation/routes';
import { PaymentHistory } from '@/pages/paymenthistory';
import { PaymentDataPage } from '@/pages/paymentdata';
import { PaymentCardsPage } from '@/pages/paymentcards';
import { PaymentAddCardPage } from '@/pages/paymentaddcard';
import { PaymentRouteParams } from '@/navigation/routes/RoutesParams';

const Stack = createStackNavigator<PaymentRouteParams>();

export const Payment = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.PaymentHistory}
      component={PaymentHistory}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.PaymentData}
      component={PaymentDataPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.PaymentCards}
      component={PaymentCardsPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.PaymentAddCard}
      component={PaymentAddCardPage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Payment;
