import fetch from '../fetch';
import { CreatePlanDto, UpdatePlanDto } from './dto';

export const getSystemPlans = () => fetch('/system-plan').then((result) => result.json());
export const getSystemPlansById = (id: string) => fetch(`/system-plan/${id}`).then((result) => result.json());
export const createPlan = (dto: CreatePlanDto) => fetch('/system-plan', {
  method: 'POST',
  body: JSON.stringify(dto),
});
export const updatePlan = (dto: UpdatePlanDto) => fetch(`/system-plan/${dto.id}`, {
  method: 'PUT',
  body: JSON.stringify(dto),
});
