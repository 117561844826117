import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { Header } from '../../components/header';
import { Container } from '../../components/container';
import locale from '../../internationalization';
import { PageTitle } from '../../components/pagetitle';
import { Paper } from '@/components/paper';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { colors, Images } from '@/consts';
import { PageStyles } from '@/styles/common/PageStyles';
import { useProfile } from './ProfilePage.functions';
import { ErrorHandler } from '../../components/errorhandler';
import { SuccessHandler } from '../../components/successhandler';
import { VERIFICATION_CODE_TIME } from '../registerstep2/RegisterStep2.functions';
import { Styles } from './profilePage.styles';

export const ProfilePage = () => {
  const {
    admin,
    name,
    email,
    phone,
    code,
    emailError,
    loading,
    hasAPIError,
    APIErrorMessage,
    password,
    confirmPassword,
    APISuccess,
    apiSuccessMessage,
    shouldValidatePhone,
    countDown,
    isDisabledByCuntDown,
    isPhoneNumberValid,
    sendVerificationCode,
    cancel,
    update,
    setPassword,
    setConfirmPassword,
    setName,
    emailChange,
    onChangePhone,
    onChangeCode,
  } = useProfile();
  return (
    <>
      <Header username={admin?.name} />
      <Container>
        <PageTitle title={locale.t('profile.title')} />
        <Paper disableInternalPadding>
          <View style={PaperColumns.tableContainerForm}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.columnForm}>
                  <View style={PageStyles.imageWrapper}>
                    <img
                      src={Images.ProfileIcon}
                      alt={locale.t('imagesAlt.profile')}
                    />
                  </View>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('profile.subtitle')}
                  </Text>
                  <Text>{locale.t('profile.content')}</Text>
                </View>
                <View style={PaperColumns.columnFormRight}>
                  <View style={PageStyles.subtitleHeader}>
                    <View />
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('profile.cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          onPress={cancel}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('profile.save')}
                          disabled={
                            name.trim() === '' ||
                            email.trim() === '' ||
                            phone.trim() === '' ||
                            phone.trim().length < 15 ||
                            loading ||
                            emailError ||
                            shouldValidatePhone ||
                            !isPhoneNumberValid ||
                            password.trim() !== confirmPassword.trim()
                          }
                          onPress={update}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                    </View>
                  </View>
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  {APISuccess && <SuccessHandler message={apiSuccessMessage} />}
                  <View>
                    <Text style={PageStyles.subTitleForm}>
                      {locale.t('profile.form.content')}
                    </Text>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('profile.form.name')}
                          value={name}
                          onChangeText={setName}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('profile.form.password')}
                          value={password}
                          onChangeText={setPassword}
                          secureTextEntry
                          isPassword
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('profile.form.email')}
                          value={email}
                          onChangeText={emailChange}
                          hasError={emailError}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('profile.form.confirmPassword')}
                          onChangeText={setConfirmPassword}
                          value={confirmPassword}
                          secureTextEntry
                          isPassword
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                    <View />
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('profile.form.phone')}
                          value={phone}
                          onChangeText={onChangePhone}
                          mask="(00) 00000-0000"
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('profile.form.code')}
                          value={code}
                          maxLength={6}
                          onChangeText={onChangeCode}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                    <View>
                      <Text
                        style={[
                          PageStyles.subTitleForm,
                          { marginVertical: 10 },
                        ]}>
                        {locale.t('profile.form.codeContent')}
                      </Text>
                      <View style={[PageStyles.inputWrapper, { width: 155 }]}>
                        <MFullWidthButton
                          label={locale.t('profile.form.sendCode')}
                          disabled={
                            loading ||
                            !shouldValidatePhone ||
                            isDisabledByCuntDown ||
                            phone.trim() === '' ||
                            phone.trim().length < 15
                          }
                          onPress={sendVerificationCode}
                          height={42}
                          fontSize={14}
                        />
                        <Text style={Styles.verificationCode}>
                          {countDown.currentSecond === 0
                            ? `00:${VERIFICATION_CODE_TIME}`
                            : `00:${countDown.currentSecond}`}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default ProfilePage;
