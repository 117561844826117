import { DatatableHeader } from '@/components/datatable';
import { ColumnComponents } from '@/components/datatable/header/datatableHeader.types';

export const SystemPeriodListPageHeaders: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'isActive',
    label: 'Status',
    width: '23%',
    renderComponent: ColumnComponents.Switch,
  },
  {
    isOrderResultEnabled: false,
    key: 'period',
    label: 'Período',
    width: '23%',
  },
  {
    isOrderResultEnabled: false,
    key: 'time',
    label: 'Horário',
    width: '23%',
  },
  {
    isOrderResultEnabled: false,
    key: 'description',
    label: 'Descrição',
    width: '23%',
  },
  {
    isOrderResultEnabled: false,
    key: 'manage',
    label: 'Gerenciar',
    width: '6%',
    renderComponent: ColumnComponents.Edit,
  },
];
