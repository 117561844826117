import React from 'react';
import { View } from 'react-native';
import { Header } from '../../components/header';
import { Container } from '../../components/container';
import { PageTitle } from '../../components/pagetitle';
import locale from '../../internationalization';
import { Paper } from '../../components/paper';
import { PaymentTabs } from '../../components/paymenttabs';
import { Datatable } from '../../components/datatable';
import { headers } from './paymentHistory.consts';
import { PaperColumns } from '../../styles/common/PaperColumns';
import { usePaymentHistory } from './PaymentHistory.functions';

export const PaymentHistory = () => {
  const { adminData } = usePaymentHistory();
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Container>
        <PageTitle title={locale.t('paymentHistory.pageTitle')} />
        <Paper disableInternalPadding bgColor="transparent">
          <PaymentTabs
            paymentCards={false}
            paymentAddCard={false}
            paymentsData={false}
            payments
          />
          <View style={PaperColumns.tableContainer}>
            <Datatable headers={headers} data={[]} searchFields={['name']} />
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default PaymentHistory;
