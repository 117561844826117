import { StyleSheet } from 'react-native';
import { colors } from '../../consts';
import { typography } from '../../styles';

export const clientStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnContainer: {
    paddingHorizontal: 25,
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 3,
    borderColor: colors.tabs.border,
  },
  btnText: {
    ...typography.p3,
    fontWeight: '700',
    color: colors.tabs.text,
  },
});
