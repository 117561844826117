import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { formatNumber } from 'react-native-currency-input';
import { SystemPlanStatusList } from './PlanCreatePage.consts';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { formatOptions } from '@/utils/currencyMask';
import { useCreatePlan, useSystemPlanById, useUpdatePlan } from '@/api/plans';
import {
  currencyFormat,
  currencyFormatFloat,
  stringToBoolean,
} from '@/utils/strUtils';
import locale from '@/internationalization';
import { useGetAdmin, useGetAdminId } from '@/api/admin';

export const usePlanCreate = (id?: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);

  const [name, setName] = useState('');
  const [monthlyPrice, setMonthlyPrice] = useState<number | null>(0);
  const [annualPrice, setAnnualPrice] = useState<number | null>(0);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);
  const [status, setStatus] = useState(SystemPlanStatusList[0].value);
  const [loading, setLoading] = useState(false);

  const [statusList] = useState(SystemPlanStatusList);

  const queryClient = useQueryClient();

  const { data: systemPlan } = useSystemPlanById(id || '', !!id);
  const mutatorCreate = useCreatePlan();
  const mutatorUpdate = useUpdatePlan();

  const { goBack } = useNavigate();

  const onSelectChange = (value: string) => {
    setStatus(value);
  };

  const createNewPlan = () => mutatorCreate.mutateAsync({
    name,
    monthlyPrice: currencyFormat(formatNumber(monthlyPrice || 0, formatOptions)),
    annualPrice: currencyFormat(formatNumber(annualPrice || 0, formatOptions)),
    isActive: stringToBoolean(status),
  });

  const updatePlan = (planId: string) => mutatorUpdate.mutateAsync({
    id: planId,
    name,
    monthlyPrice: currencyFormat(formatNumber(monthlyPrice || 0, formatOptions)),
    annualPrice: currencyFormat(formatNumber(annualPrice || 0, formatOptions)),
    isActive: stringToBoolean(status),
  });

  const submit = async () => {
    if (name.trim() !== '' && annualPrice !== 0 && monthlyPrice !== 0) {
      setLoading(true);
      try {
        const result = id ? await updatePlan(id) : await createNewPlan();

        if (result.status !== 201 && result.status !== 200) {
          setLoading(false);
          setHasAPIError(true);
          setAPIErrorMessage(locale.t('errors.400'));
          return;
        }

        await queryClient.invalidateQueries('systemPlans');
        setLoading(false);
        goBack();
      } catch (e) {
        console.error('usePlanCreate.submit', e);
        setHasAPIError(true);
        setAPIErrorMessage(locale.t('errors.500'));
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (systemPlan && systemPlan.id) {
      setStatus(systemPlan.isActive ? '1' : '0');
      setName(systemPlan.name);
      setAnnualPrice(currencyFormatFloat(systemPlan.annualPrice));
      setMonthlyPrice(currencyFormatFloat(systemPlan.monthlyPrice));
    }
  }, [systemPlan]);

  return {
    adminData,
    statusList,
    name,
    setName,
    status,
    monthlyPrice,
    annualPrice,
    APIErrorMessage,
    hasAPIError,
    loading,
    setAnnualPrice,
    setMonthlyPrice,
    onSelectChange,
    goBack,
    submit,
  };
};
