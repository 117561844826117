import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { InvitationsRouteParams } from '@/navigation/routes/RoutesParams';
import { Routes } from '@/navigation/routes';
import { InvitationsList } from '@/pages/invitationslist';
import { InvitationsCreate } from '@/pages/invitationscreate';
import { InvitationsCreateGeneral } from '@/pages/invitationscreategeneral';

const Stack = createStackNavigator<InvitationsRouteParams>();

const Invitations = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.InvitationsCreate}
      component={InvitationsCreate}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.InvitationsList}
      component={InvitationsList}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.InvitationsCreateGeneral}
      component={InvitationsCreateGeneral}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Invitations;
