import { useEffect, useRef, useState } from 'react';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetAdminEntities, useGetEntitiesAll } from '@/api/entities';
import { emptyEntity, Entity } from '@/types/entity';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { Routes } from '@/navigation/routes';

const MAX_NUMBER_OF_ENTITIES = 8;

export const useHome = () => {
  const entities = useRef<Entity[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState(false);
  const [show, setShow] = useState(false);
  const [onShowTable, setOnShowTable] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [entitiesList, setEntitiesList] = useState<any[]>([]);

  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entitiesResponse, isLoading: loading } = useGetAdminEntities(adminId, !!adminId);
  const { data: entitiesResponseAll, isLoading: loadingg } = useGetEntitiesAll(adminId, !!adminId);

  const { goToEntityPage, navigate, goToEditEntity } = useNavigate();

  const generateEmptyCards = (amount: number): Entity[] => {
    const emptyEntitiesList: Entity[] = [];
    const empty = { ...emptyEntity };
    if (amount > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < amount; i++) {
        emptyEntitiesList.push({
          ...empty,
          enableAddButton: i === 0,
        });
      }
    }

    return emptyEntitiesList;
  };

  const prepareEntities = () => {
    if (entitiesResponse?.length === 0) {
      return [];
    }
    return entitiesResponse?.length ? entitiesResponse?.map((e: Entity) => ({
      ...e,
      enableAddButton: false,
    })) : entitiesResponseAll?.map((e: Entity) => ({
      ...e,
      enableAddButton: false,
    }));
  };

  const generateEntityCardList = () => {
    const preparedEntities = prepareEntities();

    const emptyCardAmount = MAX_NUMBER_OF_ENTITIES - (preparedEntities?.length || 0);
    const emptyList = generateEmptyCards(emptyCardAmount);

    entities.current = preparedEntities?.concat(emptyList);
    setRefreshList(true);
  };

  const prepareRows = () => {
    const half = Math.ceil(entities.current.length / 2);
    const firstHalf = entities.current.splice(0, half);
    const secondHalf = entities.current.splice(-half);

    const rowsList = [firstHalf, secondHalf];

    setRows(rowsList);
  };

  const goToEntityInternal = (entity: string) => {
    if(adminData?.adminType?.type === 'manager') {
      goToEntityPage(Routes.Responsible, {
        params: { entity },
        screen: Routes.ResponsibleProfiles,
      }) 
    }

    if (adminData?.adminType?.type !== 'manager') {
      goToEntityPage(Routes.Administration, {
        params: { entity },
        screen: Routes.AdministrationProfiles,
      })
    }
  };

  const editEntity = (entityId: string) => {
    goToEditEntity({ id: entityId });
  };

  const goToAddEntity = () => {
    navigate(Routes.NewEntity);
  };



  const formatEntitiesList = (list: Entity[]) => {
    const listFormatted = list.map((entity) => ({
      id: entity.id,
      name: entity.name,
      picture: entity.logo,
      access: '',
      manage: '',
    }));

    setEntitiesList(listFormatted);
  };


  const goToAccess = (idEntity: string) => {
    const index = entitiesList.findIndex((c) => c.id === idEntity);
    if (entitiesList[index]) {
      const { id } = entitiesList[index];
      goToEntityInternal(id)
    }
  };

  const onShow = () => {
    if (adminData?.adminType?.type === 'menupass_super') {
      setOnShowTable(true)
      setShow(false);
      return;
    }
    if (adminData?.adminType?.type === 'owner') {
      setShow(true);
      setOnEdit(true);
    }
  };

  useEffect(() => {
    if (entitiesResponse !== undefined && !loading) {
      generateEntityCardList();
      prepareRows();
      onShow();
    }
  }, [entitiesResponse]);

  useEffect(() => {
    if (entitiesResponseAll?.length && !loadingg) {
      onShow();
      generateEntityCardList();
      prepareRows();
      formatEntitiesList(entitiesResponseAll)
    }
  }, [entitiesResponseAll]);

  return {
    adminData,
    loading,
    rows,
    refreshList,
    onShowTable,
    goToAccess,
    goToEntityInternal,
    goToAddEntity,
    editEntity,
    entitiesList,
    loadingg,
    show,
    onEdit,
  };
};
