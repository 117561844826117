import React from 'react';

import { View } from 'react-native';
import { Header } from '@/components/header';
import { Subheader } from '@/components/subheader';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Datatable } from '@/components/datatable';
import {
  productActiveInactive,
  productsListPageTabHeader,
  ascDesc,
} from './ProductsListPage.consts';
import ProductTabs from '@/components/producttabs/ProductTabs';
import { ProductsListPageTypes } from './ProductsListPage.types';
import { useProductsList } from './ProductsListPage.functions';
import { Routes } from '@/navigation/routes';

export const ProductsListPage = ({
  route: {
    params: { entity },
  },
}: ProductsListPageTypes) => {
  const {
    adminData,
    entityData,
    loading,
    productDatatable,
    isActiveInactive,
    orderVal,
    segmentVal,
    goToEdit,
    updateStatus,
    setIsActiveInactive,
    setOrderVal,
    setSegmentVal,
    segmentValues,
  } = useProductsList(entity);

  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.ProductList}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('product.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <ProductTabs entityId={entity} list create={false} />
          <View style={[PaperColumns.tableContainer]}>
            <Datatable
              selectedValue={isActiveInactive}
              selectedOrder={orderVal}
              selectedSegment={segmentVal}
              onValueChange={setIsActiveInactive}
              onOrderChange={setOrderVal}
              onSegmentChange={setSegmentVal}
              showfilterSelect
              values={productActiveInactive}
              orderValues={ascDesc}
              segmentValues={segmentValues}
              headers={productsListPageTabHeader}
              data={productDatatable}
              loading={loading}
              onEditPress={goToEdit}
              onSwitchChange={updateStatus}
              searchFields={['name', 'category', 'price']}
            />
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default ProductsListPage;
