import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { Styles } from './modalLimit.styles';
import { Images } from '@/consts';
import locale from '@/internationalization/';
import { LimitDto } from './modalLimit.types';

export const ModalLimit = ({ limit, onShowLimitsDays, close }: LimitDto) => (
  <View style={Styles.modalVc}>
    <View>
      <TouchableOpacity
        style={Styles.modal}
        onPress={() => close(false)}
      >
        <img src={Images.CloseCross} alt={locale.t('imagesAlt.ClosseCross')} />
      </TouchableOpacity>
    </View>
    <View style={Styles.containerMS}>
      <View style={Styles.cardMonthly}>
        <Text style={Styles.textMonthly}>Limite Mensal</Text>
        <Text>{limit.monthlySpendLimit}</Text>
      </View>
      <View style={Styles.line} />
      <View style={Styles.cardWeekly}>
        <Text style={Styles.textWeekly}>Limite Semanal</Text>
        <Text>{limit.weeklySpendLimit}</Text>
      </View>
    </View>

    <View style={Styles.cardDaily}>
      <Text style={Styles.textDaily}>{onShowLimitsDays ? 'Limite por Dia' : 'Limite Diário'}</Text>
      {!onShowLimitsDays && (
        <Text>{limit.dailySpendLimit}</Text>
      )}
    </View>

    {onShowLimitsDays && (
      <View style={Styles.containerDaily}>
        <View style={Styles.containerSeparator}>
          <View style={Styles.cardsDaily}>
            <Text style={Styles.textDays}>Seg</Text>
            <Text>{limit.monday}</Text>
          </View>
          <View style={Styles.cardsDaily}>
            <Text style={Styles.textDays}>Ter</Text>
            <Text>{limit.tuesday}</Text>
          </View>
          <View style={Styles.cardsDaily}>
            <Text style={Styles.textDays}>Qua</Text>
            <Text>{limit.wednesday}</Text>
          </View>
          <View style={Styles.cardsDaily}>
            <Text style={Styles.textDays}>Qui</Text>
            <Text>{limit.thursday}</Text>
          </View>
        </View>
        <View style={Styles.separator}>
          <View style={Styles.cardsDaily}>
            <Text style={Styles.textDays}>Sex</Text>
            <Text>{limit.friday}</Text>
          </View>
          <View style={Styles.cardsDaily}>
            <Text style={Styles.textDays}>Sáb</Text>
            <Text>{limit.saturday}</Text>
          </View>
          <View style={Styles.cardsDaily}>
            <Text style={Styles.textDays}>Dom</Text>
            <Text>{limit.sunday}</Text>
          </View>
        </View>
      </View>
    )}
  </View>
);
