import { StyleSheet } from 'react-native';
import { typography } from '../fonts';
import { colors } from '../../consts';

export const PageStyles = StyleSheet.create({
  imageWrapper: {
    width: 24,
    height: 24,
    marginBottom: 25,
  },
  subtitle: {
    ...typography.p2,
    fontWeight: '700',
    color: colors.paymentData.subtitle,
    marginBottom: 25,
  },
  content: {
    ...typography.p3,
    fontWeight: '400',
    color: colors.paymentData.subtitle,
    marginBottom: 25,
  },
  subtitleHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 25,
    flexWrap: 'wrap',
    gap: 10,
  },
  headerButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 330,
  },
  buttonsWrapper: {
    width: 155,
  },
  inputWrapper: {
    marginBottom: 20,
    width: '100%',
  },
  formRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  formRowInput: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  subTitleForm: {
    ...typography.p2,
    fontWeight: '400',
    color: colors.paymentData.subtitle,
    marginVertical: 15,
  },
});
