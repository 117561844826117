import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import {
  useGetEntity,
} from '@/api/entities';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import { Routes } from '@/navigation/routes';
import i18n from '@/internationalization';
import { PeriodRoles } from '@/types/periodsRoles';
import { useCreateNewPeriod, useGetPeriod, useUpdatePeriod } from '@/api/period';

export const useSystemPeriodCreate = (entity: string, id: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: period } = useGetPeriod(id, !!id);
  const [time, setTime] = useState('');
  const [description, setDescription] = useState('');
  const [periodValue, setPeriodValue,
  ] = useState<PeriodRoles>(PeriodRoles.manha);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { goBack, goToEntityPage } = useNavigate();
  const queryClient = useQueryClient();

  const mutatorCreate = useCreateNewPeriod();
  const createPeriod = () => mutatorCreate.mutateAsync({
    entity,
    period: periodValue,
    time,
    description
  });

  const mutatorUpdate = useUpdatePeriod();
  const updatePeriod = () => mutatorUpdate.mutateAsync({
    id,
    entity,
    period: periodValue,
    time,
    isActive: period?.isActive,
    description
  });

  const onSubmit = async () => {
    setLoading(true);

    try {
      const result = id ? await updatePeriod() : await createPeriod();

      if (result.status !== 201 && result.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        setAPIErrorMessage(i18n.t('errors.400'));
      }

      setLoading(false);

      await queryClient.invalidateQueries('useGetPeriods');
      goToEntityPage(
        Routes.System,
        { params: { entity }, screen: Routes.periodList },
      );
    } catch (e) {
      setHasAPIError(true);
      setAPIErrorMessage(i18n.t('errors.500'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (period?.id) {
      setPeriodValue(period.period);
      setTime(period.time.slice(0, 5))
      setDescription(period.description);
    }
  }, [period]);

  const onChangeTime = (t: string) => {
    let input = t.replace(/[^0-9]/g, '');

    if (input.length > 4) {
      input = input.slice(0, 4);
    }
  
    let hours = '';
    let minutes = '';
  
    if (input.length <= 2) {
      hours = input;
    } else {
      hours = input.slice(0, 2);
      minutes = input.slice(2, 4);
    }
  
    if (parseInt(hours, 10) >= 24) {
      hours = '23';
    }
  
    if (minutes && parseInt(minutes, 10) >= 60) {
      minutes = '59';
    }
  
    const validText = minutes ? `${hours}:${minutes}` : hours;
  
    setTime(validText);
  };

  return {
    adminData,
    entityData,
    periodValue,
    APIErrorMessage,
    hasAPIError,
    loading,
    time,
    description,
    onChangeTime,
    setDescription,
    setPeriodValue,
    goBack,
    onSubmit,
  };
};
