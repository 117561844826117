export enum Media {
  FullHd,
  FullHdPortrait,
  Inches13,
  TabletLandscape,
  TabletPortrait,
  MobileLandscape,
  Mobile
}

export const getCurrentMedia = (): Media => {
  if (window.matchMedia('(max-width: 320px)').matches) {
    return Media.Mobile;
  }

  if (window.matchMedia('(max-width: 568px)').matches) {
    return Media.MobileLandscape;
  }

  if (window.matchMedia('(max-width: 568px)').matches) {
    return Media.MobileLandscape;
  }

  if (window.matchMedia('(max-width: 768px)').matches) {
    return Media.TabletPortrait;
  }

  if (window.matchMedia('(max-width: 1024px)').matches) {
    return Media.TabletLandscape;
  }

  if (window.matchMedia('(max-width: 1080px)').matches) {
    return Media.FullHdPortrait;
  }

  if (window.matchMedia('(max-width: 1366px)').matches) {
    return Media.Inches13;
  }

  if (window.matchMedia('(max-width: 1920px)').matches) {
    return Media.FullHd;
  }

  return Media.FullHd;
};
