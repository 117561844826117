import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MSelect, MTextInput } from 'menupass-ui';
import { Header } from '../../components/header';
import { Container } from '../../components/container';
import locale from '../../internationalization';
import { PageTitle } from '../../components/pagetitle';
import { Paper } from '../../components/paper';
import { PlanTabs } from '../../components/plantabs';
import { PaperColumns } from '../../styles/common/PaperColumns';
import { PageStyles } from '../../styles/common/PageStyles';
import { colors } from '../../consts';
import { PlanCreatePageTypes } from './PlanCreatePage.types';
import { usePlanCreate } from './PlanCreatePage.functions';
import { ErrorHandler } from '../../components/errorhandler';

export const PlanCreatePage = ({
  route: { params },
}: PlanCreatePageTypes) => {
  const {
    adminData,
    statusList,
    name,
    setName,
    status,
    monthlyPrice,
    annualPrice,
    hasAPIError,
    APIErrorMessage,
    loading,
    setMonthlyPrice,
    setAnnualPrice,
    onSelectChange,
    goBack,
    submit,
  } = usePlanCreate(params?.id);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Container>
        <PageTitle title={locale.t('planCreate.pageTitle')} />
        <Paper disableInternalPadding bgColor="transparent">
          <PlanTabs plansAdd plans={false} />
          <View style={PaperColumns.tableContainer}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.imageWrapper} />
                  <Text style={PageStyles.subtitle}>
                    {locale.t('planCreate.subtitle')}
                  </Text>
                  <Text>{locale.t('planCreate.content')}</Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <View />
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('planCreate.cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          onPress={goBack}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('planCreate.save')}
                          disabled={
                            name === ''
                            || monthlyPrice === 0
                            || monthlyPrice === null
                            || annualPrice === 0
                            || annualPrice === null
                            || loading
                          }
                          onPress={submit}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                    </View>
                  </View>
                  {hasAPIError && (
                    <ErrorHandler message={APIErrorMessage} />
                  )}
                  <View>
                    <Text style={PageStyles.subTitleForm}>
                      {locale.t('planCreate.form.content')}
                    </Text>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('planCreate.form.name')}
                          value={name}
                          onChangeText={setName}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MSelect
                          label={locale.t('planCreate.form.status')}
                          values={statusList}
                          selectedValue={status}
                          onValueChange={onSelectChange}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('planCreate.form.monthlyPrice')}
                          value={monthlyPrice}
                          isCurrency
                          onChangeValue={setMonthlyPrice}
                          prefix="R$ "
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('planCreate.form.annualPrice')}
                          value={annualPrice}
                          isCurrency
                          onChangeValue={setAnnualPrice}
                          prefix="R$ "
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default PlanCreatePage;
