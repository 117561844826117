import React from 'react';

import { View } from 'react-native';
import { Header } from '@/components/header';
import { Subheader } from '@/components/subheader';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { AdministrationTabs } from '@/components/administrationtabs';
import { Paper } from '@/components/paper';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Datatable } from '@/components/datatable';
import { administrationProfilesPageHeaders } from './AdministrationProfilesPage.consts';
import { AdministrationProfilesPageTypes } from './AdministrationProfilesPage.types';
import { useAdministrationProfiles } from './AdministrationProfilesPage.functions';
import { Routes } from '@/navigation/routes';

export const AdministrationProfilesPage = ({
  route: { params: { entity } },
}: AdministrationProfilesPageTypes) => {
  const {
    adminData,
    entityData,
    adminList,
    loading,
    updateStatus,
    goToEdit,
  } = useAdministrationProfiles(entity);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.AdministrationProfiles}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('administration.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <AdministrationTabs entityId={entity} profiles create={false} />
          <View style={[PaperColumns.tableContainer]}>
            <Datatable
              headers={administrationProfilesPageHeaders}
              data={adminList}
              loading={loading}
              onSwitchChange={updateStatus}
              onEditPress={goToEdit}
              searchFields={['name', 'email']}
            />
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default AdministrationProfilesPage;
