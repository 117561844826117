import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptBR from './locales/pt_br';

const resources = {
  pt: {
    translation: ptBR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'pt',
  fallbackLng: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
