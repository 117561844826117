import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { Paper } from '@/components/paper';
import { colors, dimensions, Images } from '@/consts';
import { ProgressBar } from '@/components/progressbar';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Subheader } from '@/components/subheader';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { PageStyles } from '@/styles/common/PageStyles';
import { Styles } from './CategoryCreatePage.styles';
import { CategoryTabs } from '@/components/categorytabs';
import { CategoryCreatePageTypes } from './CategoryCreatePage.types';
import { Routes } from '@/navigation/routes';
import { useCategoryCreate } from './CategoryCreatePage.functions';
import { UploadComponent } from '@/components/uploadcomponent';
import { ErrorHandler } from '@/components/errorhandler';
import { Flows } from '@/navigation/routes/enums/Flows';

export const CategoryCreatePage = ({
  route: {
    params: { entity, id, flow },
  },
}: CategoryCreatePageTypes) => {
  const {
    adminData,
    entityData,
    imageCache,
    setName,
    name,
    step2,
    step1,
    loading,
    picture,
    hasAPIError,
    APIErrorMessage,
    isEditing,
    goBack,
    onImagePick,
    goToStep2,
    goToStep1,
    submit,
  } = useCategoryCreate(entity, id || '', flow || Flows.undefined);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.CategoryList}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('categoryCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <CategoryTabs entityId={entity} create list={false} />
          <View style={[PaperColumns.tableContainerForm]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.columnForm}>
                  <View style={PageStyles.imageWrapper}>
                    <img
                      src={Images.ProductCreateIcon}
                      alt={locale.t('imagesAlt.product')}
                    />
                  </View>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('categoryCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('categoryCreate.content')}
                  </Text>
                </View>
                <View style={PaperColumns.columnFormRight}>
                  <View style={Styles.progressbarContainer}>
                    <ProgressBar
                      width={dimensions.paperWidth / 2 - 70}
                      step1={step1}
                      step2={step2}
                      steps={2}
                    />
                  </View>
                  {step1 && !step2 && (
                    <View style={PageStyles.subtitleHeader}>
                      <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                        {locale.t('categoryCreate.basicInfo')}
                      </Text>
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('categoryCreate.cancel')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goBack}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('categoryCreate.next')}
                            disabled={loading || name === ''}
                            onPress={goToStep2}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && (
                    <View style={PageStyles.subtitleHeader}>
                      <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                        {locale.t('categoryCreate.basicLogoContent')}
                      </Text>
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('categoryCreate.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep1}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('categoryCreate.register')}
                            disabled={
                              (loading || imageCache.trim() === '') &&
                              !isEditing
                            }
                            onPress={submit}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  {step1 && !step2 && (
                    <View style={Styles.inputContainer}>
                      <Text style={Styles.content}>
                        {locale.t('categoryCreate.content1')}
                      </Text>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('categoryCreate.form.name')}
                            value={name}
                            onChangeText={setName}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && (
                    <View style={Styles.inputsContainerStep3}>
                      <Text style={Styles.content}>
                        {locale.t('imageContent')}
                      </Text>
                      <View>
                        <UploadComponent
                          onFileSelect={onImagePick}
                          uri={imageCache || picture}
                        />
                      </View>
                    </View>
                  )}
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default CategoryCreatePage;
