import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../consts';
import { typography } from '../../styles';

export const subheaderStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: dimensions.submenuHeight,
    backgroundColor: colors.subheader.bg,
    paddingHorizontal: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuWrapper: {
    width: 700,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: 40,
    paddingHorizontal: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    borderRadius: 12,
  },
  menuItemText: {
    ...typography.p3,
    color: colors.subheader.menuText,
    fontWeight: '700',
  },
});
