import React from 'react';

import { View } from 'react-native';
import { Header } from '@/components/header';
import { Subheader } from '@/components/subheader';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Datatable } from '@/components/datatable';
import { responsibleProfilesPageHeaders } from './ResponsibleProfilesPage.consts';
import { ResponsibleTabs } from '@/components/responsibletabs';
import { ResponsibleProfilesPageTypes } from './ResponsibleProfilesPage.types';
import { useResponsibleProfiles } from './ResponsibleProfilesPage.functions';
import { Routes } from '@/navigation/routes';

export const ResponsibleProfilesPage = ({
  route: { params: { entity } },
}: ResponsibleProfilesPageTypes) => {
  const {
    adminData, entityData, adminClientsList, loading, updateStatus, goToEdit,
  } = useResponsibleProfiles(entity);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.ResponsibleProfiles}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('responsible.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <ResponsibleTabs entityId={entity} profiles create={false} />
          <View style={[PaperColumns.tableContainer]}>
            <Datatable
              headers={responsibleProfilesPageHeaders}
              data={adminClientsList}
              onEditPress={goToEdit}
              onSwitchChange={updateStatus}
              loading={loading}
              searchFields={['name', 'email']}
            />
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default ResponsibleProfilesPage;
