import { useMutation, useQuery } from 'react-query';
import {
  checkAdminClientEmail,
  checkAdminClientPhone,
  createAdminClient,
  createClient,
  createStaff,
  createVirtualCard,
  getAdminClient,
  getAdminClientById,
  getClientById,
  getEntityClients,
  manualRecharge,
  removeBalance,
  transferRecharge,
  updateAdminClient,
  updateClient,
} from './requests';

export const useCreateAdminClient = () => useMutation(createAdminClient);
export const useCreateStaff = () => useMutation(createStaff);
export const useUpdateAdminClient = () => useMutation(updateAdminClient);
export const useManualRecharge = () => useMutation(manualRecharge);
export const useTransferRecharge = () => useMutation(transferRecharge);
export const useRemoveBalance = () => useMutation(removeBalance);
export const useCreateVirtualCard = () => useMutation(createVirtualCard);
export const useCreateClient = () => useMutation(createClient);
export const useUpdateClient = () => useMutation(updateClient);
export const useGetAdminClient = (entity: string, isEnabled: boolean) =>
  useQuery(['adminClients', entity], () => getAdminClient(entity), {
    enabled: isEnabled,
  });
export const useGetAdminClientById = (id: string, isEnabled: boolean) =>
  useQuery(['adminClient', id], () => getAdminClientById(id), {
    enabled: isEnabled,
  });
export const useGetEntityClients = (entity: string, isEnabled: boolean) =>
  useQuery(['entityClients', entity], () => getEntityClients(entity), {
    enabled: isEnabled,
  });
export const useGetClientById = (id: string, isEnabled: boolean) =>
  useQuery(['client', id], () => getClientById(id), { enabled: isEnabled });
export const useCheckAdminClientEmail = (email: string, isEnabled: boolean) =>
  useQuery(['clientByEmail', email], () => checkAdminClientEmail(email), {
    enabled: isEnabled,
  });
export const useCheckAdminClientPhone = (phone: string, isEnabled: boolean) =>
  useQuery(['clientByPhone', phone], () => checkAdminClientPhone(phone), {
    enabled: isEnabled,
  });
