import { useNavigation } from '@react-navigation/native';
import { Routes } from '../index';
import { Plan } from '../../../types/plans';

export const useNavigateEditPlan = () => {
  const navigation = useNavigation();

  const gotoEditPlan = (params: Plan) => {
    navigation.navigate(Routes.PlanAdd, { id: params.id });
  };

  return { gotoEditPlan };
};
