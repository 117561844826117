import React from 'react';

import { Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { colors, dimensions, Images } from '../../consts';
import { LoginRegisterStyle } from '../../styles/common';
import locale from '../../internationalization';
import { useRegisterStep1 } from './Register.functions';

export const Register = () => {
  const {
    step2,
    emailChange,
    emailError,
    name,
    email,
    password,
    passwordConfirm,
    setPassword,
    setName,
    setPasswordConfirm,
    goToLogin,
  } = useRegisterStep1();
  return (
    <View style={LoginRegisterStyle.container}>
      <View style={LoginRegisterStyle.imageContainer}>
        <img
          src={Images.Access}
          height={dimensions.height}
          width={dimensions.width * 0.5}
          alt={locale.t('imagesAlt.access')}
        />
      </View>
      <View style={LoginRegisterStyle.formWrapper}>
        <View style={LoginRegisterStyle.formContainer}>
          <Text style={LoginRegisterStyle.steps}>{locale.t('register.step1')}</Text>
          <Text style={LoginRegisterStyle.title}>{locale.t('register.title')}</Text>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              value={name}
              onChangeText={setName}
              label={locale.t('register.form.name')}
              placeholder={locale.t('register.form.namePlaceholder')}
              height={42}
              borderRadius={14}
            />
          </View>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              value={email}
              onChangeText={emailChange}
              hasError={emailError}
              keyboardType="email-address"
              label={locale.t('register.form.email')}
              placeholder={locale.t('register.form.emailPlaceholder')}
              height={42}
              borderRadius={14}
            />
          </View>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              value={password}
              onChangeText={setPassword}
              label={locale.t('register.form.password')}
              placeholder={locale.t('register.form.passwordPlaceholder')}
              secureTextEntry
              isPassword
              height={42}
              borderRadius={14}
            />
          </View>
          <View style={LoginRegisterStyle.inputWrapper}>
            <MTextInput
              value={passwordConfirm}
              onChangeText={setPasswordConfirm}
              label={locale.t('register.form.passwordConfirm')}
              placeholder={locale.t('register.form.passwordConfirmPlaceholder')}
              secureTextEntry
              isPassword
              height={42}
              borderRadius={14}
            />
          </View>
          <View style={LoginRegisterStyle.buttonsWarpper}>
            <View style={LoginRegisterStyle.buttonsContainer}>
              <MFullWidthButton
                label={locale.t('totemCreate.back')}
                bgColor={colors.newEntity.entitiesBtn}
                labelColor={colors.newEntity.entitiesBtnTitle}
                onPress={goToLogin}
                height={42}
                fontSize={14}
              />
            </View>
            <View style={LoginRegisterStyle.buttonsContainer}>

              <MFullWidthButton
                label={locale.t('register.form.next')}
                onPress={() => step2()}
                disabled={
                name === ''
                || email === ''
                || password === ''
                || passwordConfirm === ''
                || password !== passwordConfirm
                || emailError
              }
                height={42}
                fontSize={14}
              />
            </View>

          </View>
        </View>
      </View>
    </View>
  );
};

export default Register;
