import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import {
  useCreateClient, useCreateVirtualCard, useGetAdminClient, useGetClientById, useUpdateClient,
} from '@/api/client';
import { useGetEntity } from '@/api/entities';
import { Responsible } from '@/types/responsible';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { emailIsValid, genEmailCode, removeMenuPassDomainFromEmailCode, fullNameIsValid } from '@/utils/strUtils';
import { Routes } from '@/navigation/routes';
import locale from '@/internationalization';

export const useClientCreate = (entity: string, id: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const {
    data: responsibleResponse,
    isLoading: adminClientsLoading,
  } = useGetAdminClient(entity, !!entity);
  const { data: clientToEdit } = useGetClientById(id, !!id);

  const { goBack, goToEntityPage } = useNavigate();

  const [loading, setLoading] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [hasAPIError, setHasAPIError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [email, setEmail] = useState(genEmailCode());
  const [responsibleSearchList, setResponsibleSearchList] = useState<any[]>([]);
  const [responsibleId, setResponsibleId] = useState<any[]>([]);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [picture, setPicture] = useState('');
  const [identity, setIdentity] = useState('');
  const [imageCache, setImageCache] = useState('');

  const queryClient = useQueryClient();
  const updateMutator = useUpdateClient();
  const mutator = useCreateClient();
  const virtualCardMutator = useCreateVirtualCard();

  const goToStep1 = () => {
    setStep1(true);
    setStep2(false);
    setStep3(false);
  };

  const goToStep2 = () => {
    setStep1(true);
    setStep2(true);
    setStep3(false);
  };

  const goToStep3 = () => {
    setStep1(true);
    setStep2(true);
    setStep3(true);
  };

  const onImagePick = (file: File, uri: string) => {
    setImageCache(uri);
    setPicture('');
  };

  const emailChange = (e: string) => {
    setEmail(e);
    setEmailError(emailIsValid(e));
  };
  const onSetName = (fullName: string) => {
    setName(fullName);
  }

  useEffect(() => {
    if (name) {
      setNameError(!fullNameIsValid(name));
    }
  }
  , [name]);

  const updateClient = () => updateMutator.mutateAsync({
    id,
    name,
    email: `${email}@menupass.com.br`,
    entity,
    password,
    identityNumber: identity,
    username: `${email}@menupass.com.br`,
    admins: responsibleId,
    picture: imageCache || '',
    user: password ? clientToEdit?.user.id : '',
  });

  const createClient = () => mutator.mutateAsync({
    name,
    email: `${email}@menupass.com.br`,
    password,
    entity,
    picture: imageCache,
    username: `${email}@menupass.com.br`,
    admins: responsibleId,
    identityNumber: identity,
  });

  const submit = async () => {
    setLoading(true);

    try {
      const response = id ? await updateClient() : await createClient();
      const data = await response.json();

      if (response.status !== 201 && response.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        const err = data.code ? locale.t(`errors.${data.code}`) : locale.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }
      await virtualCardMutator.mutateAsync({
        ...data,
        client: data.id,
        minimumBalance: data.entity?.systemMinRecurrentPrice || 0,
        isRecurrent: false,
      });

      setLoading(false);

      await queryClient.invalidateQueries('entityClients');
      goToEntityPage(Routes.Client, {
        params: {
          entity,
        },
        screen: Routes.ClientProfiles,
      });
    } catch (e) {
      console.error('useClientCreate.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setLoading(false);
    }
  };

  const onChangeSelect = (value: any) => {
    setResponsibleId(value);
  };
  
  const formatList = () => {
    const list = responsibleResponse?.map((r: Responsible) => ({
      label: r.name,
      value: r.id,
    }));

    const listSorted = list.sort((a: any, b: any) => a.label.localeCompare(b.label));

    setResponsibleSearchList(listSorted);
  };

  const changePassword = (val: string) => {
    setPassword(val.replace(/[^0-9]/g, ''));
  };

  const changeConfirmPassword = (val: string) => {
    setConfirmPassword(val.replace(/[^0-9]/g, ''));
  };

  useEffect(() => {
    if (responsibleResponse?.length && !adminClientsLoading) {
      formatList();
    }
  }, [responsibleResponse]);

  useEffect(() => {
    if (clientToEdit) {
      setName(clientToEdit?.name);
      setIdentity(clientToEdit?.identityNumber);
      setResponsibleId(clientToEdit?.admins?.map((admin: any) => admin.id));
      setEmail(removeMenuPassDomainFromEmailCode(clientToEdit?.email || ''));
      setPicture(clientToEdit?.picture);
      setIsEditing(true);
    }
  }, [clientToEdit]);

  useEffect(() => {
    goToStep1();
  }, []);

  return {
    adminData,
    entityData,
    loading,
    responsibleSearchList,
    responsibleId,
    name,
    onSetName,
    nameError,
    step1,
    step2,
    step3,
    password,
    changePassword,
    confirmPassword,
    changeConfirmPassword,
    imageCache,
    picture,
    email,
    emailError,
    identity,
    setIdentity,
    APIErrorMessage,
    hasAPIError,
    isEditing,
    onChangeSelect,
    goToStep2,
    goToStep3,
    goToStep1,
    goBack,
    submit,
    onImagePick,
    emailChange,
  };
};
