import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../consts';
import { typography } from '../../styles';
import { colorSchema } from '@/consts/colors';

export const Styles = StyleSheet.create({
  progressbarContainer: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
  },
  inputContainer: {
    width: '100%',
  },
  content: {
    ...typography.p2,
    color: colors.newEntity.subtitle,
    marginBottom: 15,
  },
  inputsContainerStep3: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
  },
  selectContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 40,
      borderRadius: 14,
      borderWidth: 1,
      borderColor: colorSchema.Gray2,
    },
    textLabel: {
      marginBottom: 5,
      ...typography.p3,
      color: colorSchema.PureBlack,
      fontWeight: '700',
    },
  errorText: {
    color: 'red',
    fontSize: 12,
    marginTop: 4,
  }

});
