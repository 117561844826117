import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { LinkingOptions } from '@react-navigation/native/lib/typescript/src/types';
import { ActivityIndicator } from 'react-native';
import { External } from '@/navigation/external';
import { Internal } from '@/navigation/internal';
import { config, Routes } from '@/navigation/routes';
import { colors } from '@/consts';
import { MainRouteParams } from '@/navigation/routes/RoutesParams';
import { useMain } from '@/navigation/main/Main.functions';

const Stack = createStackNavigator<MainRouteParams>();

const linking = {
  prefixes: ['http://localhost:19006'],
  animationEnabled: true,
  config,
};

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: colors.default.pageBg,
  },
};

export const Main = () => {
  const {
    initialState,
    isReady,
    isAuthenticated,
    onStatusChange,
  } = useMain();

  if (!isReady) {
    return null;
  }

  return (
    <NavigationContainer
      initialState={initialState}
      linking={linking as LinkingOptions}
      theme={theme}
      onStateChange={onStatusChange}
      fallback={<ActivityIndicator size="large" color={colors.loading} />}
    >
      <Stack.Navigator>
        {!isAuthenticated && (
          <Stack.Screen
            name={Routes.External}
            component={External}
            options={{ headerShown: false }}
          />
        )}
        {isAuthenticated && (
          <Stack.Screen
            name={Routes.Internal}
            component={Internal}
            options={{ headerShown: false }}
          />
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Main;
