import React from 'react';
import { TextInput, View } from 'react-native';
import { DatatableFilterTypes } from './DatatableFilter.types';
import locate from '../../../internationalization';
import { styles } from './DatatableFilter.styles';
import { Images } from '../../../consts';
import Select from '@/components/select/Select';

const DatatableFilter = ({
  onSearch,
  showfilterSelect,
  values,
  onValueChange,
  selectedValue,
  orderValues,
  segmentValues,
  onOrderChange,
  onSegmentChange,
  selectedOrder,
  selectedSegment,
}: DatatableFilterTypes) => (
  <View style={styles.warpper}>
    <View style={styles.container}>
      <View style={styles.inputWrapper}>
        <TextInput
          style={styles.input}
          onChangeText={onSearch}
          placeholder={locate.t('search')}
          keyboardType="web-search"
          clearButtonMode="while-editing"
        />
        <View style={styles.imageWrapper}>
          <img
            src={Images.SearchIcon}
            height={24}
            width={24}
            alt={locate.t('search')}
          />
        </View>
      </View>
    </View>
    {showfilterSelect && (
      <View style={[styles.selectContainer]}>
        <Select
          label="Filtrar por"
          onValueChange={(value: string) => onValueChange(value as any)}
          selectedValue={selectedValue}
          values={values}
          height={42}
          borderRadius={14}
        />
        <View style={styles.spacer20} />
        <Select
          label="Ordenar por"
          onValueChange={(value: string) => onOrderChange(value as any)}
          selectedValue={selectedOrder}
          values={orderValues}
          height={42}
          borderRadius={14}
        />
        <View style={styles.spacer20} />
        <Select
          label="Segmento"
          onValueChange={(value: string) => onSegmentChange(value as any)}
          selectedValue={selectedSegment}
          values={segmentValues}
          height={42}
          borderRadius={14}
        />
      </View>
    )}
  </View>
);

export default DatatableFilter;
