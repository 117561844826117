import { StyleSheet } from 'react-native';
import { colors } from '@/consts';
import { typography } from '@/styles';
import { colorSchema } from '@/consts/colors';
import { shadow } from '@/styles/common/Shadow';

export const Styles = StyleSheet.create({
  modal: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  labelVC: {
    marginBottom: 5,
    ...typography.p3,
    color: colorSchema.PureBlack,
    fontWeight: '700',
  },

  modalVc: {
    backgroundColor: colors.header.submenuBg,
    position: 'absolute',
    top: 200,
    minWidth: 400,
    display: 'flex',
    alignItems: 'stretch',
    paddingHorizontal: 30,
    right: 200,
    padding: 16,
    borderRadius: 16,
    ...shadow,
  },

  containerMS: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingVertical: 20,
  },

  cardMonthly: {
    marginBottom: 30,
  },

  textMonthly: {
    marginBottom: 5,
    ...typography.p3,
    color: colorSchema.PureBlack,
    fontWeight: '700',
  },

  cardWeekly: {
    marginBottom: 30,
  },

  textWeekly: {
    marginBottom: 5,
    ...typography.p3,
    color: colorSchema.PureBlack,
    fontWeight: '700',
  },

  cardDaily: {
    marginBottom: 10,
  },

  textDaily: {
    marginBottom: 5,
    ...typography.p3,
    color: colorSchema.PureBlack,
    fontWeight: '700',
  },

  textDays: {
    paddingRight: 10,
    marginBottom: 5,
    ...typography.p3,
    color: colorSchema.PureBlack,
    fontWeight: '700',
  },

  line: {
    height: 40,
    width: 1,
    backgroundColor: colorSchema.Gray5,
  },

  containerDaily: {
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: 20,
  },

  cardsDaily: {
    paddingRight: 30,
  },

  separator: {
    flexDirection: 'row',
    marginTop: 20,
  },

  containerSeparator: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

});
