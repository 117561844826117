import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { Login } from '../../pages/login';
import { Register } from '../../pages/register';
import { RegisterStep2 } from '../../pages/registerstep2';
import { Routes } from '../routes';
import { ExternalRouteParams } from '../routes/RoutesParams';
import { RecoveryPassword } from '../../pages/recoverypassword';
import { RecoveryPassword2 } from '@/pages/recoverypassword2';

const Stack = createStackNavigator<ExternalRouteParams>();

export const External = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.Login}
      component={Login}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Register}
      component={Register}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.Register2}
      component={RegisterStep2}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.RecoveryPassword}
      component={RecoveryPassword}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.RecoveryPassword2}
      component={RecoveryPassword2}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default External;
